<template>
  <v-app>
    <v-container fluid class="mb-8 relative wavebg2">
      <v-row class="align-center px-4 justify-center">
        <v-col cols="12" lg="7" class="text-center">
          <div class="pt-md-10 pt-5 pb-sm-6 pb-0 z-10 position-relative">
            <h1 class="text-black font-40 ls-75">Preset Boxes</h1>
            <p class="my-4 font-16 ls-75 text-black">
              We've carefully selected and assembled these custom boxes to best suit your needs.
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="px-sm-4">
        <v-col cols="6" sm="4" md="3" lg="3" v-for="item in allBoxes" :key="item.id" :to="item.id">
          <v-card @click.stop="showPackDetail(item)" class="text-center pa-4 rounded-16 hover-bg z-1" height="100%">
            <div class="mb-3">
              <v-img :src="item.image?.image" class="fill-height rounded-16"></v-img>
            </div>
            <div class="text-left">
              <v-chip class="font-12 mb-2 px-4 text-light7 bg-blue-light">Min. Qty: <span class="min-quantity ms-1 font-weight-semibold">50</span></v-chip>
              <v-card-title class="font-18 mb-2 font-weight-semibold text-dark2 pa-0">{{ item.name }}</v-card-title>
              <div class="font-16 font-weight-normal text-light7">Starts from USD <span class="font-weight-bold text-bluish3">{{ getBoxPrice(item.box_products) }}</span></div>
            </div>
          </v-card>
        </v-col>
      </v-row>
 
    </v-container>

    <v-dialog v-model="drawer" max-width="1000px" overlay-color="#f1f1f1" content-class="rounded-xl" scrollable>
      <v-card class="rounded-16">
        <v-card-text class="px-0 py-0">
          <v-container>
            <v-row>
              <v-col cols="12" lg="6" class="pe-md-2">
                <div class="bg-white rounded-16 px-4 py-4 h-100 card-shadow">
                  <v-window v-model="onboarding" class="mb-3 elevation-0 rounded-lg cst-slider-main" show-arrows>
                    <template v-slot:prev="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" min-width="10" height="40" class="pa-2 elevation-0 rounded-circle">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" min-width="10" height="40" class="pa-2 elevation-0 rounded-circle">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <v-window-item v-for="(image, index) in [box.image, ...box.products_images]" :key="index">
                      <v-card class="pa-0">
                        <div class="fill-height">
                          <v-img :src="getImage(image)" contain max-width="100%" height="400px" class="mx-auto"></v-img>
                        </div>
                      </v-card>
                    </v-window-item>
                  </v-window>

                  <div class="d-flex justify-space-between align-center pa-0">
                    <v-btn text @click="prev" variant="plain" class="pa-0 me-3 border-grey text-light6 slider-prev rounded-circle">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-item-group v-model="onboarding" class="text-center ga-3 d-flex flex-row overflow-x-auto overflow-y-hidden thumbnail-slider py-2" active-class="active">
                      <v-item v-for="(image, index) in [box.image, ...box.products_images]" :key="index" v-slot="{ active, toggle }">
                        <v-btn :class="{ 'active': active }" text class="pa-0 mr-1 elevation-0" width="64" @click="toggle">
                          <v-img :src="getImage(image)" contain height="40" width="64px"></v-img>
                        </v-btn>
                      </v-item>
                    </v-item-group>

                    <v-btn text @click="next" variant="plain" class="pa-0 ms-3 border-grey text-light6 slider-prev rounded-circle">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="6" class="ps-md-2">
                <div class="bg-white rounded-16 px-4 py-4 h-100 card-shadow">
                  <div class="mb-4 mb-md-6">
                    <h1 class="font-24 mb-2 font-weight-semibold ls-75 text-primary">The Planter's Box</h1>
                    <v-text class="mb-2 px-4 py-2 bg-light4 rounded-pill"><span class="font-weight-bold text-bluish4 font-18 ls-25">$44.13</span> <span
                        class="min-quantity text-light9 font-14 ls-25">/ box</span></v-text>
                  </div>
                  <div class="mb-4 mb-md-8 text-light7 font-weight-medium font-13 lh-2">Create a sense of unity and belonging among attendees, as they all
                    receive this SKO swag pack! These items will serve as a reminder of the event and the company,
                    keeping them top-of-mind long after the SKO meeting has ended.</div>
                  <div>
                    <v-list-item-subtitle class="text-primary font-15 ls-75 font-weight-semibold opacity-100">Box
                      includes:</v-list-item-subtitle>
                    <v-list color="transparent" class="mb-3">
                      <v-list-item class="pl-2">
                        <v-list-item-content>
                          <v-list-item-title class="font-13 text-light7 font-weight-medium ls-25"
                            v-for="(product, prodoctIndex) in box.box_products" :key="prodoctIndex">
                            <v-icon class="text-light7 font-13">mdi-circle-medium</v-icon>
                            {{ product.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!-- <v-btn class="font-weight-bold primary text-none rounded-sm py-6 px-3" @click="addToCart(box)">Start with this box</v-btn> -->
                    <v-btn rounded class="bg-dark2 text-unset text-light8 px-6 font-13 font-weight-semibold" min-height="42" @click="addToCart(box)">
                      Start with this box
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'PresetBoxesList',
  props: {
    allBoxes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      drawer: null,
      box: {},
      onboarding: 0,
    };
  },
  methods: {
    showPackDetail(box) {
      this.box = box;
      this.drawer = true;
      this.onboarding = 0;
    },
    getImage(imageJson) {
      return imageJson?.image || (imageJson?.[0]?.data?.link ? this.$helper.functions.getPresetBoxProdImage(imageJson[0].data.link) : '');
    },
    getBoxPrice(products) {
      return this.$helper.functions.getPresetBoxPrice(products).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    next() {
      this.onboarding = (this.onboarding + 1) % ([this.box.image, ...this.box.products_images].length);
    },
    prev() {
      this.onboarding = (this.onboarding - 1 + [this.box.image, ...this.box.products_images].length) % ([this.box.image, ...this.box.products_images].length);
    }
  },
};
</script>

<style scoped>
</style>
<template>
  <v-container fluid class="bg-primary2 py-11">
    <v-row class="text-center px-4 justify-center align-center pb-2">
      <v-col cols="12">
        <h2 class="text-light2 font-24 ls-75 font-weight-semibold">Brands we worked with</h2>
      </v-col>
      <v-col cols="12" class="pb-md-5">
        <Splide :options="splideOptions" ref="splide" class="partner-slider">
          <SplideSlide v-for="(logo, index) in logos" :key="index">
            <div class="logo-slide d-flex align-center justify-space-evenly rounded-16">
              <img :src="logo" class="logo-img" alt="Brand Logo" loading="lazy" />
            </div>
          </SplideSlide>
        </Splide>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import '@splidejs/vue-splide/css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  name: "BrandWork",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      logos: [
        new URL('@/assets/images/inak-logo.png', import.meta.url).href,
        new URL('@/assets/images/lead-origin.png', import.meta.url).href,
        new URL('@/assets/images/zendrop.png', import.meta.url).href,
        new URL('@/assets/images/sdc-logo.png', import.meta.url).href,
        new URL('@/assets/images/herogo.png', import.meta.url).href,
        new URL('@/assets/images/inak-logo.png', import.meta.url).href,
        new URL('@/assets/images/lead-origin.png', import.meta.url).href,
        new URL('@/assets/images/zendrop.png', import.meta.url).href,
        new URL('@/assets/images/sdc-logo.png', import.meta.url).href,
        new URL('@/assets/images/herogo.png', import.meta.url).href,
      ],
      splideOptions: {
        type: 'loop',
        perPage: 6,
        perMove: 1,
        autoplay: true,
        interval: 2000,
        speed: 1000,
        gap: '24px',
        arrows: false,
        pagination: false,
        breakpoints: {
          320: { perPage: 1 },
          640: { perPage: 3 },
          1024: { perPage: 4 },
          1263: { perPage: 6 },
        },
      },
    };
  },
};
</script>

<style scoped>
.splide__slide img {
  max-width: 100%;
  height: auto;
}

.splide__slide.is-active {
  transform: none !important;
  opacity: 1 !important;
}
</style>

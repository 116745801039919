<template>
  <v-app>
    <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
      <v-overlay :model-value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <v-card elevation="3" rounded="lg">
              <div class="py-6 text-center">
                <v-btn :to="{ name: 'Home' }" variant="text" class="elevation-0">
                  <v-img :src="logoSrc" max-width="100%" height="auto" width="200"></v-img>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="pt-4 px-4">
                <div class="text-body-2 text-primary font-weight-semibold text-uppercase">Let's get you started</div>
                <v-form @submit.prevent="signUp">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="py-0 text-left" v-for="(field, index) in fields" :key="index">
                        <v-label class="opacity-100 font-weight-medium mb-1 font-14">{{ field.label }}</v-label>
                        <v-text-field 
                          v-model="user[field.model]" 
                          :type="field.type" 
                          :placeholder="`Enter your ${field.label.toLowerCase()}`"
                          variant="outlined" 
                          density="compact" 
                          required
                        ></v-text-field>

                      </v-col>
                      <v-col cols="12" class="py-1">
                        <v-btn 
                          class="font-weight-semibold text-none rounded-pill py-md-5" 
                          color="primary" block
                          type="submit"
                        >Sign up</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
              <v-divider></v-divider> 
              <div class="pa-4 text-body-1 font-weight-medium">
                <span> Already have an account?</span> 
                <v-btn 
                  variant="text" 
                  height="auto"
                  compact="comfortable"
                  class="text-primary px-0 min-width-0 elevation-0  text-decoration-underline"
                  :to="{ name: 'Login' }"
                >Log in
                </v-btn> 
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "SignUpPage",
  setup() {
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const logoSrc = new URL('@/assets/images/swagbox-logo.png', import.meta.url).href;

    const user = reactive({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      company_name: "",
    });

    const fields = computed(() => [
      { label: "First Name", model: "first_name", type: "text" },
      { label: "Last Name", model: "last_name", type: "text" },
      { label: "Email", model: "email", type: "email" },
      { label: "Password", model: "password", type: "password" },
      { label: "Company Name", model: "company_name", type: "text" },
    ]);

    const signUp = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("SIGN_UP", user);
        store.commit("showAlert", {
          text: response.message,
          successStatus: true,
        });
        router.push({ name: "AccountCreated" });
      } catch (error) {
        store.commit("showAlert", {
          text: error.data?.message || "Something went wrong",
          successStatus: false,
          alertColor: "red-darken-4",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      user,
      loading,
      logoSrc,
      fields,
      signUp,
    };
  },
};
</script>

<style scoped>
</style>
<template>
  <v-container fluid class="py-10 py-md-15 bg-s-light-3">
    <v-row class="pt-md-15">
      <v-col>
        <h2 class="pb-md-10 font-40 ls-05 text-dark3">Frequently Asked Questions</h2>
      </v-col>
    </v-row>

    <v-row class="pb-md-15 justify-center">
      <v-col cols="12" md="9" lg="7">
        <!-- Expansion Panels -->
        <v-expansion-panels class="elevation-0">
          <v-expansion-panel class="text-left">
            <v-expansion-panel-title class="font-18 text-primary font-weight-medium" collapse-icon="mdi-minus" expand-icon="mdi-plus">
              How long does it take to order swag?
            </v-expansion-panel-title>
            <v-expansion-panel-text class="font-14">
              <div class="mb-md-4 mb-3">Swag typically takes 3-4 weeks to get produced, but that doesn’t mean you can’t get it sooner! If you need something rushed, let your Swag Expert know and we’ll get it sorted.</div>
              <div><span class="text-primary">NOTE:</span> The fewer colors and decorations an item has, the quicker it can get produced. For example, anything with leather patches, hem tags, or gradients will take time to make, as opposed to a simple one-color logo, which we can sometimes produce in a single day!</div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel class="text-left">
            <v-expansion-panel-title class="font-18 text-primary font-weight-medium" collapse-icon="mdi-minus" expand-icon="mdi-plus">
              Do you ship internationally?
            </v-expansion-panel-title>
            <v-expansion-panel-text class="font-14">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel class="text-left">
            <v-expansion-panel-title class="font-18 text-primary font-weight-medium" collapse-icon="mdi-minus" expand-icon="mdi-plus">
              We need somewhere to store our swag. Can you help?
            </v-expansion-panel-title>
            <v-expansion-panel-text class="font-14">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel class="text-left">
            <v-expansion-panel-title class="font-18 text-primary font-weight-medium" collapse-icon="mdi-minus" expand-icon="mdi-plus">
              Do packs come preassembled?
            </v-expansion-panel-title>
            <v-expansion-panel-text class="font-14">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel class="text-left">
            <v-expansion-panel-title class="font-18 text-primary font-weight-medium" collapse-icon="mdi-minus" expand-icon="mdi-plus">
              Is there a fee to store swag?
            </v-expansion-panel-title>
            <v-expansion-panel-text class="font-14">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima.
            </v-expansion-panel-text>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

  
  <script>
  export default {
    name: "FaqsSection", 
  };
  </script>
  
  <style scoped>
  </style>
  
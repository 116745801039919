<template>
  <v-app>
    <v-container class="bg-gradient-4" fluid>
      <v-row justify="center" class="text-center pt-md-8 pt-5">
        <v-col cols="12" class="pt-0">
          <div class="bg-light5 rounded-16 border-grey py-md-15 py-10 px-4 px-md-0">
            <v-row justify="center" class="text-center">
              <v-col cols="12" sm="10" lg="6" md="8">
                <v-img :src="greenTick" width="133" class="mx-auto mb-3"></v-img>
                <h1 class="text-primary font-weight-semibold font-20 ls-75 mb-1">Order Confirmed!</h1>
                <p class="text-primary font-18 font-weight-normal ls-75 mb-4">
                  Thank you for choosing Swagify for your order, 
                  <span class="text-bluish6 font-weight-bold">SW-0004</span>.
                </p>
                <p class="text-light10 font-13 ls-25 font-weight-medium">
                  We've just sent you an email with all the necessary details regarding your order.  
                  Should you require any further assistance, please don't hesitate to contact us at  
                  <a href="mailto:support@swagify.com" class="font-weight-bold text-light10 text-decoration-none">
                    support@swagify.com
                  </a>.
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center py-md-15 my-md-5 py-10">
        <v-col cols="12" md="10">
          <v-row justify="center" class="text-center">
            <v-col cols="12">
              <div class="text-primary font-weight-normal font-18 ls-75">What happens next?</div>
            </v-col>
            <v-col v-for="(step, index) in steps" :key="index" cols="6" md="3">
              <v-img :src="step.icon" width="36" class="mx-auto mb-3"></v-img>
              <div class="text-primary font-weight-normal font-15 ls-75">
                {{ step.text }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { ref } from "vue";
import greenTick from "@/assets/images/svg/green-tick.svg";
import artIcon from "@/assets/images/svg/art.svg";
import reviewIcon from "@/assets/images/svg/review.svg";
import approveIcon from "@/assets/images/svg/approve.svg";
import shipIcon from "@/assets/images/svg/ship-icon.svg";

export default {
  name: "ThankYou",
  setup() {
    const steps = ref([
      { icon: artIcon, text: "We will send you a proof in next 4 hours!" },
      { icon: reviewIcon, text: "Review your proof on your Swagify portal." },
      { icon: approveIcon, text: "Approve your proof or request changes" },
      { icon: shipIcon, text: "After proof approval, we print and ship your swag." },
    ]);

    return { greenTick, steps };
  },
};
</script>


<style scoped>
/* Add any specific styles if needed */
</style>

import { defineRule, configure } from "vee-validate";
import { email, confirmed } from "@vee-validate/rules";

// Define validation rules
defineRule("required", (value) => {
  return value ? true : "{_field_} is required";
});

defineRule("email", email);
defineRule("confirmed", confirmed);

defineRule("exact_length_cvv", (value, [max_count]) => {
  if (value.length <= max_count) {
    return true;
  }
  return `Maximum ${max_count} characters allowed for {_field_}`;
});

defineRule("limit_phone", (value, [min_count]) => {
  if (value.length === min_count) {
    return true;
  }
  return "Invalid Phone Number";
});

defineRule("validate_phone_number", (value) => {
  if (value.length >= 14) {
    return true;
  }
  return "Minimum 10 characters allowed for {_field_}";
});

defineRule("date_format_month_year", (value) => {
  return /((0[1-9]|1[0-12])\/[1-9]\d{3}$)/.test(value)
    ? true
    : "Invalid Date Format, required format MM/YYYY";
});

defineRule("future_month", (value) => {
  let [month, year] = value.split("/");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (parseInt(year) === currentYear) {
    if (parseInt(month) > currentMonth) {
      return true;
    }
    return "Invalid Month";
  }
  return true;
});

defineRule("future_year", (value) => {
  let [, year] = value.split("/");
  if (parseInt(year) >= new Date().getFullYear()) {
    return true;
  }
  return "Invalid Year";
});

defineRule("exact_length", (value, [max_count]) => {
  if (value.length === max_count) {
    return true;
  }
  return "Invalid Card Number";
});

defineRule("cvv_length", (value) => {
  if (value.length === 3 || value.length === 4) {
    return true;
  }
  return "Invalid CVV / CVC";
});

// Configure default messages
configure({
  generateMessage: (ctx) => `${ctx.field} is invalid`,
  validateOnInput: true, // Validate as user types
});

<template>
    <v-container fluid class="px-0 pt-0">
      <!-- Sign-in Section -->
      <v-card class="pa-5 rounded-16 mb-2 bg-light1 border-b" elevation="0">
        <v-row class="align-center">
          <v-col cols="12" md="5">
            <h3 class="font-weight-medium font-13 ls-25 text-black text-left mb-md-2 mb-3">Sign in for a faster checkout experience</h3>

            <form @submit.prevent="handleSignIn" class="mb-3">
              <v-row class="mb-2">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="email"
                    placeholder="Email Address"
                    density="compact"
                    class="rounded-4 bg-white-light font-15 font-weight-normal ls-75 px-3 text-primary"
                    variant="plain"
                    hide-details
                    style="height: 40px;"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="ps-sm-0">
                  <v-text-field
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    density="compact"
                    class="rounded-4 bg-white-light font-15 font-weight-normal ls-75 px-3 text-primary"
                    variant="plain"
                    hide-details
                    style="height: 40px;"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <div class="d-flex align-center">
                <v-btn rounded="pill" class="bg-dark2 text-unset text-light8 font-weight-bold mr-3 px-4 font-14" min-height="42">
                  Sign in
                </v-btn>
                <v-btn :to="{ name: 'SignUpPage' }" variant="outlined" rounded="pill" class="ls-25 font-14 font-weight-bold ls-25 border-bluish2 px-4 text-dark2" min-height="42">
                  Create Account
                </v-btn>
              </div>
            </form>


            <!-- Forgot Password with Partial Link -->
            <div class="text-left font-12 ls-25 font-weight-medium text-bluish6">
              <router-link to="/forgot-password" class="text-decoration-none text-bluish6">
                Forgot Password? Click here
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" md="7" class="ps-md-0">
            <v-row class="align-start">
              <v-col cols="12" sm="6" class="d-flex align-center" v-for="(feature, index) in features" :key="index">
                <div class="blurred-bg d-flex rounded-lg">
                  <!-- Circle with Background -->
                  <v-sheet color="light4" class="d-flex justify-center align-center rounded-circle px-3" width="46" height="46">
                    <v-img :src="require(`@/assets/images/svg/${feature.icon}.svg`)" width="24"></v-img>
                  </v-sheet>
                  <div class="ml-3">
                    <div class="font-weight-medium text-black font-13 text-left mb-md-1">{{ feature.title }}</div>
                    <div class="text-light7 text-left font-12 ls-25">{{ feature.description }}</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
</template>

<script>
export default {
  name: 'CartEmpty',
  data() {
    return {
      email: '',
      password: '',
      features: [
        { icon: 'tag', title: 'Exclusive access to deals and discounts', description: 'Gain exclusive access to special deals and discounts tailored just for you.' },
        { icon: 'cart-2', title: 'Speedier checkout process', description: 'Experience a quicker and more efficient checkout process for seamless transactions.' },
        { icon: 'search', title: 'View order history and tracking', description: 'Keep tabs on your order history and easily track your packages.' },
        { icon: 'heart', title: 'Save favorite products and designs', description: 'Save and revisit your preferred products and designs for a personalized shopping experience.' }
      ]
    };
  },
  methods: {
    handleSignIn() {
      console.log('Signing in with:', this.email, this.password);
      // Handle sign-in logic
    },
    createAccount() {
      console.log('Redirecting to account creation...');
      // Handle account creation logic
    },
    shopSwagbox() {
      this.$router.push({ name: "ProductOnboarding" });
    },
  },
};
</script>

<style scoped>
</style>

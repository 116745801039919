<template>
  <v-app-bar scroll-behavior="elevate" class="bg-white px-3">
    <!-- Logo -->
    <router-link class="me-15" :to="{ name: 'Home' }">
      <v-img
        :src="require('@/assets/images/swagbox-logo.png')"
        contain
        max-width="100%"
        height="auto"
        width="150"
      ></v-img>
    </router-link>

    <!-- Mobile Menu Icon -->
    <v-app-bar-nav-icon class="d-md-none text-primary" @click.stop="toggleDrawer">
      <v-icon :class="{ 'rotate-icon': drawer }" transition="scale-transition" >{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
    </v-app-bar-nav-icon>

    <!-- Desktop Navigation -->
    <v-toolbar-items class="d-none d-md-flex align-center">
      <v-menu v-model="productMenu" transition="slide-y-reverse-transition">
        <template v-slot:activator="{ props }">
          <v-btn  variant="text" class="ls-75 font-14 font-weight-medium" v-bind="props" color="primary">
            Products  
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in productItems" :key="i" @click="goToProductPage(item)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <a 
        href="https://www.swagifypod.com/contact-us" 
        target="_blank" 
        rel="noopener noreferrer"
        class="d-inline-block h-100"
      >
        <v-btn 
          variant="text" 
          class="ls-75 font-14 font-weight-medium text-primary h-100"
        >
          Contact Us
        </v-btn>
      </a>

    </v-toolbar-items>

    <v-spacer class="d-none d-md-flex"></v-spacer>

    <!-- Desktop Right Buttons -->
    <div class="d-none d-md-flex">
      <v-btn variant="outlined" rounded="pill" class="ls-25 font-14 px-4 text-dark2" min-height="42" @click="goToLogin">
        Login
      </v-btn>
      <v-btn rounded="pill" class="bg-dark2 text-light8 px-4 ml-2 font-14" min-height="42" @click="shopSwagbox">
        Shop Swagbox
      </v-btn>
    </div>
  </v-app-bar>

  <!-- Mobile Navigation Drawer -->
  <v-navigation-drawer v-model="drawer" temporary side="left" class="d-md-none text-left text-primary">
  <v-list>
    <!-- Products Dropdown (Border with Side Spacing) -->
    <v-list-group v-model="mobileProductMenu" class="border-b mx-4 pb-1">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="px-0">
          <v-list-item-title>Products</v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item v-for="(item, i) in productItems" :key="i" @click="goToProductPage(item)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>

    <!-- Contact Us Link (Without Border) -->
    <v-list-item @click="goToContactUs" class="py-4e">
      <v-list-item-title>Contact Us</v-list-item-title>
    </v-list-item>

    <!-- Buttons Section -->
    <v-list-item>
      <v-btn block variant="outlined" rounded="pill" class="text-dark2 mb-2" @click="goToLogin">
        Login
      </v-btn>
    </v-list-item>

    <v-list-item>
      <v-btn block rounded="pill" class="bg-primary text-white" @click="shopSwagbox">
        Shop Swagbox
      </v-btn>
    </v-list-item>
  </v-list>
</v-navigation-drawer>


</template>

<script>
export default {
  name: "NavbarPage",
  data() {
    return {
      drawer: false,
      productMenu: false,
      mobileProductMenu: false,
      productItems: [
        { title: "Product 1", path: "/product1" },
        { title: "Product 2", path: "/product2" },
        { title: "Product 3", path: "/product3" },
      ],
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    goToProductPage(item) {
      this.$router.push(item.path);
      this.drawer = false;
    },
    goToContactUs() {
      this.$router.push({ name: "ContactUs" });
      this.drawer = false;
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
      this.drawer = false;
    },
    shopSwagbox() {
      this.$router.push({ name: "ProductOnboarding" });
      this.drawer = false;
    },
  },
};
</script>

<style scoped>

</style>
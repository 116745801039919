<template>
  <v-app>
    <v-container class="bg-gradient-4" fluid>
      <!-- Sign-in Section -->
       <div class="pt-2">
              <SignIn />
       </div>


      <!-- Empty Cart Section -->
      <div class="d-flex justify-center align-center border rounded-xl pa-5" style="min-height: 500px;">
        <v-row class="justify-center">
          <v-col cols="12" class="text-center">
            <v-img :src="require('@/assets/images/emptycart.png')" class="mx-auto mb-3" width="116px"></v-img>
            <h1 class="font-weight-semibold font-18 ls-75 text-primary mb-3">Your cart is empty</h1>
            <v-btn rounded="pill" class="bg-dark2 text-light8 px-4" :to="{ name: 'ProductOnboarding' }">
              Shop Swagbox
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import SignIn from "./components/SignIn.vue"; // ✅ Import at the top

export default {
  name: "CartEmpty",
  components: {
    SignIn, // ✅ Reference it here
  },
};
</script>

<style scoped>
.text-grey {
  color: #888;
}
</style>

<template>
  <v-app>
    <!-- navbar -->
    <!-- <ProductNav class="d-none" @filterProduct="updateProductList"  v-if="!loading"/> -->
    <v-divider></v-divider>
    <!-- Products list ad side cart START-->
    <v-container fluid class="pt-md-3 px-1">
      <v-row>
        <!-- Side left Navbar START -->
        <v-col cols="12" md="3" lg="2" class="relative px-0 pt-0 mt-6 py-0 py-md-3" v-if="loading">
          <v-skeleton-loader v-for="count in catLoaderCount" :key="count" v-bind="attrs" type="list-item-avatar">
          </v-skeleton-loader>

        </v-col>
        <!-- :absolute="$vuetify.breakpoint.mdAndUp" :permanent="$vuetify.breakpoint.mdAndUp" -->
        <v-col cols="12" md="3" lg="2" class="px-0 pt-0 pb-0 pb-md-3" v-if="!loading">
            <v-navigation-drawer
                :permanent="!isMobile"
                :temporary="isMobile"
                v-model="productcatmobile"
                class="elevation-0 category-nav"
                overlay-color="#f1f1f1"
              > 
              <div class="w-100 d-flex d-md-block justify-space-between align-center  py-2 py-md-0">
                <div class="ps-0 px-lg-0"> 
                  <v-list-item class="pb-lg-0">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-left font-weight-semibold text-uppercase text-primary font-14 ls-75">Categories</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div> 
                <div class="px-5">
                  <v-btn 
                      @click="productcatmobile = false" 
                      variant="text"
                      class="rounded-circle pe-4 font-20 font-weight-light text-light7 font-24 d-md-none elevation-0 darken-5"
                      width="24" 
                      height="24" 
                      min-width="24"
                    >
                    <i class="ri-close-circle-line"></i>
                  </v-btn>
                </div>
              </div>
              <v-list rounded density="compact" class="catsidenav pt-0">
                <v-list-item-group v-model="selectedcat" color="primary">
                  <v-list-item   v-for="item in $store.getters.categories" 
                    :key="item.id" 
                    :id="`category-${item.id}`"
                    class="px-2 pb-1">
                    <v-btn block variant="outlined" class="text-left justify-start rounded-pill border " height="38"
                      :style="selectedcat === item.id ? 'background-color: #141402; color: #fff; border-color: #141402;' : ''"
                      @click="gotoCats('sub_cat', item)">
                      <v-img :src="getItemIcon(item)" width="18" height="18" class="mr-2"></v-img>
                      <span class="font-12 font-weight-medium text-light7 ls-25">{{ item.title }}</span>
                    </v-btn>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
        </v-col>
        <!-- Side left Navbar END -->

        <!-- Main Products List START -->
        <v-col cols="12" md="6" lg="8" class="px-0 py-0">
          <div class="bg-gray2 px-3 py-3">
            <v-row class="align-center mb-2 mb-md-0">
              <v-col cols="6" md="6" lg="6" class="pr-0">
                <div v-if="loading">
                  <v-skeleton-loader type="heading"></v-skeleton-loader>
                </div>
                <div v-else class="font-18 text-black ls-75 font-weight-semibold d-flex text-start align-center mob-lh">
                  <span><i class="ri-arrow-left-s-line font-24 font-weight-medium text-primary"></i></span>
                  <span>Start Your Swag Pack</span>
                </div>
              </v-col>
              <v-col cols="6" md="6" lg="6" class="pl-0">
                <div class="d-flex align-center ga-2 justify-end">
                  <v-icon class="filter-icon">
                    <i class="ri-filter-line text-primary font-weight-light font-16"></i>
                  </v-icon>
                  <v-select 
                    variant="outlined"
                    hide-details
                    density="compact"
                    :items="['Featured', 'Latest']"
                    v-model="selectedSort"
                    class="sort-dropdown font-weight-semibold"
                    label="Sort by" 
                    menu-icon=""
                  >
                    <template v-slot:append-inner>
                      <v-icon class="text-light7"><i class="ri-arrow-down-s-line font-weight-light font-20"></i></v-icon>
                    </template>
                  </v-select>
                </div>
              </v-col>
            </v-row>
            <ProductsList class="rounded-16" v-if="!loading" :catalogs="catalogs" />
          </div>
          <v-container fluid v-if="loading" class="mt-1">
            <v-row>
              <v-col cols="6" sm="4" md="4" lg="4" class="text-center">
                <v-skeleton-loader type="image, heading, image, heading"></v-skeleton-loader>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="4" class="text-center">
                <v-skeleton-loader type="image, heading, image, heading"></v-skeleton-loader>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="4" class="text-center">
                <v-skeleton-loader type="image, heading, image, heading"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <!-- Main Products List END -->

        <!-- Side cart START-->
        <v-col cols="12" md="3" lg="2" class="px-0 pt-0">
          <!-- :absolute="$vuetify.breakpoint.mdAndUp" :permanent="$vuetify.breakpoint.mdAndUp"  -->
          <v-navigation-drawer 
            location="right"
            :permanent="!isMobile"
            :temporary="isMobile"
            v-model="cartitems"
            class="cart-sidebar border-s-grey elevation-0"
            overlay-color="#f1f1f1"
          >
              <div class="w-100 d-flex d-lg-block justify-space-between align-center border-2b-grey py-2 py-md-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-left  font-weight-semibold text-uppercase text-primary font-14 ls-75">Your SWAG
                      PACK</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div class="px-5"> 
                <v-btn 
                    @click="cartitems = false" 
                    variant="text"
                    class="rounded-circle pa-0 font-20 font-weight-light text-light7 font-24 d-lg-none elevation-0 darken-5"
                    width="24" 
                    height="24" 
                    min-width="24"
                  >
                    <i class="ri-close-circle-line"></i>
                  </v-btn>
                </div> 
              </div>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item link class="border-b-grey" v-for="(item, itemIndex) in CartItem" :key="itemIndex">
                  <div class="d-flex my-2" @click="openDetail(item)">
                    <div>
                      <div class="border rounded-lg pa-1">
                        <v-img  :src="$helper.functions.getProdImage(item, 'test')"
                        max-width="100%" height="auto" width="62" contain>
                      </v-img>
                      </div> 
                    </div>
                    <div class="text-left ml-2 overflow-hidden">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <div v-bind="props"
                            class="font-13 font-weight-medium text-light10 text-no-wrap ls-25 text-truncate">
                            {{ item.title }}
                          </div>
                        </template>
                        <template v-slot:default>
                          <div class=" tooltip-default px-0 py-0">
                            {{ item.title }}
                          </div>
                        </template>
                      </v-tooltip>

                      <div class="text-light7 font-12 font-weight-medium">Qty: 
                        <span class="text-primary">{{ getItemQuantity(item) }} </span>
                      </div>
                      <div class="d-inline-block font-12 font-weight-medium">
                        <span class="text--primary"><span class="text-light7">Color:</span>
                          <v-avatar :color="$helper.functions.getColorCode(item.selected_color)" size="10" class="mx-1">
                          </v-avatar>
                          <span class="text-capitalize font-weight-medium text-primary"> {{
                            $helper.functions.getColorName(item.selected_color) }}</span>
                        </span>
                      </div>
                      <div class="text-light7 font-12 font-weight-medium ls-25">
                        Price per: <span class="text-primary">$1.74 (Est)</span>
                      </div>
                      <span class="remove-btn d-flex font-12 font-weight-medium ga-2 ga-md-2">
                        <v-tooltip bottom content-class="text-body-2">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" @click="openDetail(item)"
                              class="cursor-pointer text-bluish5 text-decoration-underline">
                              Customize
                            </span>
                          </template>
                        </v-tooltip>

                        <v-tooltip bottom content-class="text-body-2 ">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" @click="removeIndex(itemIndex)"
                              class="cursor-pointer ms-1 text-error text-decoration-underline">
                              Delete
                            </span>
                          </template>
                        </v-tooltip>
                      </span>
                    </div>
                  </div>
                </v-list-item>
              </v-list>

              <template v-slot:append>
                <v-divider class="border-grey opacity-100"></v-divider>
                <div class="pa-2 pl-lg-3 text-left qty-dropdown">
                  <div class="d-flex justify-space-between align-center mb-2" max-width="100">
                    <div class="font-13 font-weight-medium text-primary ls-25">
                      Quantity <span class="text-light7">(Min {{ getMinQuantity() }})</span>
                    </div>
                    <div class="text--primary text-body-2 font-weight-bold relative">
                      <div class="printed-qty-val d-none">{{ selectedqty }}</div>

                      <!-- Updated Quantity and Price Breakdown Dropdown -->
                      <v-select
                        v-model="selectedqty"
                        :items="qtyprices"
                        item-text="value"
                        item-value="uqty"
                        density="compact"
                        @change="quantityUpdated"
                        ref="select"
                        variant="plain"
                        class="pa-0 my-0 qty-selct border-grey rounded-4 text-right"
                        hide-details
                        :menu-props="{ 
                          closeOnContentClick: false, 
                          offsetY: true, 
                          contentClass: 'pricebreak-list',
                          left: $vuetify.display.mobile,  /* Force left on mobile */
                          transition: 'scale-transition' /* Smooth animation */
                        }"
                        :menu-icon="'mdi-chevron-down'"
                      >
                        <template v-slot:prepend-item>
                          <div class="text-left px-2">
                            <div class="text-body-2 font-weight-bold">Select quantity</div>
                            <div class="text-caption">The more you order, the more you save</div>
                          </div>
                          <v-divider class="mt-2"></v-divider>
                        </template>

                        <template v-slot:item="{ item }">
                          <div class="d-flex justify-space-between px-2">
                            <div class="font-weight-semibold">50</div>
                            <div class="font-weight-medium">${{ item.value }}</div>
                          </div>
                        </template>

                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <div class="px-2 pb-2 d-flex align-center">
                            <v-text-field v-model="custom_quantity" density="compact" variant="text" hide-details
                              placeholder="Enter Quantity" class="mr-2 px-2 font-14 border rounded-4"
                              @keyup.enter="updateCustomQuantity"></v-text-field>
                            <v-btn class="font-weight-semibold bg-dark2 text-white" :disabled="custom_quantity < 50"
                              @click="updateCustomQuantity">
                              Enter
                            </v-btn>
                          </div>
                        </template>
                      </v-select>
                      <!-- End of Dropdown -->
                    </div>
                  </div>

                  <div class="d-flex justify-space-between mb-2">
                    <div class="font-13 text-primary ls-25 font-weight-medium">
                      Cost per swagbox
                      <small class="font-13 text-primary ls-25 font-weight-medium">(Est)</small>
                    </div>
                    <div class="font-13 text-primary ls-25 font-weight-semibold">
                      {{ costPerSwagbox }}
                    </div>
                  </div>
                  <hr class="my-3 border-grey">

                  <div class="d-flex justify-space-between mb-2">
                    <div class="font-13 text-primary ls-25 font-weight-medium">
                      Subtotal
                      <small class="font-13 text-primary ls-25 font-weight-medium">(Est)</small>
                    </div>
                    <div class="font-13 text-primary ls-25 font-weight-semibold">
                      {{ subTotal }}
                    </div>
                  </div>

                  <v-btn block :disabled="!CartItem.length"
                    class="bg-dark2 text-unset font-weight-semibold ls-25 font-14 text-light8 rounded-pill" height="42"
                    variant="text" @click.stop="SelectQuantities = !SelectQuantities">
                    Next - Product sizes
                  </v-btn>
                  <span class="font-12 ls-5 text-center text-light7 font-weight-medium d-block mt-2">No credit card info
                    required</span>
                </div>
              </template>
          </v-navigation-drawer>
        </v-col>
        <!-- Side cart END-->
      </v-row>
    </v-container>

    <!-- Select Quantity Modal START -->
    <v-dialog v-model="SelectQuantities" transition="dialog-bottom-transition" fullscreen bottom scrollable>
      <SelectQuantity v-if="SelectQuantities && !loading" @close-modal="SelectQuantities = false" />
    </v-dialog>
    <!-- Select Quantity Modal START -->

    <v-bottom-navigation :value="value" color="primary" class="d-md-none position-fixed"> 
      <v-row>
        <v-col col="6" class="px-0">
          <v-btn class="d-flex flex-column justify-center align-center w-100 rounded-0" @click.stop="productcatmobile = !productcatmobile">
            <v-icon>mdi-view-grid-outline</v-icon>
            <span>Categories</span>
          </v-btn>

        </v-col>
        <v-col col="6" class="px-0">
          <v-btn class="d-flex flex-column justify-center align-center w-100 rounded-0" @click.stop="cartitems = !cartitems">
            <v-icon>mdi-cart-variant</v-icon>
            <span>Cart Items</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-bottom-navigation>

    <!-- Product Detail view START -->
    <v-dialog v-model="openProdModal" max-width="1080px" v-if="Object.keys(productSelected).length != 0"
      overlay-color="#f1f1f1" content-class="rounded-xl relative" scrollable>
      <ProductDetail :productSelected="productSelected" @close-modal="openProdModal = false" />
    </v-dialog>
    <!-- Product Detail view END -->
  </v-app>
</template>

<script>
// import ProductNav from "./components/ProductNav"
import ProductsList from "./components/ProductsList"
// import ProductsFilters from "./components/ProductsFilters"
import SelectQuantity from "@/components/SelectQuantity"
import ProductDetail from "@/components/ProductDetail"
import { eventBus } from '@/eventBus';

export default {
  name: 'ProductCatalog',
  components: {
    // ProductNav,
    ProductsList,
    // ProductsFilters,
    SelectQuantity,
    ProductDetail
  },

  data() {
    return {
      // selectedSort: 'Featured',
      catalogs: [],
      cartitems: false,
      catLoaderCount: 10,
      productcatmobile: false,
      activeCat: null,
      offset: 100,
      selected: '50',
      selectedProduct: {},
      minqty: [50, 100, 150, 200, 300],
      value: 1,
      loading: true,
      SelectQuantities: false,
      openProdModal: false,
      custom_quantity: null,
      fab: false,
      // qtyprices: [{ uqty: '50', value: '2.00' }, { uqty: '100', value: '1.40' }, { uqty: '150', value: '1.40' }, { uqty: '250', value: '1.00' }, { uqty: '500', value: '0.82' }, { uqty: '1000', value: '0.76' }],
      selectedqty: this.$store.getters.getCart?.quantity ? this.$store.getters.getCart.quantity : '50',
      selectedcat: 0,
      isMobile: false
    }
  },
  watch: {
    openProdModal(newValue, oldValue) {
      if (!newValue) {
        let prodIncluded = this.$store.state.cart?.box_products.find(prod => {
          return prod.id == this.selectedProduct.id
        });
        if (!prodIncluded) {
          this.$helper.functions.resetSpecificProduct(this.selectedProduct)
        }
      }
      if (newValue) {
        this.selectedProduct.number_of_logos = this.$store.getters.numberOfLogos
      }
      // console.log('newValue', newValue)
    },
    SelectQuantities(newValue, oldValue) {
      if (newValue == true) {
        let boxProducts = this.$store.state.cart?.box_products
        let boxQuantity = this.$store.getters.getCart?.quantity
        let variant = {}

        boxProducts.forEach(product => {
          let prodFinalPrice = 0
          let sizes = product.sizes
          let sizeQuantity = 0
          if (sizes.length > 0) {
            sizes.forEach(object => {
              sizeQuantity += Number(object.quantity);
            });

            // if (sizes.length == 1) {
            //   variant = product.variants.find(variant => {
            //     return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
            //   })
            //   if (variant) {
            //     let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, this.$store.getters.getQuantityLimits)
            //     sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
            //     sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
            //     sizes[0].sku = variant.sku
            //   }
            //   prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
            //   product.final_price = prodFinalPrice.toFixed(2)

            //   let logoFinalPrice = (Number(this.$helper.functions.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)

            //   product.final_price = (Number(logoFinalPrice) + Number(product.final_price))
            //   console.log('product.final_price product.final_price', product.final_price)
            // }

            if (sizeQuantity == 0) {
              // let qua = sizes.length - 1
              // let sameValue = (boxQuantity / qua)
              // sameValue = Math.trunc(sameValue)

              let qua = null
              let sameValue = 0
              if (Number.isInteger(boxQuantity / sizes.length)) {
                qua = null
                sameValue = boxQuantity / sizes.length
                sameValue = Math.trunc(sameValue)
              } else {
                qua = sizes.length - 1
                sameValue = (boxQuantity / qua)
                sameValue = Math.trunc(sameValue)
              }

              if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                  return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })
                if (variant) {
                  let volumePrice = this.$helper.functions.getVolumePrice(product, variant, boxQuantity, this.$store.getters.getQuantityLimits)
                  sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                  sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                  sizes[0].sku = variant.sku
                }

                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
              } else {
                sizes.forEach((size, key) => {
                  if (key == qua) {
                    variant = product.variants.find(variant => {
                      return variant.color?.value === product.selected_color.value && variant.size === size.size
                    })
                    if (variant) {
                      let volumePrice = this.$helper.functions.getVolumePrice(product, variant, boxQuantity, this.$store.getters.getQuantityLimits)
                      size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
                      size.price = (size.quantity * volumePrice.price).toFixed(2)
                      size.sku = variant.sku
                    }
                    prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                  } else {
                    variant = product.variants.find(variant => {
                      return variant.color?.value === product.selected_color.value && variant.size === size.size
                    })
                    if (variant) {
                      let volumePrice = this.$helper.functions.getVolumePrice(product, variant, boxQuantity, this.$store.getters.getQuantityLimits)
                      size.quantity = Number(sameValue) * Number(product.quantity)
                      size.price = (size.quantity * volumePrice.price).toFixed(2)
                      size.sku = variant.sku
                    }
                    prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                  }
                });
              }
              product.final_price = prodFinalPrice.toFixed(2)
              let logoFinalPrice = (Number(this.$helper.functions.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)

              product.final_price = (Number(logoFinalPrice) + Number(product.final_price))
              // console.log('product.final_price', product.final_price)



              // let logoFinalPrice = (Number(this.$helper.functions.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)

              // product.final_price = (Number(logoFinalPrice) + Number(product.final_price))

              // console.log('product.final_price index', product.final_price)
              product.price_distributed = true
            }
          } else {
            let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value
            })

            // let volumePrice = this.$helper.functions.getVolumePrice(variant, this.$store.state.cart?.quantity, this.$store.getters.getQuantityLimits)
            let varPrice = (Number(product.quantity) * Number(prodPrice)) * Number(boxQuantity)
            // console.log('VAR', varPrice)
            variant.quantity = Number(boxQuantity) * Number(product.quantity)
            variant.price = varPrice.toFixed(2)
            product.final_price = varPrice.toFixed(2)
          }
        });
      }
    },
  },
  computed: {
    productSelected() {
      return this.selectedProduct
    },

    qtyprices() {
      if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.length) {
        let products = this.$store.state.cart?.box_products
        let boxQuantity = this.$store.getters.getCart?.quantity
        let priceOne = 0
        let priceTwo = 0
        let priceThree = 0
        let priceFour = 0
        let priceFive = 0
        let priceSix = 0
        products.forEach(prod => {
          if (prod.sizes.length) {
            let product = JSON.stringify(prod)
            product = JSON.parse(product)
            let finalPriceOne = this.$helper.functions.getSizedProdPrice(product, 50, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceTwo = this.$helper.functions.getSizedProdPrice(product, 100, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceThree = this.$helper.functions.getSizedProdPrice(product, 150, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceFour = this.$helper.functions.getSizedProdPrice(product, 250, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceFive = this.$helper.functions.getSizedProdPrice(product, 500, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceSix = this.$helper.functions.getSizedProdPrice(product, 1000, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            // console.log('finalPriceOne', finalPriceOne)
            // console.log('finalPriceTwo', finalPriceTwo)
            // console.log('finalPriceThree', finalPriceThree)
            // console.log('finalPriceFour', finalPriceFour)
            // console.log('finalPriceFive', finalPriceFive)
            // console.log('finalPriceSix', finalPriceSix)
            priceOne += finalPriceOne
            priceTwo += finalPriceTwo
            priceThree += finalPriceThree
            priceFour += finalPriceFour
            priceFive += finalPriceFive
            priceSix += finalPriceSix


          } else {
            // let variant = prod.variants.find(variant => {
            //   return variant.color?.value === prod.selected_color.value
            // })

            // priceOne += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 50
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceTwo += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 100
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceThree += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 150
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceFour += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 250
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceFive += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 500
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceSix += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 1000
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)
            priceOne += this.$helper.functions.getItemPrice(prod, 50, this.numberOfLogos)
            priceTwo += this.$helper.functions.getItemPrice(prod, 100, this.numberOfLogos)
            priceThree += this.$helper.functions.getItemPrice(prod, 150, this.numberOfLogos)
            priceFour += this.$helper.functions.getItemPrice(prod, 250, this.numberOfLogos)
            priceFive += this.$helper.functions.getItemPrice(prod, 500, this.numberOfLogos)
            priceSix += this.$helper.functions.getItemPrice(prod, 1000, this.numberOfLogos)

          }

        })

        return [
          { uqty: '50', value: priceOne.toFixed(2) },
          { uqty: '100', value: priceTwo.toFixed(2) },
          { uqty: '150', value: priceThree.toFixed(2) },
          { uqty: '250', value: priceFour.toFixed(2) },
          { uqty: '500', value: priceFive.toFixed(2) },
          { uqty: '1000', value: priceSix.toFixed(2) }];
      } else {
        return []
      }
    },

    logoPrice() {
      return this.$store.getters.logoPrice
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },

    CartItem() {
      return this.$store.state.cart?.box_products ? this.$store.state.cart?.box_products : []
    },

    quantityLimits() {
      return this.$store.getters.getQuantityLimits
    },

    costPerSwagbox() {
      let products = this.$store.state.cart?.box_products
      let boxQuantity = this.$store.getters.getCart?.quantity
      let totalPrice = 0
      if (products && products.length > 0) {
        products.forEach(prod => {
          // if (prod.price == prod.final_price) {
          if (prod.sizes.length) {
            let currentProd = JSON.stringify(prod)
            currentProd = JSON.parse(currentProd)
            let price = this.$helper.functions.getSizedProdPrice(currentProd, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
            totalPrice = Number(totalPrice) + Number(price)
          } else {
            let prodPrice = this.$helper.functions.getItemPrice(prod, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // let price = (Number(prod.quantity) * Number(prodPrice)) + (Number(this.logoPrice) * Number(prod.quantity))
            totalPrice = Number(totalPrice) + Number(prodPrice)
          }
        })

        // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      } else {
        let totalPrice = 0
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      // return this.$helper.functions.costPerSwagbox(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice)
    },

    subTotal() {
      let products = this.$store.state.cart?.box_products
      let boxQuantity = this.$store.getters.getCart?.quantity
      let totalPrice = 0
      if (products && products.length > 0) {
        products.forEach(prod => {
          // if (prod.price == prod.final_price) {
          if (prod.sizes.length) {
            let currentProd = JSON.stringify(prod)
            currentProd = JSON.parse(currentProd)
            let price = this.$helper.functions.getSizedProdPrice(currentProd, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
            totalPrice = (Number(this.$store.getters.getCart?.quantity) * Number(price)) + Number(totalPrice)
          } else {
            let prodPrice = this.$helper.functions.getItemPrice(prod, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // let volumePrice = prod.variants.find(variant => {
            //   return variant.color?.value === prod.selected_color.value
            // }).volume_pricing.find(price => {
            //   // return price.quantity >= this.$store.state.cart?.quantity
            //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
            // })

            // let price = (Number(prod.quantity) * Number(prodPrice)) + (Number(this.logoPrice) * Number(prod.quantity))
            let quantityPrice = Number(prodPrice) * Number(boxQuantity)
            totalPrice = Number(totalPrice) + Number(quantityPrice)
          }

          // } else {
          //   let price = Number(prod.final_price)
          //   totalPrice = Number(totalPrice) + Number(price)
          // }
        })

        // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      } else {
        let totalPrice = 0
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }

      // if (this.$store.state.cart?.box_products) {
      //   let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.logoPrice)
      //   return productsPrice.toLocaleString('en-US', {
      //     style: 'currency',
      //     currency: 'USD',
      //   })
      // } else {
      //   let price = 0
      //   return price.toLocaleString('en-US', {
      //     style: 'currency',
      //     currency: 'USD',
      //   })
      // }
    },
  },
  // For mobile product nav
  mounted() {
    // Initial check and add resize listener
    this.checkMobileView()
    window.addEventListener('resize', this.checkMobileView)
  },
  beforeUnmount() {
    // Remove resize listener
    window.removeEventListener('resize', this.checkMobileView)
  },
  methods: {
    gotoCats(type, item) {
      this.selectedcat = item.id;
      
      // Find the category element and scroll to it
      const categoryElement = document.getElementById(`category-${item.id}`);
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: "smooth" });
      }
    },

    isSectionActive(item) {
      return this.activeCat == item.id ? 'v-list-item--active' : ''
    },

    updateScroll() {
      let scrollPosition = window.scrollY
      this.selectedcat = null
      // $store.getters.products
      this.catalogs.forEach((category, key) => {
        if (document.getElementById('cat' + category.id)) {
          if (
            scrollPosition >=
            document.getElementById('cat' + category.id).offsetTop - this.offset
          ) {
            this.activeCat = category.id
          }
        }
      });
      // console.log('scrollPosition', scrollPosition)
      // this.on = 'customerPart'
      // if (
      //   scrollPosition >=
      //   this.$refs.transportationPart?.offsetTop - this.offset
      // ) {
      //   this.on = 'transportationPart'
      // } else if (
      //   scrollPosition >=
      //   this.$refs.memoPart?.offsetTop - this.offset
      // ) {
      //   this.on = 'memoPart'
      // } else if (
      //   scrollPosition >=
      //   this.$refs.dateTimePart?.offsetTop - this.offset
      // ) {
      //   this.on = 'dateTimePart'
      // } else if (
      //   scrollPosition >=
      //   this.$refs.customerPart?.offsetTop - this.offset
      // ) {
      //   this.on = 'customerPart'
      // }
    },

    getItemIcon(item) {
      let icon = ''
      switch (item.title) {
        case 'Packaging':
          icon = require('@/assets/images/icons/packaging.png');
          break;
        case 'Water Bottles':
          icon = require('@/assets/images/icons/water-bottles.png');
          break;
        case 'Travel Tumblers':
          icon = require('@/assets/images/icons/tumblers.png');
          break;
        case 'Coffee Mugs':
          icon = require('@/assets/images/icons/coffee-mugs.png');
          break;
        case 'Tops':
          icon = require('@/assets/images/icons/tops.png');
          break;
        case 'Sweatshirts':
          icon = require('@/assets/images/icons/sweatshirts.png');
          break;
        case 'Outerwear':
          icon = require('@/assets/images/icons/outerwear.png');
          break;
        case 'Swag Accessories':
          icon = require('@/assets/images/icons/accessories.png');
          break;
        case 'Notebooks & Pens':
          icon = require('@/assets/images/icons/notebooks-pens.png');
          break;
        case 'Backpacks':
          icon = require('@/assets/images/icons/backpacks.png');
          break;
        case 'Totes & Duffles':
          icon = require('@/assets/images/icons/tottes-duffles.png');
          break;
        case 'Bonus Items':
          icon = require('@/assets/images/icons/bonus-items.png');
          break;
        case 'Tech Items':
          icon = require('@/assets/images/icons/tech-items.png');
          break;
        case 'Bonus items':
          icon = require('@/assets/images/icons/bonus-items.png');
          break;
      }
      return icon
    },

    submitQuantity(e) {
      e.preventDefault()
      if (e.keyCode === 13) {
        if (this.custom_quantity > 49) {
          this.updateCustomQuantity()
        }
      }
    },

    updateCustomQuantity() {
      this.$refs.select.isMenuActive = false;
      this.selectedqty = this.custom_quantity
      this.quantityUpdated()
    },

    quantityUpdated() {
      setTimeout(() => {
        this.$store.commit('updateBoxQuantity', this.selectedqty)
        let boxProducts = this.$store.state.cart?.box_products
        boxProducts.forEach(product => {
          if (product.sizes.length) {
            let pricee = this.$helper.functions.getSizedProdPrice(product, this.$store.getters.getCart?.quantity, 0.00)
          }
        })
      }, 50)
    },

    openDetail(product) {
      eventBus.emit('cartProdDetail', product)
    },

    catmobile() {
      this.productcatmobile = !this.productcatmobile;
    },

    getItemQuantity(product) {
      return product.quantity ? product.quantity : 1
    },

    getSizedProdPrice(product) {
      let price = 0
      let sizes = product.sizes
      let sizeQuantity = 0
      let prodFinalPrice = 0
      let boxQuantity = this.$store.getters.getCart?.quantity
      let variant = {}
      sizes.forEach(object => {
        sizeQuantity += Number(object.quantity);
      });

      if (sizes.length == 1) {
        variant = product.variants.find(variant => {
          return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
        })
        if (variant) {
          let volumePrice = variant.volume_pricing.find(price => {
            return price.quantity >= boxQuantity
          })
          sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
          sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
          sizes[0].sku = variant.sku
        }
        prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
        product.final_price = prodFinalPrice.toFixed(2)
      }

      // || !this.cartItemIds.includes(product.id)
      // if (sizeQuantity == 0) {
      let qua = sizes.length - 1
      let sameValue = (boxQuantity / qua)
      sameValue = Math.trunc(sameValue)
      if (sizes.length == 1) {
        variant = product.variants.find(variant => {
          return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
        })
        if (variant) {
          let volumePrice = variant.volume_pricing.find(price => {
            return price.quantity >= boxQuantity
          })
          sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
          sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
          sizes[0].sku = variant.sku
        }

        prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
      } else {
        sizes.forEach((size, key) => {
          if (key == qua) {
            variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value && variant.size === size.size
            })
            if (variant) {
              let volumePrice = variant.volume_pricing.find(price => {
                return price.quantity >= boxQuantity
              })
              size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
              size.price = (size.quantity * volumePrice.price).toFixed(2)
              size.sku = variant.sku
            }
            prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
          } else {
            variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value && variant.size === size.size
            })
            if (variant) {
              let volumePrice = variant.volume_pricing.find(price => {
                return price.quantity >= boxQuantity
              })
              size.quantity = Number(sameValue) * Number(product.quantity)
              size.price = (size.quantity * volumePrice.price).toFixed(2)
              size.sku = variant.sku
            }
            prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
          }
        });
      }
      product.final_price = prodFinalPrice.toFixed(2)
      // }

      let logoFinalPrice = Number(this.logoPrice) * Number(product.quantity)
      // console.log('Final Prod', product)

      price = ((Number(logoFinalPrice) + Number(product.final_price)) / Number(boxQuantity))
      return price
    },

    getItemPrice(item) {
      // if (item.price == item.final_price) {
      let price = 0
      if (item.sizes.length) {
        let prod = JSON.stringify(item)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        // price = (Number(price) / Number(item.quantity)).toFixed(2)
        price = Number(price).toFixed(2)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(item, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        // let volumePrice = item.variants.find(variant => {
        //   return variant.color?.value === item.selected_color.value
        // }).volume_pricing.find(price => {
        //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
        //   // return price.quantity >= this.$store.state.cart?.quantity
        // })
        // price = (Number(prodPrice) / Number(item.quantity)).toFixed(2)
        price = Number(prodPrice).toFixed(2)
      }

      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      // } else {
      //   return this.$helper.functions.getProductAveragePrice(item, this.$store.getters.getCart.quantity, this.logoPrice)
      // }

      // let price = item.final_price / this.$store.getters.getCart.quantity
      // return price.toLocaleString('en-US', {
      // style: 'currency',
      // currency: 'USD',
      // })
    },

    getTotalPrice() {
      let productsPrice = this.$helper.functions.getBoxPrice(this.$store.getters.getCart.box_products, this.$store.getters.getQuantityLimits)
      let minQuantity = this.$store.getters.getCart.quantity
      return (productsPrice * minQuantity).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getMinQuantity() {
      return this.$store.getters.getCart?.min_quantity ? this.$store.getters.getCart.min_quantity : 50
    },

    goToCartPage() {
      this.$router.push({ name: 'Cart' })
    },

    removeIndex(itemIndex) {
      this.$store.commit('removeItemFromCart', itemIndex)
    },

    isEmpty(object) {
      for (let property in object) {
        if (object[property].length != 0) {
          return false;
        }
      }
      return true;
    },

    updateProductList(data) {
      if (data) {
        this.$store.commit('updateFilter', !this.isEmpty(data))
      }
      // this.$store.commit('togglePageLoading', true)
      this.loading = true
      this.$store.dispatch('GET_PRODUCT_LIST', data).then(
        response => {
          if (response.status == 'success') {
            eventBus.emit('scroll')
            this.catalogs = response.products

            // if(data?.scrollTo) {
            //   this.$vuetify.goTo('#cat'+data?.scrollTo.id, { offset: 25 })
            // }
            // this.$store.commit('togglePageLoading', false)
            this.loading = false
          }
        },
        error => {
          // this.$store.commit('togglePageLoading', false)
          this.loading = false
          throw error
        }
      )
    },

    // Scroll to top
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },

    checkMobileView() {
      this.isMobile = window.innerWidth < 960
      if (!this.isMobile) {
        this.productcatmobile = true
        this.cartitems = true
      }
    }

  },
  created() {
    window.addEventListener('scroll', this.updateScroll)
    if (this.$store.getters.getCart?.min_quantity) {
      this.minqty.push(this.$store.getters.getCart?.min_quantity)
      this.minqty = this.minqty.sort(function (a, b) {
        if (a === Infinity)
          return 1;
        else if (isNaN(a))
          return -1;
        else
          return a - b;
      })
    }

    this.$store.dispatch('GET_CATEGORIES_LIST').then(
      response => {
        if (response.status == 'success') {
          // this.loading = false
        }
      },
      error => {
        this.loading = false
        throw error
      }
    )

    if (!this.$store.getters.getCart && this.$store.getters.getCart?.quantity) {
      this.$store.getters.getCart.quantity = 50
    }

    // this.$root.$on('distribution_invalid', (e) => {
    //   this.SelectQuantities = true
    // })

    // this.$root.$on('closeSkuModal', (e) => {
    //   this.SelectQuantities = false
    // })

    eventBus.on('distribution_invalid', () => {
      this.SelectQuantities = true;
    });

    eventBus.on('closeSkuModal', () => {
      this.SelectQuantities = false;
    });

    if (this.$route.query.distribution == 'false') {
      this.SelectQuantities = true
      this.loading = false
    } else {
      let filters = {}
      if (this.$store.getters.getSelectedColors?.length > 0 || this.$store.getters.getSelectedSizes?.length > 0 || this.$store.getters.getSelectedBrand?.length > 0) {
        filters = {
          colors: this.$store.getters.getSelectedColors,
          size: this.$store.getters.getSelectedSizes,
          // range: this.range,
          apply_filter: true,
          brand: this.$store.getters.getSelectedBrand,
        }
      }
      this.updateProductList(filters)
    }

    eventBus.on('openProdDetail', (product) => {
      this.selectedProduct = product
      this.openProdModal = true
    })
  }
}
</script>

<style scoped>
.max-ftr-h {
  max-height: 140px;
  overflow-y: auto;
}

.v-avatar {
  border: 1px solid #d6d6d6 !important;
}

@media (min-width: 960px) {
  .cart-sidebar {
    height: calc(100vh - 60px) !important;
    position: sticky !important;
    top:64px!important;
    align-self: start;
  }

  /* .remove-btn {
    position: absolute;
    right: 2px;
    bottom: 10px;
    z-index: 5;
  } */

}
</style>

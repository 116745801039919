<template>
<v-app>
    <div class="position-relative sky-bg">
        <v-text class="position-absolute left-0 right-0 top-0 bottom-0 bubble-desc z-10">
            <h2 class="font-48 ls-75 mb-2 font-weight-bold">
                High-quality <br />
                swag for every <br />
                occasion
            </h2> 
            <v-btn :to="{ name: 'ProductOnboarding' }" :ripple="false" class="mt-4 text-unset px-5 elevation-4 font-weight-bold bg-dark2 text-light8 font-14 ls-25" min-height="42"  rounded>
                Get started now
            </v-btn> 
        </v-text>
        <div id="background-wrap" class="gradient-blur">
            <v-img v-for="(bubble, index) in bubbles" :key="index" :src="bubble.image" class="bubble" :class="bubble.class" contain />
        </div>
    </div>
</v-app>
</template>

<script>
export default {
    name: "BubblesBackground",
    data() {
        return {
            bubbles: [{
                    class: "x1",
                    image: new URL("@/assets/images/bubble1.png",
                        import.meta.url).href,
                },
                {
                    class: "x2",
                    image: new URL("@/assets/images/bubble2.png",
                        import.meta.url).href,
                },
                {
                    class: "x3",
                    image: new URL("@/assets/images/bubble3.png",
                        import.meta.url).href,
                },
                {
                    class: "x4",
                    image: new URL("@/assets/images/bubble4.png",
                        import.meta.url).href,
                },
                {
                    class: "x5",
                    image: new URL("@/assets/images/bubble5.png",
                        import.meta.url).href,
                },
                {
                    class: "x6",
                    image: new URL("@/assets/images/bubble6.png",
                        import.meta.url).href,
                },
                {
                    class: "x7",
                    image: new URL("@/assets/images/bubble7.png",
                        import.meta.url).href,
                },
                {
                    class: "x8",
                    image: new URL("@/assets/images/bubble8.png",
                        import.meta.url).href,
                },
                {
                    class: "x9",
                    image: new URL("@/assets/images/bubble9.png",
                        import.meta.url).href,
                },
                {
                    class: "x10",
                    image: new URL("@/assets/images/bubble10.png",
                        import.meta.url).href,
                },
            ],
        };
    },
};
</script>

<style scoped></style>

<template>
    <div class="position-relative pb-4 pb-md-1">
      <div class="title-border d-none d-md-block"></div>
      <div class="position-absolute mobile-relative top-0 bottom-0 bg-grad mt-md-n4 pr-md-3">
      <div class="text-black font-weight-semibold ls-75 font-18 d-md-flex text-left align-center">
        Shopping Cart  ({{ CartItem?.length }} items <span class="bg-black d-inline-block rounded-pill mx-2" style="width: 8px; height: 8px;"></span>
        Swagboxes  ) 
      </div>
      </div>
    </div>
 
    <v-responsive class="overflow-auto">
      <v-table class="my-3 elevation-0">
        <thead class="text-light7 font-13 font-weight-semibold bg-grad">
          <tr>
            <th class="text-left font-weight-semibold ps-0">ITEM</th>
            <th class="font-weight-semibold text-center">PRICE</th>
            <th class="font-weight-semibold text-center">QTY PER BOX</th>
            <th class="font-weight-semibold text-center">TOTAL QTY</th>
            <th class="font-weight-semibold text-center">TOTAL</th>
            <th class="font-weight-semibold text-center"></th>
          </tr>
        </thead>
        <tbody v-if="CartItem?.length" class="bg-light1 pt-3">
          <tr v-for="(item, itemIndex) in CartItem" :key="itemIndex">
            <td class="py-3 px-2 px-sm-4 align-content-start border-dash" style="max-width: 250px; min-width:250px;">
              <div class="d-flex flex-wrap flex-sm-nowrap">
                <div>
                  <div class="border rounded-4 pa-1 bg-white-light">
                    <v-img :src="$helper.functions.getProdImage(item)" class="fill-height" min-width="72" max-width="72"></v-img>
                  </div>
                </div>
                <div class="ms-2 ms-md-3 text-left">
                  <div class="text-black font-weight-semibold font-15 ls-75">{{ item.title }}</div>
                  <div v-if="item.showDetails">
                    <div class="text-black font-weight-medium font-12 ls-25">Qty per Box: {{ item.quantity }}</div>
                    <div class="text-black font-weight-medium font-12 ls-25">SKU : SW- 8800 </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">Customization Option : Printed </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">Number of Imprint Colors : 1 imprint Color [+0.00] </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">Print Position: Both Front and Back </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">Artfile: Both Front and Back [+ $4.00] Submitted </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">Setup Charge : One Time Fee Per Order [+$50.00] </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">Proof Charge : +$10.00 </div> 
                    <div class="text-black font-weight-medium font-12 ls-25">(Quantity: 3 Per box - Size: One Size - Color: Transparent) </div> 
                  </div>
                  <div class="text-bluish6 font-weight-medium font-12 ls-25 cursor-pointer" @click="toggleDetails(item)">
                    {{ item.showDetails ? 'Hide Details' : 'View Details' }}
                  </div>
                </div>
              </div>
            </td>
            <td class="py-3 px-2 px-sm-4 align-content-start border-dash">
              <div class="text-black font-weight-medium font-15 ls-75">$1.74</div>
            </td>
            <td class="py-3 px-2 px-sm-4 align-content-start border-dash" style="min-width: 150px;">
              <div class="text-no-wrap text-primary text-caption font-weight-bold">
                3
              </div>
            </td>
            <td class="py-3 px-2 px-sm-4 align-content-start border-dash" style="min-width: 150px;">
              <div class="text-black font-weight-medium font-15 ls-75">
                {{ productPrice(item) }}
              </div>
            </td>
            <td class="py-3 px-2 px-sm-4 align-content-start border-dash" style="max-width: 150px;">
              <div class="d-flex justify-center align-center mb-1">
                <div class="text-bluish3 font-weight-semibold font-15 ls-75">{{ productPrice(item) }}</div>
              </div> 
            </td>
            <td class="py-3 px-2 px-sm-4 align-content-start border-dash"  style="max-width: 90px;"> 
              <div class="d-flex mt-md-10 ga-1">
                <v-btn
                variant="text"
                class="rounded-4 pa-0 text-white bg-dark2 font-weight-medium"
                min-width="40"
                width="40"
                height="40"
                @click.stop="SelectQuantities = !SelectQuantities"
              >
                <v-icon><i class="ri-pencil-line font-16"></i></v-icon> 
              </v-btn>

              <v-btn
                variant="text"
                class="rounded-4 pa-0 bg-error font-weight-medium"
                min-width="40"
                width="40"
                height="40"
                @click="removeIndex(itemIndex)"
              >
                <v-icon><i class="ri-delete-bin-line font-16"></i></v-icon> 
              </v-btn>
 
              </div> 
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-responsive> 

    
    <!-- Select Quantity Modal -->
    <v-dialog v-model="SelectQuantities" transition="dialog-bottom-transition" fullscreen> 
      <SelectQuantity @close-modal="SelectQuantities = false" /> 
    </v-dialog>


    <!-- Price Breakdown Sidebar -->
    <v-dialog v-model="PriceBreak" overlay-color="#f1f1f1" max-width="1000px" content-class="rounded-xl" scrollable>
      <v-btn @click="PriceBreak = false" color="elevation-0" icon class="position-absolute top-0 right-0 mt-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <template v-if="selectedProd && PriceBreak">
        <PriceBreakdown :selectedProd="selectedProd" />
      </template>
    </v-dialog>

    <!-- Product Detail View -->
    <v-dialog v-model="openProdModal" max-width="1080px" v-if="Object.keys(productSelected).length"
      overlay-color="#f1f1f1" content-class="rounded-xl" scrollable>

      <ProductDetail :productSelected="productSelected" />
    </v-dialog>
</template>


<script>
import SelectQuantity from "@/components/SelectQuantity"
import PriceBreakdown from "@/components/PriceBreakdown"
import ProductDetail from "@/components/ProductDetail"
import { eventBus } from '@/eventBus';

export default {
  name: 'CartItems',
  components: {
    SelectQuantity,
    PriceBreakdown,
    ProductDetail
  },
  mounted() { },
  computed: {
    productSelected() {
      return this.selectedProduct
    },

    CartItem() {
      if (this.$store.state.cart?.box_products) {
        // return cloneDeep(this.$store.state.cart?.box_products)
        return this.$store.state.cart?.box_products
      } else {
        return []
      }
    },

    logoPrice() {
      return this.$store.getters.logoPrice
    },

    allValidated() {
      return this.$helper.functions.allValidated(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity)
    },

    quantityLimits() {
      return this.$store.getters.getQuantityLimits
    },
    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },
  },
  data() {
    return {
      SelectQuantities: false,
      PriceBreak: false,
      selectedProd: {},
      openProdModal: false,
      selectedProduct: {},
    }
  },
  methods: {
    productPrice(product) {

      let price = null
      if (product.sizes.length) {
        let prod = JSON.stringify(product)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        price = Number(price.toFixed(2)) * Number(this.$store.state.cart?.quantity)
        let logoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        let logoFinalPrice = (Number(logoPrice) * Number(product.quantity)) * Number(this.$store.getters.getCart?.quantity)
        console.log('logoPrice', logoPrice)
        console.log('logoFinalPrice', logoFinalPrice)
        product.imprint_color_price = logoFinalPrice
        product.logo_price = logoPrice
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        // let volumePrice = product.variants.find(variant => {
        //   return variant.color?.value === product.selected_color.value
        // }).volume_pricing.find(price => {
        //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
        //   // return price.quantity >= this.$store.state.cart?.quantity
        // })
        // price = ((Number(product.quantity) * Number(prodPrice)) + (Number(this.logoPrice) * Number(product.quantity))) * Number(this.$store.state.cart?.quantity)
        price = Number(prodPrice) * Number(this.$store.state.cart?.quantity)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getItemPrice(item) {
      let price = 0
      if (item.sizes.length) {
        let prod = JSON.stringify(item)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(item, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        // let volumePrice = item.variants.find(variant => {
        //   return variant.color?.value === item.selected_color.value
        // }).volume_pricing.find(price => {
        //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
        //   // return price.quantity >= this.$store.state.cart?.quantity
        // })
        price = Number(prodPrice) + Number(this.logoPrice)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    closeQuantityDialogue() {
      if (this.allValidated) {
        // this.$root.$emit('modalClosed', true)
        eventBus.emit('modalClosed', true)
        this.SelectQuantities = false
      }
    },

    openProdPriceBreakdown(item) {
      this.selectedProd = item
      this.PriceBreak = !this.PriceBreak
    },

    // getImage(image) {
    //   // let imageContent = JSON.parse(image)
    //   return image?.[0]?.data?.link ? image?.[0]?.data?.link : ''
    // },

    // removeIndex(itemIndex) {
    //   // this.$helper.functions.resetItem(this.$store.getters.products, this.$store.state.cart?.box_products[itemIndex])
    //   this.$store.commit('removeItemFromCart', itemIndex)
    //   if (!this.$store.state.cart?.box_products || this.$store.state.cart?.box_products?.length == 0) {
    //     this.$router.push({ name: 'CartEmpty' })
    //   }
    // }
    toggleDetails(item) {
      item.showDetails = !item.showDetails;
    }

  },

  created() {
    // this.CartItem = this.$store.state.cart.box_products
    // this.$root.$on('closeModal', () => {
    //   this.SelectQuantities = false
    // })
    eventBus.on('closeModal', () => {
      this.SelectQuantities = false
    })
    if (!this.allValidated) {
      this.SelectQuantities = true
    }

    // this.$root.$on('openProdDetailCart', (product) => {
    //   this.selectedProduct = product
    //   this.openProdModal = true
    // })

    eventBus.on('openProdDetailCart', (product) => {
      this.selectedProduct = product
      this.openProdModal = true
    })
  }
}
</script>

<style scoped> 
</style>

<template>
  <v-card height="100%">
    <!-- Header title -->
    <v-card-title class="pe-2 ps-4 text-center py-3 mt-0 border-b-grey">
      <v-row justify="space-between" class="w-100 align-md-center">
        <!-- Title on the Left -->
        <v-col cols="10" md="auto" class="text-wrap text-left">
          <span class="text-primary font-weight-normal font-15 ls-75 d-block">
            Please select the sizes and distribute quantities for products in your cart.
          </span>
        </v-col>

        <!-- Close Button on the Right -->
        <v-col cols="2" md="auto" class="text-end">
          <v-btn @click="$emit('close-modal')" variant="text"
            class="rounded-circle pa-0 font-20 font-weight-light text-light7 font-24" width="24" height="24" min-width="24">
            <i class="ri-close-circle-line"></i>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>



    <!-- Item Grid -->
    <v-card-text>
      <v-sheet class="text-center font-24 font-weight-semibold ls-75 text-primary mb-md-5 my-3">
        I would like to order <br class="d-sm-none">
        <div class="input-sizer" style="display: inline-block;">
          <input 
            type="text" 
            class="text-bluish5 input-sizer border-b-bluish border-0"
            v-model="quantity"
            ref="inputField" 
            @input="updateInputWidth" 
          />
        </div>
        boxes
        <small>
          (min qty. {{ $store.getters.getCart.min_quantity }})
        </small>
      </v-sheet>



      <v-container fluid class="px-0 px-md-auto">
        <v-row class="mx-0">
          <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, itemIndex) in cartItems" :key="itemIndex" class="mb-md-3 px-2">
            <v-card class="text-center pa-4 pa-md-4 rounded-16 product-card relative"
              :class="$helper.functions.getRemainingCartQuantity(item, item.quantity, $store.getters.getCart?.quantity) != 0 ? 'invalid' : ''"
              height="100%">
              <div class="mb-3 pointer" @click.stop="openProductDetail(item)">
                <v-img :src="$helper.functions.getProdImage(item)" contain height="200px"
                  class="img-fluid mx-auto rounded-lg"></v-img>
              </div>
              <div class="text-left">
                <v-card-title class="font-weight-semibold text-dark2 text-left px-0 ls-75 font-16 ">
                  {{ item.title }}
                </v-card-title>
                <div class="font-13 font-weight-medium  text-light7 ls-25 mb-2">
                  Color:
                  <v-avatar class="pe-1" :color="$helper.functions.getColorCode(item.selected_color)"
                    size="10"></v-avatar>
                    <span class="font-weight-semibold ls-25 font-13 text-primary ms-1">
                  {{ $helper.functions.getColorName(item.selected_color) }}
                  </span>
                </div>
                <div class="text-light7 font-13 ls-25 font-weight-medium  mb-2" v-if="item.sizes.length > 1">
                  Remaining in cart: <span class="font-weight-semibold text-primary">{{ getRemainingCartQuantity(item)
                  }}</span>
                </div>
                <div class="text-grey-darken-2 text-body-2 font-weight-medium mb-2" v-if="item.sizes.length == 1">
                  In cart: {{ $store.getters.getCart?.quantity }} (Doesn’t need distribution)
                </div>
                <div class="font-13 font-weight-medium ls-25 text-light10">
                  Qty in each box: <span>3(Max 10 in each box)</span>
                </div>
                 <!-- QTy inputs -->
                 <div class="qty-input mt-3 border-grey rounded-lg pa-2" v-if="item.sizes.length > 1">
                    <div v-for="(size, sizeIndex) in item.sizes" :key="sizeIndex">
                      <div class="qty-inputs d-flex align-center text-center ga-0" v-if="sizeExist(item, size)">
                        <label class="qty-label">{{ size.size }}</label>
                        <v-text-field 
                          v-model="size.quantity" 
                          type="number"
                          variant="plain"
                          density="compact"
                          class="qty-field border-grey"
                          placeholder="0" 
                          hide-details
                          height="auto"
                          @change="updateprodPrice(size, item)"
                          @input="validateSizeQuantity(size, item.sizes, item.quantity)"
                        />
                      </div>
                    </div>
                  </div> 
              </div>

              <v-card-actions class="pa-0 transparent">
                <v-sheet class="d-flex justify-space-between align-center transparent qty-action-btns">
                  <div class="text-left">
                    <div class="text-light7 font-weight-normal ls-75 font-15">
                      From USD <span class="font-weight-semibold text-primary">{{ getItemPrice(item) }}</span>
                    </div>
                    <div>
                      <v-btn text v-if="item.sizes.length" class="text-bluish3 font-12 font-weight-medium text-unset ls-25 pa-0"
                        height="auto" variant="text" @click.stop="openProdPriceBreakdown(item)">View breakdown
                      </v-btn>
                    </div>
                    <div>
                      <!-- <v-btn 
                        class="text-bluish3 font-12 font-weight-medium ls-25 pa-0"
                        height="auto"
                        variant="text"
                        @click.stop="FlatStorage = true"  
                      >
                        Flat Storage
                      </v-btn> -->
                    </div>
                  </div>

                  <div>
                    <v-btn variant="text" color="primary" class="rounded-4 mx-1 pa-0 border-bluish2" min-width="34"
                      width="34" height="34" @click.stop="openProductDetail(item)">
                      <v-icon class="ri-edit-line font-weight-light"></v-icon>
                    </v-btn>

                    <v-btn variant="text" color="red-darken-3" class="rounded-4 mx-1 pa-0 border-error" min-width="34"
                      width="34" height="34" @click="removeIndex(itemIndex)">
                      <v-icon class="ri-delete-bin-line font-weight-light"></v-icon>
                    </v-btn>
                  </div>

                </v-sheet>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <!-- Footer Actions -->
    <v-card-actions class="pa-2 pa-md-4 text-end d-flex justify-end">
      <v-row class="justify-end align-center">
        <v-col cols="auto text-left">
          <div class="font-13 ls-25 font-weight-medium font-13 text-light7 mb-1">Cost per swagbox</div>
          <div class="font-13 ls-25 font-weight-medium font-13 text-light7">Subtotal (Est)</div>

        </v-col>

        <v-col cols="auto text-right">

          <div class="font-13 ls-25 font-weight-semibold font-13 text-primary mb-1">$20.06</div>
          <div class="font-13 ls-25 font-weight-semibold font-13 text-bluish4">$522.00</div>
        </v-col>

        <v-col cols="auto">
          <v-btn class="font-weight-semibold text-none rounded-pill bg-dark2 text-light8 px-md-12 px-3" min-height="42"
            v-if="$route.name == 'ProductCatalog'" @click="goToCartPage" :disabled="!allValidated">
            Next: Checkout
          </v-btn>
          <v-btn class="font-weight-semibold text-none rounded-pill text-light8 px-md-12 px-3" min-height="42"
            v-if="$route.name == 'Cart' || $route.name == 'PaymentOptions'" color="primary" @click="closeModal"
            :disabled="!allValidated">
            Save Changes
          </v-btn>
        </v-col>
      </v-row>

    </v-card-actions>

    <!-- Price breakdown Sidebar -->
    <v-dialog v-model="PriceBreak" overlay-color="#f1f1f1" max-width="1000px" class="rounded-xl relative" scrollable>
      <template v-if="selectedProd && PriceBreak">
        <PriceBreakdown :selectedProd="selectedProd" @close="PriceBreak = false" />
      </template>
    </v-dialog>

    <!-- Flat Rate Storage and Fulfillment -->
        <v-dialog 
          v-model="FlatStorage" 
          overlay-color="#f1f1f1" 
          max-width="1000px" 
          class="rounded-xl relative" 
          scrollable
        >
          <FlatStorage @close="FlatStorage = false" />
        </v-dialog>


    <!-- Product Detail view -->
    <v-dialog v-model="productdetail" max-width="1080px" overlay-color="#f1f1f1" class="rounded-xl relative" scrollable>
      <!-- <v-btn @click="productdetail = false" class="elevation-0" fab absolute top right height="40" width="40">
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
      <template v-if="productdetail">
        <ProductDetail :productSelected="productSelected" />
      </template>
    </v-dialog>
  </v-card>
</template>


<script>
import { cloneDeep } from 'lodash'
import PriceBreakdown from "@/components/PriceBreakdown"
import FlatStorage from "@/components/FlatStorage" 
import ProductDetail from "@/components/ProductDetail"

export default {
  name: 'SelectQuantity',
  components: {
    PriceBreakdown,
    FlatStorage,
    ProductDetail
  },
  data() {
    return {
      productdetail: false,
      FlatStorage: false,
      selectedProduct: {},
      SelectQuantities: true,
      PopoverWarning: false,
      PriceBreak: false,
      quantity: this.$store.getters.getCart?.quantity,
      lastMinQuantity: null,
      selectedProd: {},
    }
  },
  mounted() { },
  watch: {
    quantity(newValue, oldValue) {
      if (newValue < this.$store.getters.getCart.min_quantity && oldValue >= this.$store.getters.getCart.min_quantity) {
        this.lastMinQuantity = oldValue
      }
    }
  },
  computed: {
    logoPrice() {
      return this.$store.getters.logoPrice
    },

    allValidated() {
      return this.$helper.functions.allValidated(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity)
    },

    productSelected() {
      return this.selectedProduct
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },

    cartItems() {
      if (this.$store.state.cart?.box_products) {
        return this.$store.state.cart?.box_products
        // return cloneDeep(this.$store.state.cart?.box_products)
      } else {
        return []
      }
    },

    costPerSwagbox() {
      return this.$helper.functions.costPerSwagbox(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
    },

    subTotal() {
      if (this.$store.state.cart?.box_products) {
        let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        return productsPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        // let minQuantity = this.$store.getters.getCart.quantity
        // return (productsPrice * minQuantity).toLocaleString('en-US', {
        //   style: 'currency',
        //   currency: 'USD',
        // })
      } else {
        let price = 0
        return price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }

    }
  },
  methods: {
    getRemainingCartQuantity(item) {
      let remainingQuantity = this.$helper.functions.getRemainingCartQuantity(item, item.quantity,
        this.$store.getters.getCart?.quantity)
      if (remainingQuantity < 0) {
        let prod = this.$store.state.cart?.box_products.find(prod => {
          return prod.id == item.id
        })
        delete prod.price_distributed;
        this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, 0.00, this.$store.getters.getQuantityLimits)
        let updatedProd = this.$store.state.cart?.box_products.find(prod => {
          return prod.id == item.id
        })
        updatedProd.price_distributed = true;
      }

      // console.log('remainingQuantity', remainingQuantity)
      // if (remainingQuantity < 0) {
      //   console.log('LES')
      //   // this.$helper.functions.distributeQuantity(item, this.$store.getters.getQuantityLimits, this.$store.getters.getCart?.quantity)
      //   let product = item
      //   let quantityLimits = this.$store.getters.getQuantityLimits
      //   let boxQuantity = this.$store.getters.getCart?.quantity
      //   let sizes = product.sizes
      //   let sizeQuantity = 0
      //   let variant = {}
      //   let prodFinalPrice = 0
      //   if (sizes.length > 0) {
      //     sizes.forEach(object => {
      //       sizeQuantity += Number(object.quantity);
      //     });

      //     if (sizes.length == 1) {
      //       variant = product.variants.find(variant => {
      //         return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
      //       })
      //       if (variant) {
      //         let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //         sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
      //         sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
      //         sizes[0].sku = variant.sku
      //       }
      //       prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
      //       product.final_price = prodFinalPrice.toFixed(2)
      //     }

      //     // if (sizeQuantity == 0) {
      //       let qua = sizes.length - 1
      //       let sameValue = (boxQuantity / qua)
      //       sameValue = Math.trunc(sameValue)
      //       if (sizes.length == 1) {
      //         variant = product.variants.find(variant => {
      //           return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
      //         })
      //         if (variant) {
      //           let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //           sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
      //           sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
      //           sizes[0].sku = variant.sku
      //         }

      //         prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
      //       } else {
      //         sizes.forEach((size, key) => {
      //           if (key == qua) {
      //             variant = product.variants.find(variant => {
      //               return variant.color?.value === product.selected_color.value && variant.size === size.size
      //             })
      //             if (variant) {
      //               let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //               size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
      //               size.price = (size.quantity * volumePrice.price).toFixed(2)
      //               size.sku = variant.sku
      //             }
      //             prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
      //           } else {
      //             variant = product.variants.find(variant => {
      //               return variant.color?.value === product.selected_color.value && variant.size === size.size
      //             })
      //             if (variant) {
      //               let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //               size.quantity = Number(sameValue) * Number(product.quantity)
      //               size.price = (size.quantity * volumePrice.price).toFixed(2)
      //               size.sku = variant.sku
      //             }
      //             prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
      //           }
      //         });
      //       }
      //       product.final_price = prodFinalPrice.toFixed(2)
      //       product.price_distributed = true
      //     // }
      //   }
      // }
      return remainingQuantity
    },

    getItemPrice(product) {
      // let pricee = this.$helper.functions.getItemPrice(item, this.$store.getters.getCart?.quantity, this.numberOfLogos)
      // return pricee.toFixed(2).toLocaleString('en-US', {
      //   style: 'currency',
      //   currency: 'USD',
      // })
      let price = null
      if (product.sizes.length) {
        let prod = JSON.stringify(product)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        product.imprint_color_price = productLogoPrice
        // console.log('productLogoPrice', productLogoPrice)
        // price = (Number(product.quantity) * Number(prodPrice)) + (Number(productLogoPrice) * Number(product.quantity))
        price = Number(prodPrice)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    sizeExist(product, size) {
      let variant = {}
      // product.sizes.forEach(object => {
      variant = product.variants.find(variant => {
        return variant.color?.value === product.selected_color.value && variant.size === size.size
      })
      if (variant) {
        return true
      } else {
        return false
      }
      // });
    },

    closeModal() {
      this.$root.$emit('closeModal')
    },

    validateSizeQuantity(size, sizes, itemQuantity) {
      let boxQuantity = Number(this.$store.getters.getCart?.quantity) * Number(itemQuantity)
      let sizeQuantity = 0
      sizes.forEach(object => {
        sizeQuantity += Number(object.quantity);
      });
      if (sizeQuantity > boxQuantity) {
        this.$store.commit("showAlert", {
          text: "You only have " + boxQuantity + " units available to distribute. Either increase the number of boxes or redistribute them to fit the number.",
          successStatus: false,
          alertColor: 'red darken-4'
        });
        let overQuantity = Number(sizeQuantity) - Number(boxQuantity)
        size.quantity = size.quantity - overQuantity
      }
    },

    removeIndex(itemIndex) {
      // let boxProduct = this.$store.state.cart?.box_products[itemIndex]
      // let categoryId = boxProduct.pivot.category_id

      // this.$helper.functions.resetItem(this.$store.getters.products, this.$store.state.cart?.box_products[itemIndex])
      // console.log('boxProduct', boxProduct)
      // let product = this.$store.getters.products.find(obj => {
      //   return obj.id == categoryId
      // }).products.find(prod => {
      //   return prod.id == boxProduct.id
      // })
      // console.log('product', product)
      // product.selected_color = null
      // // product.price_distributed = false
      // delete product.price_distributed;
      // product.quantity = 1
      // product.final_price = product.price
      // console.log('product after', product)
      this.$store.commit('removeItemFromCart', itemIndex)

      if (this.$store.state.cart?.box_products.length < 1) {
        this.$root.$emit('closeSkuModal')
      }
    },

    openProductDetail(product) {
      let prod = this.$store.state.cart?.box_products.find(boxProduct => {
        return boxProduct.id === product.id
      })
      this.selectedProduct = prod
      this.productdetail = true
      if (this.$route.name == 'ProductCatalog') {
        this.$root.$emit('openProdDetail', this.$store.state.cart?.box_products.find(prod => prod.id == product.id))
      } else {
        this.$root.$emit('openProdDetailCart', this.$store.state.cart?.box_products.find(prod => prod.id == product.id))
      }

    },

    // getRemainingCartQuantity(item) {
    //   console.log('ds')
    //   let sizes = item.sizes
    //   let quantity = this.$store.getters.getCart?.quantity
    //   let values = this.$helper.functions.getRemainingCartQuantity(sizes, quantity)
    //   return '123'
    // },

    resetQuantity() {
      if (this.quantity < this.$store.getters.getCart.min_quantity) {
        this.quantity = this.lastMinQuantity
      }
      // let boxProducts = this.$store.state.cart?.box_products
      // boxProducts.forEach(product => {
      //   if(product.sizes.length) {
      //     let pricee = this.$helper.functions.getSizedProdPrice(product, this.$store.getters.getCart?.quantity, 0.00)
      //   }
      // })

    },

    goToCartPage() {
      this.$router.push({ name: 'Cart' })
    },

    updateQuantity(e) {
      if (this.quantity >= this.$store.getters.getCart?.min_quantity) {
        this.$store.commit('updateBoxQuantity', this.quantity)

        let boxProducts = this.$store.state.cart?.box_products
        let boxQuantity = this.$store.getters.getCart?.quantity
        let variant = {}

        boxProducts.forEach(product => {
          // console.log('PROD')
          // this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity)
          if (product.sizes.length > 0) {
            let pricee = this.$helper.functions.getSizedProdPrice(product, this.$store.getters.getCart?.quantity, 0.00)
          } else {
            // let boxProduct = this.$store.state.cart?.box_products.find(prod => {
            //   return prod.id === product.id
            // })
            // boxProducts.forEach(product => {
            // product.variants.forEach((variant, key) => {
            //   delete variant["quantity"]
            //   delete variant["price"]
            // });
            // })

            // let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // product.imprint_color_price = productLogoPrice

            let variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value
            })
            let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
            let updatedBoxQuantity = Number(boxQuantity) * Number(product.quantity)
            let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

            let volumePrice = variant.volume_pricing.find(price => {
              return price.quantity == expectedQuantity
            })

            let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // console.log('volumePrice', volumePrice)
            // console.log('productLogoPrice', productLogoPrice)

            variant.quantity = Number(this.$store.state.cart?.quantity) * Number(product.quantity)
            // console.log('variant.quantity', variant.quantity)
            // variant.price = (Number(product.quantity) * Number(volumePrice.price) * Number(this.$store.state.cart?.quantity)) + (Number(productLogoPrice) * Number(product.quantity))
            variant.price = (Number(volumePrice.price) + Number(productLogoPrice)) * Number(product.quantity) * Number(variant.quantity)

            // IF NEEDS TO REVERTED
            // variant = product.variants.find(variant => {
            //   return variant.color?.value === product.selected_color.value
            // })
            // let volumePrice = variant.volume_pricing.find(price => {
            //   return price.quantity >= this.$store.state.cart?.quantity
            // })

            // let varPrice = (Number(volumePrice.price) + (Number(this.logoPrice) * Number(product.quantity))) * Number(this.$store.state.cart?.quantity)
            // variant.quantity = Number(this.$store.state.cart?.quantity) * Number(product.quantity)
            // variant.price = varPrice.toFixed(2)
            // product.final_price = varPrice.toFixed(2)
          }

          // let prodFinalPrice = 0
          // let sizes = product.sizes
          // let sizeQuantity = 0
          // if (sizes.length > 0) {
          //   sizes.forEach(object => {
          //     sizeQuantity += Number(object.quantity);
          //   });
          //   let qua = sizes.length - 1
          //   let sameValue = (boxQuantity / qua).toFixed(0)
          //   if (sizes.length == 1) {
          //     variant = product.variants.find(variant => {
          //       return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
          //     })
          //     sizes[0].quantity = boxQuantity
          //     sizes[0].price = (sizes[0].quantity * variant.price).toFixed(2)
          //     sizes[0].sku = variant.sku
          //     prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
          //     product.final_price = prodFinalPrice.toFixed(2)
          //     this.$store.commit('updateCartProd', product)
          //   }
          // } else {
          //   let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity)
          //   variant = product.variants.find(variant => {
          //     return variant.color?.value === product.selected_color.value
          //   })

          //   // let volumePrice = this.$helper.functions.getVolumePrice(variant, this.$store.state.cart?.quantity, this.$store.getters.getQuantityLimits)
          //   let varPrice = (Number(product.quantity) * Number(prodPrice)) * Number(boxQuantity)
          //   // console.log('VAR', varPrice)
          //   variant.quantity = Number(boxQuantity) * Number(product.quantity)
          //   variant.price = varPrice.toFixed(2)
          //   product.final_price = varPrice.toFixed(2)
          // }
        });
      } else {
        return e.preventDefault()
      }
    },

    openProdPriceBreakdown(item) {
      this.selectedProd = item
      this.PriceBreak = !this.PriceBreak
    },

    updateInputWidth() {
        this.$nextTick(() => {
          const input = this.$refs.inputField;
          if (input) {
            const length = input.value.length || 1; // Ensure at least 1 character width
            input.style.width = `${length * 18}px`; // Adjust width dynamically
          }
        });
      },

    updateprodPrice(size, item) {
      let variants = item.variants
      var variant = variants.find(variant => {
        return variant.color?.value === item.selected_color.value && variant.size === size.size
      })
      let volumePrice = this.$helper.functions.getVolumePrice(item, variant, this.$store.getters.getCart?.quantity, this.$store.getters.getQuantityLimits)
      let tot = volumePrice.price * size.quantity
      size.price = tot.toFixed(2)
      size.sku = variant.sku
      let product = this.$helper.functions.updateProductPrice(item, this.$store.getters.getCart?.quantity)
      this.$store.commit('resetCartProducts', this.cartItems)
    }
  },
}
</script>

<style scoped>
 
</style>

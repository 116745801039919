<template>
  <v-card class="rounded-16">
    <v-card-title class="py-4 font-weight-semibold text-primary font-20 ls-75 d-flex justify-space-between align-center">
      <span>Price breakdown</span>
      <v-btn @click="$emit('close')" variant="text" color="primary"
        class="rounded-circle pa-0 font-20 font-weight-light" width="24" height="24" min-width="24">
        <i class="ri-close-circle-line"></i>
      </v-btn>

    </v-card-title>

    <v-divider class="border-grey opacity-100"></v-divider>
     
    <v-card-text class="px-0 py-0">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" class="pe-md-0">
            <div>
              <v-img :src="$helper.functions.getProdImage(selectedProd)" contain height="300px"
                class="img-fluid mx-auto rounded-lg elevation-1">
              </v-img>
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <div class="rounded-lg elevation-1 px-3 py-3">
              <div class="font-18 font-weight-semibold text-black text-left mb-1">
                Unisex heavy cotton tee
              </div>
              <div class="text-light7 font-14 font-weight-normal text-left mb-3">
                Remaining in cart: {{
                $helper.functions.getRemainingCartQuantity(selectedProd, selectedProd.quantity,
                $store.getters.getCart?.quantity)
                }}
              </div> 
              <!-- <v-divider></v-divider> --> 
              <!-- Price breakdown table -->
              <v-table class="my-3 elevation-0 transparent border rounded-lg" density="compact">
                <tbody class="text-left text-caption font-weight-extramedium">
                  <tr v-for="(size, sizeIndex) in selectedProd.sizes" :key="sizeIndex">
                    <template v-if="sizeExist(selectedProd, size)">
                      <td class="text-uppercase">{{ size.size }}</td>
                      <td>{{ size.quantity }} x {{ $helper.functions.getSizeUnitPrice(size, selectedProd,
                        $store.getters.getCart.quantity) }}</td>
                      <td>{{ getFullSizePrice(size) }}</td>
                    </template>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Sidebar footer price -->
    <v-divider></v-divider>
    <v-card-actions class="py-3 px-lg-10 px-7 text-end d-flex justify-end align-center">
      <div class="text-right">
        <div class="font-18 lh-22 font-weight-bold text-primary">
          {{ getProdFinalPrice() }}
        </div>
        <small class="text-caption font-weight-medium text-light7">
          For {{ $store.getters.getCart.quantity }} boxes
        </small>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PriceBreakdown',
  props: {
    selectedProd: {
      type: Object,
      default: function () {
        return {}
      },
      required: true
    }
  },
  components: {},
  mounted() { },
  computed: {
    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },
  },
  data() {
    return {}
  },
  methods: {
    getFullSizePrice(size) {
      let price = 0
      let variant = this.selectedProd.variants.find(variant => {
        return variant.color?.value === this.selectedProd.selected_color.value && variant.size === size.size
      })

      let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
      let updatedBoxQuantity = Number(this.$store.getters.getCart.quantity) * Number(this.selectedProd.quantity)
      let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

      let volumePrice = variant.volume_pricing.find(price => {
        return price.quantity == expectedQuantity
      })

      // let volumePrice = variant.volume_pricing.find(price => {
      //   return price.quantity >= this.$store.getters.getCart.quantity
      // })
      // let sizeUnitPrice = this.$helper.functions.getSizeUnitPrice(size, this.selectedProd)
      price = (size.quantity * volumePrice.price)
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getProdFinalPrice() {
      let product = this.selectedProd
      let price = null
      if (product.sizes.length) {
        let prod = JSON.stringify(product)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, 0.00, this.$store.getters.getQuantityLimits)
        price = Number(price.toFixed(2)) * Number(this.$store.state.cart?.quantity)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity)
        product.imprint_color_price = productLogoPrice
        // console.log('prodPrice', prodPrice)
        price = Number(prodPrice) * Number(this.$store.state.cart?.quantity)
        // price = Number(prodPrice)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    // getProdFinalPrice() {
    //   let price = this.selectedProd.final_price
    //   console.log(this.selectedProd.final_price)
    //   price = Number(price) + (Number(this.$store.getters.logoPrice) * this.selectedProd.quantity)
    //   // * this.$store.getters.getCart.quantity
    //   return price.toLocaleString('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //   })
    // },

    sizeExist(product, size) {
      let variant = {}
      // product.sizes.forEach(object => {
      variant = product.variants.find(variant => {
        return variant.color?.value === product.selected_color.value && variant.size === size.size
      })
      if (variant) {
        return true
      } else {
        return false
      }
      // });
    },
  },
}
</script>

<style scoped>

</style>

<template>
<v-container fluid class="gradient-bg pb-md-10 py-10 pt-md-0">
    <!-- Hero Text -->
    <v-row class="position-absolute position-mob-rel mt-sm-15 ms-sm-10 z-1 text-white">
        <v-col cols="12" md="8" lg="6" class="pe-lg-13">
            <div class="text-left pt-lg-15 ps-lg-10">
                <h1 class="ls-75 mb-md-4 mb-3">
                    Get A Free Sample Pack Of The Swag Experience
                </h1>

                <v-row>
                    <v-col cols="12" sm="10">
                        <p class="font-16 ls-75 font-weight-regular mb-md-3">
                            Get a sense of what it’s like to unbox one of our fully custom
                            swag packs. Request your sample pack below and we’ll have it to
                            your office the next day, totally free*
                        </p>
                    </v-col>
                </v-row> 
                <v-btn min-height="42" variant="outlined" color="white"
                    class="mt-4 px-7 font-16 font-weight-bold mobile-dark" rounded :to="{ name: 'ProductOnboarding' }">
                    Shop Swagbox
                    <v-icon class="ml-2 ri-arrow-right-line font-16 font-weight-light"></v-icon>
                </v-btn>
            </div>
        </v-col>
    </v-row>

    <v-row class="z-0 py-md-5">
        <v-col cols="12" md="12">
            <v-img :src="require('@/assets/images/free-sample.png')" alt="Free Sample" class="fill-height"></v-img>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "FreeSample",
    components: {},
    mounted() {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped></style>

<template>
  <v-app>
    <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
      <v-overlay :model-value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <v-card elevation="3" rounded="lg">
              <div class="py-6 text-center">
                <v-btn :to="{ name: 'Home' }" variant="text" class="elevation-0">
                  <v-img :src="logoSrc" max-width="100%" height="auto" width="200"></v-img>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="pt-4 px-4">
                <div class="text-body-2 text-primary font-weight-semibold text-uppercase">Welcome Back</div>
                <v-form @submit.prevent="login">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="py-0 text-left">
                        <v-label class="opacity-100 font-weight-medium mb-1 font-14">Email</v-label>
                        <v-text-field 
                          v-model="user.email" 
                          placeholder="john@example.com" 
                          variant="outlined" density="compact" required
                          :error-messages="errors.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0 text-left">
                        <v-label class="opacity-100 font-weight-medium mb-1 font-14">Password</v-label>
                        <v-text-field 
                          v-model="user.password" 
                          type="password" 
                          placeholder="Password" 
                          variant="outlined" density="compact" required
                          :error-messages="errors.password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-checkbox v-model="rememberMe" class="mt-1" hide-details label="Remember me"></v-checkbox>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-btn 
                          class="font-weight-semibold text-none rounded-pill py-md-5" 
                          color="primary" block
                          type="submit"
                        >Login</v-btn>
                        <div>
                          <v-btn variant="text" class="text-none my-2 font-weight-semibold text-body-1 py-0 elevation-0 rounded-pill"
                            :to="{ name: 'ForgotPassword' }">Forgot password?</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
              <v-divider></v-divider>
              <div class="pa-4 text-body-1 font-weight-medium">
                <span> Don't have an account?</span> 
                <v-btn 
                  variant="text" 
                  height="auto"
                  compact="comfortable"
                  class="text-primary px-0 min-width-0 elevation-0  text-decoration-underline"
                  :to="{ name: 'SignUpPage' }"
                >
                   Sign up
                </v-btn>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  name: "LoginPage",
  setup() {
    const user = reactive({
      email: "",
      password: "",
    });

    const errors = reactive({
      email: "",
      password: "",
    });

    const loading = ref(false);
    const rememberMe = ref(false);
    const logoSrc = new URL('@/assets/images/swagbox-logo.png', import.meta.url).href;

    const login = async () => {
      errors.email = user.email ? "" : "Email is required";
      errors.password = user.password ? "" : "Password is required";

      if (!user.email || !user.password) return;

      loading.value = true;

      try {
        // Simulate API call
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log("User logged in:", user);
        loading.value = false;

        // Redirect after login
        window.location.href = "/dashboard";
      } catch (error) {
        loading.value = false;
        alert("Login failed. Please try again.");
      }
    };

    return {
      user,
      errors,
      loading,
      rememberMe,
      logoSrc,
      login,
    };
  },
};
</script>

<style scoped>
</style>

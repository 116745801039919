<template>
    <v-app>
        <v-container fluid class="bg-gradient-4">
            <!-- Sign in component  -->
             <div class="mt-2">
                <SignIn />
            </div>

            <v-row class="mx-md-auto mt-0">
                <v-col cols="12" sm="12" md="8" lg="7" class="pt-0 ps-md-0">
                    <!-- ContactSignup -->
                    <ContactSignup />

                    <!-- How would you like your products to be shipped?    -->
                    <div class="bg-light1 mb-3 mb-md-6 pb-3 pt-sm-8 pt-5 px-4 rounded-16" elevation="0">
                        <div class="position-relative pb-sm-4 pb-md-3 mb-4 mt-md-0">
                            <div class="title-border d-none d-md-block"></div>
                            <div class="position-absolute mobile-relative top-0 bottom-0 bg-light1 mt-md-n4 pr-md-3">
                                <div
                                    class="text-primary font-weight-semibold ls-75 font-18 d-md-flex text-left align-center">
                                    How would you like your products to be shipped?
                                </div>
                            </div>
                        </div>
                        <!-- Tabs -->
                        <div class="align-center ship-tabs d-sm-flex ga-4 my-3 mt-sm-8 mt-md-0">
                            <v-tabs v-model="selectedTab" grow>
                                <v-tab value="single">
                                    <v-icon class="mr-2 font-32"><i
                                            class="ri-truck-line font-weight-light"></i></v-icon>
                                    <span class="font-13 font-weight-medium">Ship to one address</span>
                                </v-tab>
                            </v-tabs>

                            <div cols="2" class="text-center rounded-8 font-weight-medium my-2 my-md-0 font-13">OR</div>

                            <v-tabs v-model="selectedTab" grow>
                                <v-tab value="multiple">
                                    <v-img :src="require('@/assets/images/svg/location.svg')" alt="Multiple Addresses"
                                        class="mr-2" width="32" height="32" />
                                    <span class="font-13 font-weight-medium">Ship to multiple addresses</span>
                                </v-tab>
                            </v-tabs>
                        </div>

                        <!-- Tab Content -->
                        <v-window v-model="selectedTab">
                            <v-window-item value="single">
                                <v-form class="ship-form">
                                    <v-radio-group v-model="selectedCategory" inline hide-details>  
                                            <v-radio value="Personal" :ripple="false" hide-details class="default-radio me-6 me-md-10">
                                                <template v-slot:label>
                                                    <span class="font-weight-normal">Personal</span>
                                                </template>
                                            </v-radio>
                                            <v-radio value="Business/Non Profits/Schools/Church" :ripple="false" hide-details class="default-radio">
                                                <template v-slot:label>
                                                    <span class="font-weight-normal">Business/Non Profits/Schools/Church</span>
                                                </template>
                                            </v-radio>
                                    </v-radio-group>
 
                                    <v-row class="mt-0">
                                        <v-col cols="12" sm="6" class="pe-2 pb-2">
                                            <v-text-field v-model="firstName" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">First Name <span
                                                            class="text-error">*</span></span>
                                                </template>
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6" class="ps-2 pb-2">
                                            <v-text-field v-model="lastName" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">Last Name <span
                                                            class="text-error">*</span></span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-2">
                                        <v-col cols="12" class="ps-2 pb-2">
                                            <v-text-field v-model="address1" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">Address 1 <span
                                                            class="text-error">*</span></span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="ps-2 pb-2">
                                            <v-text-field v-model="address2" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">Address 2</span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-2">
                                        <v-col cols="12" sm="6" class="ps-2 pb-2">
                                            <v-select v-model="country" :items="countries" variant="solo"
                                                density="comfortable" class="elevation-0" hide-details menu-icon="">
                                                <template v-slot:label>
                                                    <span class="text-light7">Country <span
                                                            class="text-error">*</span></span>
                                                </template>
                                                <template v-slot:append-inner>
                                                    <v-icon class="text-light10 opacity-100"><i
                                                            class="ri-arrow-down-s-line"></i></v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="ps-2 pb-2">
                                            <v-select v-model="state" :items="states" variant="solo"
                                                density="comfortable" class="elevation-0" hide-details menu-icon="">
                                                <template v-slot:label>
                                                    <span class="text-light7">State <span
                                                            class="text-error">*</span></span>
                                                </template>
                                                <template v-slot:append-inner>
                                                    <v-icon class="text-light10 opacity-100"><i
                                                            class="ri-arrow-down-s-line"></i></v-icon>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-2">
                                        <v-col cols="12" sm="6" class="ps-2 pb-2">
                                            <v-text-field v-model="city" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">City <span
                                                            class="text-error">*</span></span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="ps-2 pb-2">
                                            <v-text-field v-model="zipCode" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">Zip Code <span
                                                            class="text-error">*</span></span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-2">
                                        <v-col cols="12" sm="6" class="ps-2">
                                            <v-text-field v-model="phone" prefix="+1" variant="solo"
                                                density="comfortable" class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">Phone No.</span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="ps-2 pt-1 pt-sm-3">
                                            <v-text-field v-model="companyName" variant="solo" density="comfortable"
                                                class="elevation-0" hide-details>
                                                <template v-slot:label>
                                                    <span class="text-light7">Company Name</span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!-- Same Billing Address Checkbox -->
                                    <v-checkbox v-model="sameBillingAddress" hide-details class="opacity-100 my-2"
                                        density="compact">
                                        <template v-slot:label>
                                            <span class="text-primary font-weight-medium font-13">Same billing
                                                address</span>
                                        </template>
                                    </v-checkbox>

                                    <v-row class="align-center">
                                        <v-col cols="12">
                                            <div class="text-primary font-13 font-weight-medium text-left ms-1 mb-1">
                                                Blind Shipping</div>
                                            <v-radio-group v-model="blindShipping" inline hide-details>
                                                <v-radio value="No" :ripple="false" hide-details class="default-radio">
                                                    <template v-slot:label>
                                                        <span class="font-weight-normal">No</span>
                                                    </template>
                                                </v-radio>

                                                <v-radio value="yes" :ripple="false" hide-details class="default-radio">
                                                    <template v-slot:label>
                                                        <span class="font-weight-normal">
                                                            Yes <span class="font-weight-semibold">(+ $10.00)</span>
                                                        </span>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-form>


                            </v-window-item>
                            <v-window-item value="multiple">
                                <v-form class="ship-form">
                                    <v-radio-group v-model="selectedCategory" inline hide-details>  
                                            <v-radio value="Personal" :ripple="false" hide-details class="default-radio me-6 me-md-10">
                                                <template v-slot:label>
                                                    <span class="font-weight-normal">Personal</span>
                                                </template>
                                            </v-radio>
                                            <v-radio value="Business/Non Profits/Schools/Church" :ripple="false" hide-details class="default-radio">
                                                <template v-slot:label>
                                                    <span class="font-weight-normal">Business/Non Profits/Schools/Church</span>
                                                </template>
                                            </v-radio>
                                    </v-radio-group>

                                    <!-- Same Billing Address Checkbox -->
                                    <v-checkbox v-model="sameBillingAddress" hide-details class="opacity-100 my-2"
                                        density="compact">
                                        <template v-slot:label>
                                            <span class="text-light10 font-weight-medium font-13 font-weight-medium">
                                                Complete Order and upload address later
                                            </span>
                                        </template>
                                    </v-checkbox>

                                    <div class="rounded-lg px-4 py-4 bg-white-light border-grey mb-3">
                                        <!-- Download Section -->
                                        <v-row justify="center">
                                            <v-col cols="12">
                                                <div class="text-left text-light7 font-weight-medium font-13 mb-3">
                                                    Download guidelines here to make sure your CSVs are formatted
                                                    correctly.
                                                </div>
                                                <v-btn variant="outlined" class="download-btn green-label w-100"
                                                    @click="downloadCSV" min-height="44">
                                                    <i class="ri-download-2-line font-weight-light font-16 me-2"></i>
                                                    <span class="font-weight-semibold font-14 ">Download Example CSV
                                                        Template</span>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                        <!-- Upload Section -->
                                        <v-row justify="center mt-0 csv-upload">
                                            <v-col cols="12">
                                                <div class="text-left text-light7 font-weight-medium font-13 mb-3">
                                                    Upload CSV Template</div>
                                                <v-btn
                                                    class="font-13 text-light10 bg-light5 font-weight-medium ls-25 position-relative w-100 h-70"
                                                    variant="plain">
                                                    <v-img :src="require('@/assets/images/svg/clipart.svg')"
                                                        alt="clipart Icon" class="clipart-icon me-2" />
                                                    Upload here
                                                    <v-file-input
                                                        class="position-absolute top-0 font-weight-semibold start-0 w-100 h-100 opacity-0"
                                                        hide-details prepend-icon="" />
                                                </v-btn>

                                            </v-col>
                                        </v-row>
                                    </div>

                                    <!-- Blind Shipping -->
                                    <v-row class="align-center">
                                        <v-col cols="12">
                                            <div class="text-primary font-13 font-weight-medium text-left ms-1">Blind
                                                Shipping</div>
                                            <v-radio-group v-model="blindShipping" inline hide-details>
                                                <v-radio label="No" value="no" :ripple="false" hide-details
                                                    class="me-4 me-md-10"></v-radio>
                                                <v-radio label="Yes (+ $10.00)" value="yes" :ripple="false"
                                                    hide-details></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>

                                </v-form>

                            </v-window-item>
                        </v-window>
                    </div>

                    <!-- Swag Distribution -->
                    <div class="bg-light1 mb-3 pb-3 pt-sm-8 pt-5 px-4 rounded-16" elevation="0">
                        <div class="position-relative pb-md-5 pb-4">
                            <div class="title-border d-none d-md-block"></div>
                            <div class="position-absolute mobile-relative top-0 bottom-0 bg-light1 mt-md-n4 pr-md-3">
                                <div
                                    class="text-primary font-weight-semibold ls-75 font-18 d-md-flex text-left align-center">
                                    Swag Distribution
                                </div>
                            </div>
                        </div>

                        <!-- Digital proof request   -->
                        <div class="mb-3 mt-md-2 mt-sm-8">
                            <v-select variant="plain" hide-details
                                :items="['Distribute right away (send all of my swag to recipients as soon as it is ready)', 'No']"
                                model-value="Yes (+$10)"
                                class="rounded-4 bg-white-light px-5 py-1 opacity-100 digital-proof font-weight-semibold"
                                :menu-icon="'mdi-chevron-down'">
                                <template v-slot:label>
                                    <span class="d-flex align-center font-weight-medium text-light7">
                                        What’s you plan for distributing your swagbox? *
                                    </span>
                                </template>
                            </v-select>
                        </div>
                        <!-- Shipping date -->
                        <div class="rounded-lg px-4 py-4 bg-white-light border-grey">
                            <v-row class="mb-3 align-center">
                                <v-col cols="12" sm="6" class="text-left pb-0">
                                    <div class="text-primary font-13 font-weight-medium">Shipping date</div>
                                </v-col>
                                <v-col cols="12" sm="6" class="pb-0 pt-0 pt-sm-3">
                                    <!-- Standard Checkout -->
                                    <div class="d-flex justify-sm-end align-center">
                                        <label for="standard-switch"
                                            class="font-weight-semibold text-bluish5 font-13 d-flex align-center cursor-pointer">
                                            Standard
                                        </label>
                                        <v-switch id="standard-switch" v-model="standardCheckout" inset hide-details
                                            density="compact" class="ml-4" max-height="27" color="primary"
                                            :class="{ 'opacity-100': standardCheckout, 'opacity-50': !standardCheckout }">
                                        </v-switch>
                                        <div class="text-pink2 font-13 font-weight-medium ms-3 d-flex align-center">
                                            Rush Shipping
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>


                            <!-- Which date do you want to ship the order? *   -->
                            <v-select variant="plain" hide-details :items="['6/1/24', '7/1/24']" model-value="5/1/24"
                                class="rounded-4 bg-light4 px-5 py-1 opacity-100 digital-proof" menu-icon="">
                                <template v-slot:label>
                                    <span class="d-flex align-center text-light7  ">
                                        Which date do you want to ship the order? *
                                    </span>
                                </template>

                                <template v-slot:append-inner>
                                    <v-icon class="text-dark2"> <i class="ri-calendar-event-fill font-weight-light"></i>
                                    </v-icon>
                                </template>
                            </v-select>

                            <v-radio-group v-model="selectedDelivery" hide-details class="w-100">
                                <v-container
                                    class="border2-dash rounded align-center justify-space-between py-2 px-3 mb-1 mt-4">
                                    <v-row class="align-center py-3">
                                        <v-col col="6" sm="6" class="text-left py-0">
                                            <div class="d-flex align-center flex-grow-1 mb-1">
                                                <v-radio value="standard" density="compact" hide-details
                                                    class="me-1 pa-0 ma-0 delivery-button"></v-radio>
                                                <label class="font-weight-normal font-13 text-light10 cursor-pointer "
                                                    style="min-width: 150px;" @click="selectedDelivery = 'standard'">
                                                    Delivery by:
                                                    <span class="text-primary font-weight-medium">May 1, Wednesday,
                                                        2024</span>
                                                </label>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="4" class="py-0">
                                            <div
                                                class="font-weight-normal font-13 text-light10 text-start text-sm-center">
                                                Shipping Cost: <span
                                                    class="text-primary font-weight-medium">$0.00</span>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="2" class="py-0">
                                            <v-btn variant="text" max-height="22"
                                                class="font-weight-medium font-12 text-uppercase green-label px-1 py-0"
                                                height="32">
                                                GAURANTEED
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-radio-group>
                            <v-radio-group v-model="selectedDelivery" hide-details class="w-100">
                                <v-container
                                    class="border2-dash rounded align-center justify-space-between py-2 px-3 mb-1 mt-4">
                                    <v-row class="align-center py-3">
                                        <v-col col="6" sm="6" class="text-left py-0">
                                            <div class="d-flex align-center flex-grow-1 mb-1">
                                                <v-radio value="standard" density="compact" hide-details
                                                    class="me-1 pa-0 ma-0 delivery-button"></v-radio>
                                                <label class="font-weight-normal font-13 text-light10 cursor-pointer "
                                                    style="min-width: 150px;" @click="selectedDelivery = 'standard'">
                                                    Delivery by:
                                                    <span class="text-primary font-weight-medium">May 1, Wednesday,
                                                        2024</span>
                                                </label>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="4" class="py-0">
                                            <div
                                                class="font-weight-normal font-13 text-light10 text-start text-sm-center">
                                                Shipping Cost: <span
                                                    class="text-primary font-weight-medium">$0.00</span>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="2" class="py-0">
                                            <v-btn variant="text" max-height="22"
                                                class="font-weight-medium bg-yellow3 font-12 text-uppercase text-yellow2 border-yellow2 px-1 py-0"
                                                height="32">
                                                ESTIMATED
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-radio-group>
                            <v-radio-group v-model="selectedDelivery" hide-details class="w-100">
                                <v-container
                                    class="border2-dash rounded align-center justify-space-between py-2 px-3 mb-1 mt-4">
                                    <v-row class="align-center py-3">
                                        <v-col col="6" sm="6" class="text-left py-0">
                                            <div class="d-flex align-center flex-grow-1 mb-1">
                                                <v-radio value="standard" density="compact" hide-details
                                                    class="me-1 pa-0 ma-0 delivery-button"></v-radio>
                                                <label class="font-weight-normal font-13 text-light10 cursor-pointer "
                                                    style="min-width: 150px;" @click="selectedDelivery = 'standard'">
                                                    Delivery by:
                                                    <span class="text-primary font-weight-medium">May 1, Wednesday,
                                                        2024</span>
                                                </label>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="4" class="py-0">
                                            <div
                                                class="font-weight-normal font-13 text-light10 text-start text-sm-center">
                                                Shipping Cost: <span
                                                    class="text-primary font-weight-medium">$0.00</span>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="2" class="py-0">
                                            <v-btn variant="text" max-height="22"
                                                class="font-weight-medium bg-yellow3 font-12 text-uppercase text-yellow2 border-yellow2 px-1 py-0"
                                                height="32">
                                                ESTIMATED
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-radio-group>
                            <v-radio-group v-model="selectedDelivery" hide-details class="w-100">
                                <v-container
                                    class="border2-dash rounded align-center justify-space-between py-2 px-3 mb-1 mt-4">
                                    <v-row class="align-center py-3">
                                        <v-col col="6" sm="6" class="text-left py-0">
                                            <div class="d-flex align-center flex-grow-1 mb-1">
                                                <v-radio value="standard" density="compact" hide-details
                                                    class="me-1 pa-0 ma-0 delivery-button"></v-radio>
                                                <label class="font-weight-normal font-13 text-light10 cursor-pointer "
                                                    style="min-width: 150px;" @click="selectedDelivery = 'standard'">
                                                    Delivery by:
                                                    <span class="text-primary font-weight-medium">May 1, Wednesday,
                                                        2024</span>
                                                </label>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="4" class="py-0">
                                            <div
                                                class="font-weight-normal font-13 text-light10 text-start text-sm-center">
                                                Shipping Cost: <span
                                                    class="text-primary font-weight-medium">$0.00</span>
                                            </div>
                                        </v-col>
                                        <v-col col="6" sm="2" class="py-0">
                                            <v-btn variant="text" max-height="22"
                                                class="font-weight-medium bg-yellow3 font-12 text-uppercase text-yellow2 border-yellow2 px-1 py-0"
                                                height="32">
                                                ESTIMATED
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-radio-group>
                        </div>

                        <!-- Items in box -->
                        <v-table class="elevation-0 transparent border-grey rounded-lg mt-3 item-small-list"
                            density="compact">
                            <thead>
                                <tr class="font-weight-medium font-12 ls-25 text-light10">
                                    <th class="text-left py-3 py-md-0">Items in box</th>
                                    <th class="text-center py-3 py-md-0">Item size</th>
                                    <th class="text-center py-3 py-md-0">Qty</th>
                                    <th class="text-center py-3 py-md-0">Storage cost/box</th>
                                    <th class="text-center py-3 py-md-0">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="font-13 ls-25 lh-22 font-weight-medium text-primary">
                                    <td class="text-left py-md-5 py-2 ">
                                        <div class="d-flex min-width-170">
                                            <div class="border-grey thumbnail-img rounded-4 pa-1">
                                                <v-img
                                                    :src="('https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/bcuj-aqua-front.png')"
                                                    alt="Tshirt" width="35" height="35"></v-img>
                                            </div>
                                            <div class="ms-2 ms-md-3 text-left">
                                                <div>Egyptian Cotton Unisex Hoodie</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center py-md-5 py-2 min-width-170">Large Item (Backpacks, Hoodies,
                                        Jackets)</td>
                                    <td class="text-center py-md-5 py-2">150</td>
                                    <td class="text-center py-md-5 py-2">$5.72</td>
                                    <td class="text-center py-md-5 py-2">$858.00</td>
                                </tr>
                                <tr class="font-13 ls-25 font-weight-medium text-primary">
                                    <td class="text-left py-md-5 py-4">
                                        <div class="d-flex">
                                            <div class="border-grey thumbnail-img rounded-4 pa-1">
                                                <v-img
                                                    :src="('https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/bcuj-aqua-front.png')"
                                                    alt="Tshirt" width="35" height="35"></v-img>
                                            </div>
                                            <div class="ms-2 ms-md-3 text-left">
                                                <div class="text-black font-weight-medium font-13 lh-22 ls-75">Egyptian
                                                    Cotton Unisex Hoodie
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center py-md-5 py-4">Large Item (Backpacks, Hoodies, Jackets)</td>
                                    <td class="text-center py-md-5 py-4">150</td>
                                    <td class="text-center py-md-5 py-4">$5.72</td>
                                    <td class="text-center py-md-5 py-4">$858.00</td>
                                </tr>
                                <tr class="font-13 ls-25 font-weight-medium text-primary">
                                    <td class="text-left py-md-5 py-4">
                                        <div class="d-flex">
                                            <div class="border-grey thumbnail-img rounded-4 pa-1">
                                                <v-img
                                                    :src="('https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/bcuj-aqua-front.png')"
                                                    alt="Tshirt" width="35" height="35"></v-img>
                                            </div>
                                            <div class="ms-2 ms-md-3 text-left">
                                                <div class="text-black font-weight-medium font-13 lh-22 ls-75">Egyptian
                                                    Cotton Unisex Hoodie
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center py-md-5 py-4">Large Item (Backpacks, Hoodies, Jackets)</td>
                                    <td class="text-center py-md-5 py-4">150</td>
                                    <td class="text-center py-md-5 py-4">$5.72</td>
                                    <td class="text-center py-md-5 py-4">$858.00</td>
                                </tr>
                            </tbody>
                        </v-table>

                        <!-- Additional chargers -->
                        <div class="text-left font-weight-medium  font-13 text-light10 mt-3">Additional chargers are
                            based on quantity and
                            weight. Units that stay in our facility for more than 6 months will be re-charged every 6
                            months.
                            <v-btn variant="text"
                                class="text-decoration-none px-0 font-weight-medium text-decoration-underline font-13 text-bluish5"
                                height="auto" @click="yourFunction">
                                Know more
                            </v-btn>
                        </div>
                    </div>

                    <!-- Payment Methods -->
                    <div class="bg-light1 mb-3 pb-3 pt-md-8 pt-5 px-4 rounded-16" elevation="0">
                        <div class="position-relative pb-4 pb-md-5">
                            <div class="title-border d-none d-md-block"></div>
                            <div class="position-absolute mobile-relative top-0 bottom-0 bg-light1 mt-md-n4 pr-md-3">
                                <div
                                    class="text-primary font-weight-semibold ls-75 font-18 d-md-flex text-left align-center">
                                    Payment Methods
                                </div>
                            </div>
                        </div>
                        <div class="font-weight-medium font-13 ls-25 text-primary text-left mt-sm-8 mt-md-0">Express
                            Checkout</div>
                        <div class="button-inline">
                            <div class="d-sm-flex align-center ga-3 py-3">
                                <!-- Apple Pay Button -->
                                <div class="flex-grow-1">
                                    <v-btn block
                                        class="bg-black text-unset text-white w-100 font-weight-normal rounded-4 px-4 font-14"
                                        height="40" rounded>
                                        Pay with
                                        <v-icon class="ml-2">mdi-apple</v-icon> Pay
                                    </v-btn>
                                </div>

                                <!-- OR text -->
                                <div class="text-muted font-weight-bold ">
                                    OR
                                </div>

                                <!-- PayPal Button -->
                                <div class="flex-grow-1">
                                    <v-btn block
                                        class=" text-unset text-bluish7 bg-yellow2 w-100 font-weight-normal rounded-4 mr-3 px-4 font-14"
                                        height="40" rounded>
                                        Buy with
                                        <v-icon class="ml-9">
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                                                height="20" />
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div>

                        </div>
                        <div class="font-weight-medium font-13 ls-25 text-primary text-left">Saved Cards</div>

                        <!-- Login to see saved cards -->
                        <div class="text-left font-weight-medium font-13 text-light10 mt-3">
                            <v-btn variant="text" density="comfortable"
                                class="text-decoration-none me-1 px-0 font-weight-medium text-decoration-underline font-13 text-bluish6 d-inline"
                                min-width="unset" height="auto" @click="yourFunction">
                                Login
                            </v-btn>
                            <span class="text-primary">to see saved cards</span>
                        </div>

                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="5">
                    <!-- Order summery -->
                    <div class="position-sticky top-80">
                        <v-row class="d-flex flex-column border-grey rounded-lg px-2 py-2 bg-white-light">
                            <v-col class="d-flex justify-space-between align-center pa-2">
                                <div class="text-left text-light7 font-weight-medium font-13 ls-25">Order Subtotal</div>
                                <div class="text-right font-weight-normal font-15 ls-75 text-black">$522.00</div>
                            </v-col>
                            <v-col class="d-flex justify-space-between align-center pa-2">
                                <div class="text-left text-light7 font-weight-medium font-13 ls-25">Production /
                                    Shipping</div>
                                <div class="text-right font-weight-normal font-15 ls-75 text-black">$522.00</div>
                            </v-col>
                            <v-col class="d-flex justify-space-between align-center pa-2">
                                <div class="text-left text-light7 font-weight-medium font-13 ls-25">Discount Code</div>
                                <div class="text-right font-weight-normal font-15 ls-75 text-black">$522.00</div>
                            </v-col>

                            <v-col class="d-flex justify-space-between align-center pa-2">
                                <div class="text-left text-light7 font-weight-medium font-13 ls-25">Taxes</div>
                                <div class="text-right font-weight-normal font-15 ls-75 text-black">$522.00</div>
                            </v-col>

                            <v-col class="d-flex justify-end align-center pa-2">
                                <div class="text-right text-light7 font-weight-medium font-13 ls-25">Total</div>
                            </v-col>

                            <v-col class="d-flex justify-end align-center pa-2">
                                <div class="font-weight-bold total-price text-dark2 ls-75 font-20">$522.00</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-0 pt-4 mb-5 mb-md-0">
                                <v-btn :ripple="false"
                                    class="px-5 font-weight-bold bg-dark2 text-white w-100 font-14 ls-25"
                                    min-height="40" rounded @click="proceedToPayment">
                                    Complete Order
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import ContactSignup from "./components/ContactSignup.vue";
import SignIn from "@/views/CartEmpty/components/SignIn.vue";
import clipartIcon from '@/assets/images/svg/clipart.svg';
export default {
    name: "PaymentOptions",
    components: {
        ContactSignup,
        SignIn,
    },
    data() {
        return {
            csvFile: null,
            selectedTab: "multiple",
            selectedCategory: "",
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            country: "",
            state: "",
            city: "",
            zipCode: "",
            phone: "",
            companyName: "",
            sameBillingAddress: false,
            blindShipping: "no",
            countries: ["United States", "Canada", "UK"],
            states: ["Alabama", "Alaska", "California"],
        };
    },
    methods: {
        downloadCSV() {
            const link = document.createElement("a");
            link.href = "path/to/example.csv"; // Update with actual CSV path
            link.download = "Example_CSV_Template.csv";
            link.click();
        },
    },

};
</script>

<template>
<v-app>
    <v-container fluid class="bg-white py-md-15 py-10">
        <v-row class="py-md-15">
            <v-col cols="12" sm="6" md="6">
                <h2 class="mb-5 mb-md-6 text-left text-dark3 ls-75">
                    Create Your Swag Shop
                </h2>
                <v-col cols="12" sm="12" md="12" class="text-left">
                    <v-card class="swag-card mb-3" elevation="0" bg-color="transparent">
                        <v-img class="mb-3" :src="require('@/assets/images/svg/watch.svg')" alt="Swag Management" width="32"></v-img>
                        <h6 class="d-inline font-16 ls-75 bg-light3 font-weight-medium py-1">
                            Up and Running in Minutes
                        </h6>
                        <p class="text-black font-14 ls-75 pt-3 font-weight-regular">
                            Get your store operational in mere minutes with our streamlined
                            setup process.
                        </p>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-left">
                    <v-card class="swag-card mb-3" elevation="0" bg-color="transparent">
                        <v-img class="mb-3" :src="require('@/assets/images/svg/design2.svg')" alt="Swag Management" width="32"></v-img>
                        <h6 class="d-inline font-16 ls-75 bg-s-light-10 font-weight-medium py-1">
                            Simple, Beautiful design
                        </h6>
                        <p class="text-black font-14 ls-75 pt-3 font-weight-regular">
                            Discover even more simplicity and beauty in our store's design
                        </p>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-left">
                    <v-card class="swag-card mb-3" elevation="0" bg-color="transparent">
                        <v-img class="mb-3" :src="require('@/assets/images/svg/cart.svg')" alt="Swag Management" width="32"></v-img>
                        <h6 class="d-inline font-16 ls-75 bg-light3 font-weight-medium bg-pink3 py-1">
                            Up and Running in Minutes
                        </h6>
                        <p class="text-black font-14 ls-75 pt-3 font-weight-regular">
                            Get your store operational in mere minutes with our streamlined
                            setup process.
                        </p>
                    </v-card>
                </v-col>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="ps-lg-15">
                <v-img :src="require('@/assets/images/swagshop.png')" alt="Swag Management"></v-img> 
                <v-btn
                variant="outlined"
                color="primary"
                bg-color="transparent"
                class="px-7 px-md-10 font-16 font-weight-bold btn-strong"
                rounded
                href="https://www.swagifypod.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                >
                Book Demo now
                <i class="ml-2 ri-arrow-right-line font-16 font-weight-light"></i>
                </v-btn>


            </v-col>
        </v-row>

        <v-row class="mt-3 my-md-15 justify-center py-5 cut-bg mx-0">
            <v-col cols="12" lg="10" class="py-md-12">
                <v-row class="justify-space-between align-center">
                    <v-col cols="12" sm="6" lg="6">
                        <v-img :src="require('@/assets/images/pc.png')" alt="Swag Management"></v-img>
                    </v-col>
                    <v-col cols="12" sm="6" lg="5" class="text-left">
                        <h2 class="mb-3 font-40 text-dark3 ls-75">
                            All Powered By<br />
                            Our Platform
                        </h2>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Real time
                            inventory tracking & management
                        </p>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Create
                            custom Swag Shops in seconds
                        </p>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Use Redeem
                            Pages to automate shipments
                        </p>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Send swag &
                            rewards around the globe
                        </p>
                        <v-btn
                        variant="outlined"
                        color="primary"
                        class="mt-8 px-6 text-unset font-16 font-weight-bold bg-transparent"
                        min-height="40"
                        rounded
                        href="https://www.swagifypod.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Book a demo
                        <i class="ml-2 ri-arrow-right-line font-16 font-weight-light"></i>
                        </v-btn> 
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</v-app>
</template>

<script>
export default {
    name: "SwagShop",
};
</script>

<style scoped>
.v-btn {
    margin-top: -20px;
    background: #ffffff;
}
.btn-strong {
    padding-top: 16px!important;
    padding-bottom: 16px!important;
    height: auto !important;
    border-radius: 100px!important;
}
</style>

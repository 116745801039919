<template>
  <v-container fluid class="py-0 slide-bg">
    <v-row class="z-1 position-relative py-10">
      <v-col cols="12">
        <h2 class="mb-lg-15 mb-8 pb-lg-5 text-dark2 ls-75 ">Create, Automate, & Distribute Swag Globally</h2>
        <Splide :options="splideOptions" ref="splide" class="custom-slide">
          <SplideSlide 
            v-for="(slide, index) in slides" 
            :key="index"
            @click="goToSlide(index)"
            class="clickable-slide"
          >
            <div class="slide-content">
              <img :src="slide.image" :alt="slide.alt" class="img-fluid" loading="lazy" />
              <div class="text font-weight-bold font-32 mt-3 ls-75 text-dark3">{{ slide.text }}</div>
            </div>
          </SplideSlide>
        </Splide>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import '@splidejs/vue-splide/css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  name: 'DistributeSwag',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      slides: [
        { image: require('@/assets/images/home-slider1.png'), alt: 'Slide 1', text: 'Distribute' },
        { image: require('@/assets/images/home-slider2.png'), alt: 'Slide 2', text: 'Distribute' },
        { image: require('@/assets/images/home-slider3.png'), alt: 'Slide 3', text: 'Automate' },
        { image: require('@/assets/images/home-slider1.png'), alt: 'Slide 1', text: 'Distribute' },
        { image: require('@/assets/images/home-slider2.png'), alt: 'Slide 2', text: 'Distribute' },
        { image: require('@/assets/images/home-slider3.png'), alt: 'Slide 3', text: 'Automate' }
      ],
      splideOptions: {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        autoplay: true,
        interval: 3000,
        gap: '50px',
        pagination: false,
        arrows: false,
        focus: 'center',
        width: '100%',
        height:'500px',
        fixedHeight: '450px',
        fixedWidth: '600px',
        classes: {
          root: 'custom-track',        
        },
        breakpoints: {
          1300: { perPage: 3, gap: '0'},
          768: { perPage: 3, gap: '4', fixedWidth: '400px', fixedHeight: 'auto'},
          575: { perPage: 3, gap: '0', fixedWidth: '200px', fixedHeight: 'auto'}
        }
      },
      currentIndex: 0,
    };
  },
  methods: {
    goToSlide(index) {
      this.$refs.splide.splide.go(index);
    }
  },
};
</script>

<style scoped>
</style>
<template>
  <v-card class="sticky-top elevation-0 fixed">
    <v-container>
      <v-row>
        <v-col cols="12" class="pt-0">
          <div class="text-left font-weight-medium font-13 ls-25 text-black mb-3">Gift card, Store Credit or Discount Code</div>
          <v-text-field 
              v-model="discountCode"
              placeholder="Add Code Here"
              variant="text"
              class="bg-light4 rounded-4 font-weight-normal font-15 text-light7 mb-3"
              hide-details
               min-height="40"
            ></v-text-field>
            <div class="d-flex justify-end mt-2">
              <v-btn 
                v-if="discountCode" 
                variant="text" 
                class="text-error font-weight-semibold font-13 ls-25" 
                @click="removeCode"
              >
                Remove Code
              </v-btn>
              <v-btn 
                variant="outlined" 
                class="text-black font-weight-semibold font-13 ls-25 px-7 ms-2" 
                @click="applyCode"
                min-height="40"
              >
                Apply Code
              </v-btn>
            </div> 
        </v-col>
      </v-row>

      <v-divider class="my-4 "></v-divider>

      <v-row class="d-flex flex-column">
        <v-col class="d-flex justify-space-between align-center pa-2">
          <div class="text-left text-light7 font-weight-medium font-13 ls-25">Order Subtotal</div>
          <div class="text-right font-weight-normal font-15 ls-75 text-black">{{ subTotal }}</div>
        </v-col>
        <v-col class="d-flex justify-space-between align-center pa-2">
          <div class="text-left text-light7 font-weight-medium font-13 ls-25">Discount Code</div>
          <div class="text-right font-weight-normal font-15 ls-75 text-black">{{ discountAmount }}</div>
        </v-col>
        <v-col class="d-flex justify-space-between align-center pa-2">
          <div class="text-left text-light7 font-weight-medium font-13 ls-25">Production / Shipping</div>
          <div class="text-right font-weight-normal font-15 ls-75 text-black">{{ shippingCost }}</div>
        </v-col>

        <v-col class="d-flex justify-space-between align-center pa-2">
          <div class="text-left text-light7 font-weight-medium font-13 ls-25">Additional Charges</div>
          <div class="text-right font-weight-normal font-15 ls-75 text-black">{{ additionalCharges }}</div>
        </v-col>

        <v-col class="d-flex justify-end align-center pa-2">
          <div class="text-right text-light7 font-weight-medium font-13 ls-25">Total</div>
        </v-col>

        <v-col class="d-flex justify-end align-center pa-2">
          <div class="font-weight-bold total-price text-gradient-3 ls-75 font-20">${{ finalTotal }}</div>
        </v-col>
      </v-row>

  
      <v-row>
        <v-col cols="12" class="pa-0 mt-md-2">  
          <v-btn :ripple="false" class="px-5 font-weight-bold bg-dark2 text-white w-100 font-14 ls-25" min-height="40" rounded @click="proceedToPayment">
            Proceed To Checkout
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: 'OrderSummary',
  setup() {
    const discountCode = ref('');
    const discountAmount = ref('$0.00');
    const shippingCost = ref('$10.00');
    const additionalCharges = ref('$5.00');
    const subTotal = ref('$500.00');

    const finalTotal = computed(() => {
      let subtotal = parseFloat(subTotal.value.replace('$', ''));
      let discount = parseFloat(discountAmount.value.replace('$', ''));
      let shipping = parseFloat(shippingCost.value.replace('$', ''));
      let additional = parseFloat(additionalCharges.value.replace('$', ''));
      return (subtotal - discount + shipping + additional).toFixed(2);
    });

    const applyCode = () => {
      if (discountCode.value.trim() === 'DISCOUNT10') {
        discountAmount.value = '$10.00';
      } else {
        discountAmount.value = '$0.00';
      }
    };

    const removeCode = () => {
      discountCode.value = '';
      discountAmount.value = '$0.00';
    };

    const proceedToPayment = () => {
      console.log('Proceeding to payment...');
    };

    return {
      discountCode,
      discountAmount,
      shippingCost,
      additionalCharges,
      subTotal,
      finalTotal,
      applyCode,
      removeCode,
      proceedToPayment,
    };
  },
};
</script>

<style scoped>

</style>

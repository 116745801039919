<template>
<v-container fluid class="dotted-bg py-10 py-md-12">
    <v-row class="align-center px-md-4 justify-center">
        <v-col cols="12" lg="8">
            <v-row class="text-start px-md-4 justify-space-between justify-lg-space-around align-center">
                <v-col cols="12" sm="6" md="6" class="pe-md-6">
                    <v-img :src="require('@/assets/images/connect.png')" alt="connect" class="fill-height"></v-img>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <div>
                        <h2 class="mb-md-4 ls-75 text-light3">
                            Connect To All Of Your Favorite Apps With Ease
                        </h2>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "FavoriteApps",
};
</script>

<style scoped></style>

<template>
<v-app>
    <!-- Hero section-->
    <HeroSection />
    
    <!-- Distribute swaG-->
    <DistributeSwag />

    <!-- A swag management-->
    <SimplifyingSwag />

    <!-- High-quality swag for every occasion -->
    <HighQualitySwag />

    <!-- A swag management platform that makes Sourcing, Storing, and Shipping swag easy. -->
    <SwagManagement />

    <!-- There are a lot more reasons to build your swagbox with us -->
    <BuildYourSwagbox />

    <!-- Brands we worked with -->
    <BrandWork />

    <!-- Get A Free Sample Pack Of The Swag Experience -->
    <FreeSample />

    <!-- Connect To All Of Your Favorite Apps With Ease -->
    <FavoriteApps />

    <!-- Create Your Swag Shop -->
    <SwagShop />

    <!-- Start sending incredible swag and gifts -->
    <SwagGift />

    <!-- Frequently Asked Questions -->
    <FaqsSection />

</v-app>
</template>

<script>
import HeroSection from "./components/HeroSection"
import DistributeSwag from "./components/DistributeSwag"
import SimplifyingSwag from "./components/SimplifyingSwag"
import HighQualitySwag from "./components/HighQualitySwag"
import SwagManagement from "./components/SwagManagement"
import BuildYourSwagbox from "./components/BuildYourSwagbox"
import BrandWork from "./components/BrandWork"
import FreeSample from "./components/FreeSample"
import FavoriteApps from "./components/FavoriteApps"
import SwagShop from "./components/SwagShop"
import SwagGift from "./components/SwagGift"
import FaqsSection from "./components/FaqsSection"


export default {
    name: 'Home-Page',
    components: {
        HeroSection,
        DistributeSwag,
        SimplifyingSwag,
        HighQualitySwag,
        SwagManagement,
        BuildYourSwagbox,
        BrandWork,
        FreeSample,
        FavoriteApps,
        SwagShop,
        SwagGift,
        FaqsSection
    },
    mounted() {},
    computed: {},
    data() {
        return {

        }
    },
    methods: {}
}
</script>

<style scoped>

</style>

<template>
    <v-card class="rounded-16">
        <v-card-text class="px-0 py-0 rounded-16">
            <v-container class="rounded-16">
                <!-- <div class="d-flex justify-end mb-2">
                <v-btn @click="$emit('close-modal')" 
                    variant="text" 
                    color="primary" 
                    class="rounded-circle pa-0 font-20 font-weight-light"
                    width="24"
                    height="24"
                    min-width="24">
                    <i class="ri-close-circle-line"></i>
                </v-btn>
            </div> -->

                <v-row>
                    <!-- Image Slider -->
                    <v-col cols="12" md="5" lg="4" class="pe-md-2">
                        <div class="bg-white card-shadow px-4 py-4 rounded-16 mb-5">
                            <v-window v-model="onboarding" class="elevation-0 rounded-16" show-arrows>
                                <template v-slot:prev="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" class="pa-2 elevation-0 rounded-circle">
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:next="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" class="pa-2 elevation-0 rounded-circle">
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <v-window-item v-for="(image, index) in images" :key="index">
                                    <v-card class="rounded-16">
                                        <div class="slider-height rounded-16">
                                            <NwImg :src="image" alt="Product Image" class="rounded-16" />
                                        </div>
                                    </v-card>
                                </v-window-item>
                            </v-window>
                            <div class="d-flex justify-space-between align-center mt-3">
                                <v-btn text @click="prev" variant="plain"
                                    class="pa-0 me-3 border-grey text-light6 slider-prev rounded-circle">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-item-group v-model="onboarding"
                                    class="text-center ga-3 d-flex flex-row overflow-x-auto overflow-y-hidden thumbnail-slider py-2"
                                    active-class="active">
                                    <v-item v-for="(image, index) in thumbImages" :key="index"
                                        v-slot="{ active, toggle }">
                                        <v-btn :input-value="active" @click="toggle" text class="pa-0 mx-1" height="58"
                                            width="58">
                                            <v-img :src="image" contain class="rounded-16" />
                                        </v-btn>
                                    </v-item>
                                </v-item-group>
                                <v-btn text @click="next" variant="plain"
                                    class="pa-0 ms-3 border-grey text-light6 slider-prev rounded-circle">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="mb-5">
                            <v-subheader class="font-14 ls-75 font-weight-semibold text-primary">See it in real
                                Life</v-subheader>
                            <div class="d-flex justify-content-left pt-3 ga-2">
                                <div>
                                    <v-img :src="require('@/assets/images/shirt-girl.png')"
                                        class="rounded-lg fill-height" height="56" width="56" contain />
                                </div>
                                <div>
                                    <v-img :src="require('@/assets/images/shirt-man.png')"
                                        class="rounded-lg fill-height" height="56" width="56" contain />
                                </div>
                            </div>
                        </div>
                        <div v-if="product.description">
                            <v-subheader
                                class="font-14 ls-75 font-weight-semibold text-primary">Description</v-subheader>
                            <p class="font-13 ls-25 font-weight-medium text-light7 pt-3">Why We Love It: Vibrant colors
                                and a premium cotton construction elevate the classic tee! Next Level’s Unisex Cotton
                                tee is available in 20+ colors to <span> Read More</span></p>
                        </div>
                        <div v-if="product.care_instructions">
                            <v-subheader class="font-14 ls-75 font-weight-semibold text-primary">Care
                                Instructions</v-subheader>
                            <p class="ont-13 ls-25 font-weight-medium text-light7">{{ product.care_instructions }}</p>
                        </div>
                    </v-col>

                    <!-- Product Details -->
                    <v-col cols="12" md="7" lg="8" class="ps-md-2 d-flex flex-column">
                        <div class="bg-white card-shadow h-100 py-4 rounded-16 d-flex flex-column">
                            <div class="flex-grow-1 px-md-6 px-3">
                                <h3 class="font-weight-semibold font-24 ls-75 mb-md-5 mb-3">{{ product.title }}</h3>

                                <!-- Quantity per/box  -->
                                <div class="d-flex flex-wrap mb-2 mb-md-6 qty-p-box">
                                    <div>
                                        <v-subheader
                                            class="px-0 font-weight-semibold font-15 ls-75 text-light10 mb-2 d-block">Qty
                                            per box</v-subheader>
                                        <div class="bg-light4 py-2 rounded-4 border d-flex align-center justify-center"
                                            style="height: 40px;">
                                            <span @click="decrement"
                                                class="px-3 cursor-pointer d-flex align-center text-light7 font-24">−</span>
                                            <v-text-field v-model="product.quantity" type="number" variant="plain" dense
                                                hide-details
                                                class="text-primary font-weight-semibold font-20 val-input text-center h-100"
                                                max-width="32" />
                                            <span @click="increment"
                                                class="px-3 cursor-pointer d-flex align-center text-light7 font-24">+</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Choose color  -->
                                <div class="bg-light4 px-3 py-3 rounded-4 border py-md-4 mb-2 mb-md-6">
                                    <v-subheader class="px-0 font-weight-semibold font-15 ls-75 text-primary">Choose
                                        color
                                    </v-subheader>
                                    <div class="d-flex justify-start flex-wrap ga-2 justify-start pt-3">
                                        <!-- radio list -->
                                        <v-radio-group v-model="product.selected_color"
                                            v-for="(item, colorIndex) in product.colors" :key="colorIndex"
                                            class="product-colors">
                                            <v-radio :value='item' class="d-inline-block d-none" hide-details
                                                off-icon="mdi-none" @change="updateCart(product, item)">
                                                <template v-slot:label>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <div class="colors-list" v-on="on"
                                                                v-bind:style="{ 'background-color': item.value }">
                                                            </div>
                                                        </template>
                                                        <span class="text-capitalize">{{
                                                            $helper.functions.getColorName(item)
                                                            }}</span>
                                                    </v-tooltip>
                                                    <!-- </div> -->
                                                </template>
                                            </v-radio>
                                        </v-radio-group>

                                        <v-btn variant="text" height="auto"
                                            class="text-bluish5 font-12 font-weight-medium px-0 py-0">Collapse</v-btn>

                                    </div>
                                </div>

                                <!-- Upload design button group  -->
                                <div class="bg-light4 px-3 py-3 rounded-4 border py-md-4 mb-2 mb-md-6">
                                    <div class="design-tabs mb-3 mb-md-4">
                                        <v-radio-group v-model="selectedOption" hide-details>
                                            <v-radio class="flex-grow-1 flex-md-basis-25" value="upload3">
                                                <template v-slot:label>
                                                    <div
                                                        class="d-flex align-baseline justify-center ls-25 font-13 py-4 rounded-4 text-center w-100">
                                                        <i class="ri-upload-2-line fw-lighter ri-xl font-16 me-2"></i>
                                                        <span class="font-weight-semibold">Upload Design</span>
                                                    </div>
                                                </template>
                                            </v-radio>

                                            <v-radio class="flex-grow-1 flex-md-basis-25" value="design_lab3">
                                                <template v-slot:label>
                                                    <div
                                                        class="d-flex align-center justify-center ls-25 font-13 py-4 rounded-4 text-center w-100">
                                                        <v-img :src="require('@/assets/images/svg/pixflo-icon.svg')"
                                                            height="16" width="16" max-width="16" class="me-2" />
                                                        <span class="font-weight-semibold">Design Lab</span>
                                                    </div>
                                                </template>
                                            </v-radio>

                                            <v-radio class="flex-grow-1 flex-md-basis-25" value="design_later3">
                                                <template v-slot:label>
                                                    <div
                                                        class="d-flex align-baseline justify-center ls-25 font-13 py-4 rounded-4 text-center w-100">
                                                        <i class="ri-time-line fw-lighter ri-xl font-16 me-2"></i>
                                                        <span class="font-weight-semibold">Design Later</span>
                                                    </div>
                                                </template>
                                            </v-radio>

                                            <v-radio class="flex-grow-1 flex-md-basis-25" value="buy_blank3">
                                                <template v-slot:label>
                                                    <div
                                                        class="d-flex align-baseline justify-center ls-25 font-13 py-4 rounded-4 text-center w-100">
                                                        <i class="ri-t-shirt-line fw-lighter ri-xl font-16 me-2"></i>
                                                        <span class="font-weight-semibold">Buy as blank</span>
                                                    </div>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </div>


                                    <!-- Upload front back buttons  -->
                                    <v-row class="uploade-buttons">
                                        <v-col cols="12" md="6" class="pe-md-1">
                                            <v-btn
                                                class="font-13 text-gradient-1 font-weight-semibold ls-25 position-relative w-100 border-gradient-1 h-70"
                                                variant="outlined">
                                                <i class="ri-add-circle-fill font-15 me-1 font-weight-medium"></i>
                                                Upload Front Design
                                                <v-file-input
                                                    class="position-absolute top-0 font-weight-semibold start-0 w-100 h-100 opacity-0"
                                                    hide-details prepend-icon="" />
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="12" md="6" class="ps-md-1 pt-0 pt-md-3">
                                            <v-btn
                                                class="font-13 text-gradient-1 font-weight-semibold ls-25 position-relative w-100 border-gradient-1 h-70"
                                                variant="outlined">
                                                <i class="ri-add-circle-fill font-15 me-1 font-weight-medium"></i>
                                                Upload Back Design
                                                <v-file-input
                                                    class="position-absolute top-0 font-weight-semibold start-0 w-100 h-100 opacity-0"
                                                    hide-details prepend-icon="" />
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <!-- MORE OPTIONS -->
                                    <!-- <v-row class="px-2">
                                <v-col cols="12 py-0">
                                    <div class="font-weight-semibold font-15 text-gradient-3">MORE OPTIONS</div>
                                    <v-checkbox 
                                    name="more_options" 
                                    id="print_on_both_sides" 
                                    hide-details 
                                    ripple="false" 
                                    density="compact"
                                    >
                                    <template v-slot:label>
                                        <span class="font-14 text-primary font-weight-medium">Both Side Front and Back (+$2.5)</span>
                                    </template>
                                    </v-checkbox>

                                    <v-checkbox 
                                    name="more_options" 
                                    id="help_with_artfile" 
                                    hide-details 
                                    ripple="false" 
                                    density="compact"
                                    >
                                    <template v-slot:label>
                                        <span class="font-14 text-primary font-weight-medium">I need Help with My Artfile (+$49)</span>
                                    </template>
                                    </v-checkbox>

                                </v-col>
                            </v-row>  -->

                                    <!-- Number of imprint colors -->
                                    <!-- <v-row class="px-2 mt-5 mb-2">
                                <v-col cols="12 py-0">
                                    <v-select
                                    id="noofimprintcolors"
                                    name="number_of_imprint"
                                    variant="plain"
                                    hide-details
                                    :items="['1 Imprint Color', 'Full Imprint Color']"
                                    model-value="Yes (+$10)" 
                                    class="rounded-4 border-grey bg-white px-2 py-1 opacity-100"
                                    :menu-icon="'mdi-chevron-down'"
                                    >
                                    <template v-slot:label>
                                        <span class="d-flex align-center">
                                            Number of imprint colors
                                        </span>
                                    </template>
                                    </v-select>
                                </v-col>
                            </v-row>  -->

                                    <!-- Number of imprint colors -->
                                    <!-- <v-row class="px-2 mt-5 mb-2">
                                <v-col cols="12 py-0">
                                    <v-select
                                    id=""
                                    name=""
                                    variant="plain"
                                    hide-details
                                    :items="['Please Select']"
                                    model-value="Please Select" 
                                    class="rounded-4 border-grey bg-white px-2 py-1 opacity-100"
                                    :menu-icon="'mdi-chevron-down'"
                                    >
                                    <template v-slot:label>
                                        <span class="d-flex align-center">
                                            Imprint Color 1
                                        </span>
                                    </template>
                                    </v-select> 
                                </v-col>
                            </v-row> -->

                                    <!-- You'll upload your file within the order details after completing the checkout process -->
                                    <!-- <v-row class="px-2 mt-5 mb-2">
                                <v-col cols="12 py-0">
                                    <v-alert class="font-12 bg-warning2" text="You'll upload your file within the order details after completing the checkout process."></v-alert> 
                                </v-col>
                            </v-row> -->

                                </div>

                                <!-- Digital proof request -->
                                <!-- Digital proof request -->
                                <v-select variant="plain" hide-details :items="['Yes (+$10)', 'No']"
                                    model-value="Yes (+$10)"
                                    class="rounded-4 bg-light4 px-5 py-1 opacity-100 digital-proof font-weight-semibold"
                                    :menu-icon="'mdi-chevron-down'">
                                    <template v-slot:label>
                                        <span class="d-flex align-center font-weight-medium text-light7">
                                            Digital proof request
                                            <v-tooltip location="top">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" class="text-light6 ms-1 font-15">
                                                        <i class="ri-error-warning-fill"></i>
                                                    </v-icon>
                                                </template>
                                                <span>Digital proof request</span>
                                            </v-tooltip>
                                        </span>
                                    </template>
                                </v-select>


                            </div>

                            <!-- Full-width Border Above Actions -->
                            <v-divider class="mt-3 mb-3 border-grey opacity-100"></v-divider>
                            <!-- Sticky v-card-actions -->
                            <div class="px-md-6 px-4">
                                <v-card-actions class="pa-3 d-flex justify-space-between flex-wrap rounded-16">
                                    <v-row class="justify-center align-center">
                                        <v-col cols="12" md="6">
                                            <div class="font-weight-semibold font-15 mb-2">
                                                From {{ productSinglePrice(product) }} / Per box
                                                <small class="text-light7">({{ product.quantity }} {{ product.quantity >
                                                    1 ? 'Units' : 'Unit' }})</small>
                                            </div>
                                            <div class="font-weight-semibold font-15">
                                                {{ $store.getters.getCart?.quantity || 0 }} swagboxes
                                                <span class="text-bluish4">{{ productPrice(product) }}</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-btn min-height="46"
                                                class="font-weight-semibold font-15 ls-25 rounded-pill text-light8 bg-dark2 w-100"
                                                :disabled="!product.selected_color"
                                                v-if="!cartItemIds.includes(product.id)" @click="addToCart(product)">
                                                Add to box
                                            </v-btn>
                                            <v-btn min-height="46"
                                                class="font-weight-semibold font-15 ls-25 bg-error rounded-pill text-white w-100"
                                                outlined v-if="cartItemIds.includes(product.id)"
                                                @click="removeIndex(product.id)">
                                                Remove
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </div>
                    </v-col>

                </v-row>
            </v-container>
        </v-card-text>

        <v-divider class="rounded-16"></v-divider>
    </v-card>
</template>
  
  
<script>
import { cloneDeep } from 'lodash'
import NwImg from "@/components/NwImg"
export default {
    name: 'ProductDetail',
    props: {
        productSelected: {
            type: Object,
            default: function () {
                return {}
            },
            required: true
        }
    },
    components: {
        NwImg
    },
    data() {
        return {
            onboarding: 0,
            logo_colors: 1,
            selectedOption: "upload3"
            // images: []
        }   
    },  
    computed: {
        images() {
            if (!this.productSelected.selected_color) {
                let base_url = process.env.VUE_APP_S3_BASE;
                // console.log(base_url + 'thumbnails/' + this.productSelected.sku + '-thumbnail.png')
                return [base_url + 'medium/' + this.productSelected.sku + '-thumbnail.png']
            }

            let variants = this.productSelected.variants
            let variant = variants.find(obj => {
                return obj.color?.value == this.productSelected.selected_color.value
            })
            let images = []
            variant.images.forEach((object, key) => {
                let img_name = this.$helper.functions.getImage(object)
                images.push(img_name)
            })
            if (images.length > 0) {
                let prod = this.productSelected
                prod.images = images;
                this.$store.commit('updateCartProd', prod)
                return images
            } else {
                return []
            }
        },

        thumbImages() {
            if (!this.productSelected.selected_color) {
                let base_url = process.env.VUE_APP_S3_BASE;
                // console.log(base_url + 'thumbnails/' + this.productSelected.sku + '-thumbnail.png')
                return [base_url + 'thumbnails/' + this.productSelected.sku + '-thumbnail.png']
            }
            let variants = this.productSelected.variants
            let variant = variants.find(obj => {
                return obj.color?.value == this.productSelected.selected_color.value
            })
            let images = []
            variant.images.forEach((object, key) => {
                let img_name = this.$helper.functions.getImage(object, 'thumbnails')
                images.push(img_name)
            })
            if (images.length > 0) {
                return images
            } else {
                return []
            }
        },

        cartItemIds() {
            if (this.$store.state.cart?.box_products) {
                return this.$store.state.cart?.box_products.map(prod => prod.id)
            } else {
                return []
            }

        },

        product() {
            return this.productSelected
        },

        logoPrice() {
            return this.$store.getters.logoPrice
        },

        numberOfLogos: {
            get() {
                return this.$store.getters.numberOfLogos
            },
            set(newName) {
                // console.log(newName)
            }
        },

        quantityLimits() {
            return this.$store.getters.getQuantityLimits
        },

        volumePrice() {
            let volumePrice = this.productSelected.variants.find(variant => {
                return variant.color?.value === this.productSelected.selected_color.value
            }).volume_pricing.find(price => {
                return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
            })
            return volumePrice
        },
    },

    watch: {
        // whenever question changes, this function will run
        // productSelected(newQuestion, oldQuestion) {
        //     console.log('Watched')
        //     this.getImages(true)
        // }
    },

    methods: {
        basename(url) {
            if (url) {
                return url.split('/').pop();
            } else {
                return null
            }
        },

        productPrice(product) {
            if (!product.selected_color) {
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                if (!productLogoPrice) {
                    productLogoPrice = 0
                }
                let productLogoFinalPrice = (Number(productLogoPrice) * Number(product.quantity))
                // let basePrice = (Number(product.price) * Number(product.quantity)) + Number(productLogoFinalPrice)

                // let productLogoPrice = (Number(this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity)) * Number(product.quantity))
                let lowestPrice = this.$helper.functions.getProdLowestPrice(product, this.$store.getters.getCart?.quantity)
                // let basePrice = (Number(product.price) * Number(product.quantity)) + Number(productLogoFinalPrice)
                let basePrice = (Number(lowestPrice) * Number(product.quantity)) + Number(productLogoFinalPrice)
                let bulkPrice = (Number(basePrice) * Number(this.$store.getters.getCart?.quantity))

                return bulkPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
            let price = null
            if (product.sizes.length) {
                let prod = JSON.stringify(product)
                prod = JSON.parse(prod)
                price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
                price = Number(price.toFixed(2)) * Number(this.$store.state.cart?.quantity)
            } else {
                let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                product.imprint_color_price = productLogoPrice
                // console.log('prodPrice', prodPrice)
                price = Number(prodPrice) * Number(this.$store.state.cart?.quantity)
                // price = Number(prodPrice)
            }
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getSizedProdPrice(product) {
            let price = 0
            let sizes = product.sizes
            let sizeQuantity = 0
            let prodFinalPrice = 0
            let boxQuantity = this.$store.getters.getCart?.quantity
            let variant = {}
            sizes.forEach(object => {
                sizeQuantity += Number(object.quantity);
            });

            if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                    return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })
                if (variant) {
                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity >= boxQuantity
                    })
                    sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                    sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                    sizes[0].sku = variant.sku
                }
                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
                product.final_price = prodFinalPrice.toFixed(2)
            }

            // || !this.cartItemIds.includes(product.id)
            // if (sizeQuantity == 0) {
            let qua = sizes.length - 1
            let sameValue = (boxQuantity / qua)
            sameValue = Math.trunc(sameValue)
            if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                    return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })
                if (variant) {
                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity >= boxQuantity
                    })
                    sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                    sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                    sizes[0].sku = variant.sku
                }

                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
            } else {
                sizes.forEach((size, key) => {
                    if (key == qua) {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        if (variant) {
                            let volumePrice = variant.volume_pricing.find(price => {
                                return price.quantity >= boxQuantity
                            })
                            size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
                            size.price = (size.quantity * volumePrice.price).toFixed(2)
                            size.sku = variant.sku
                        }
                        prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                    } else {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        if (variant) {
                            let volumePrice = variant.volume_pricing.find(price => {
                                return price.quantity >= boxQuantity
                            })
                            size.quantity = Number(sameValue) * Number(product.quantity)
                            size.price = (size.quantity * volumePrice.price).toFixed(2)
                            size.sku = variant.sku
                        }
                        prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                    }
                });
            }
            product.final_price = prodFinalPrice.toFixed(2)
            // }

            let logoFinalPrice = Number(this.logoPrice) * Number(product.quantity)
            // console.log('Final Prod', product)

            price = ((Number(logoFinalPrice) + Number(product.final_price)) / Number(boxQuantity))
            return price
        },

        productSinglePrice(product) {
            let price = null

            if (!product.selected_color) {
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                if (!productLogoPrice) {
                    productLogoPrice = 0
                }
                let lowestPrice = this.$helper.functions.getProdLowestPrice(product, this.$store.getters.getCart?.quantity)
                // console.log(lowestPrice)
                let productLogoFinalPrice = (Number(productLogoPrice) * Number(product.quantity))
                // let basePrice = (Number(product.price) * Number(product.quantity)) + Number(productLogoFinalPrice)
                let basePrice = (Number(lowestPrice) * Number(product.quantity)) + Number(productLogoFinalPrice)

                return basePrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }

            if (product.sizes.length) {
                let prod = JSON.stringify(product)
                prod = JSON.parse(prod)
                price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
                price = Number(price)
            } else {
                let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                product.imprint_color_price = productLogoPrice
                // console.log('productLogoPrice', productLogoPrice)
                // price = (Number(product.quantity) * Number(prodPrice)) + (Number(productLogoPrice) * Number(product.quantity))
                price = Number(prodPrice)
            }
            // if (product.price == product.final_price) {
            // price = (Number(product.quantity) * Number(this.volumePrice.price)) + (Number(this.logoPrice) * Number(product.quantity))
            // } else {
            //     price = product.final_price / this.$store.state.cart?.quantity
            //     price = Number(price) + Number(this.logoPrice)
            // }
            // console.log('PROD',product)
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        updateCart(product, color) {
            if (this.cartItemIds.includes(product.id)) {
                product.selected_color = color
                let variant = {}
                if (product.sizes.length > 0) {
                    product.sizes.forEach((size, key) => {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        if (variant) {
                            size.sku = variant.sku
                        }
                    });
                } else {
                    let boxProduct = this.$store.state.cart?.box_products.find(prod => {
                        return prod.id === product.id
                    })
                    // boxProducts.forEach(product => {
                    boxProduct.variants.forEach((variant, key) => {
                        delete variant["quantity"]
                        delete variant["price"]
                    });
                    // })

                    variant = product.variants.find(variant => {
                        return variant.color?.value === product.selected_color.value
                    })
                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity >= this.$store.state.cart?.quantity
                    })

                    let varPrice = (Number(volumePrice.price) + (Number(this.logoPrice) * Number(product.quantity))) * Number(this.$store.state.cart?.quantity)
                    variant.quantity = Number(this.$store.state.cart?.quantity) * Number(product.quantity)
                    variant.price = varPrice.toFixed(2)
                    product.final_price = varPrice.toFixed(2)
                }


                this.$store.commit('updateCartProd', product)
                // console.log(product)
            }
            // this.getImages()
        },

        addToCart(product) {
            if (!product.selected_color) {
                product.selected_color = product.colors[0] ? product.colors[0] : null
            }
            // product.final_price = (Number(product.quantity) * Number(product.price)) + (Number(this.logoPrice) * Number(product.quantity))
            this.$store.commit('pushToCart', product)
        },

        removeIndex(itemId) {
            let itemIndex = this.$store.state.cart?.box_products.findIndex(prod => prod.id == itemId)
            // this.$helper.functions.resetItem(this.$store.getters.products, this.$store.state.cart?.box_products[itemIndex])
            this.$store.commit('removeItemFromCart', itemIndex)
        },

        next() {
            this.onboarding = this.onboarding + 1 === this.length ?
                0 :
                this.onboarding + 1
        },

        prev() {
            this.onboarding = this.onboarding - 1 < 0 ?
                this.length - 1 :
                this.onboarding - 1
        },

        increment() {
            if (this.product.quantity < 10) {
                this.product.quantity = this.product.quantity + 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                setTimeout(() => {
                    this.$store.commit('updateCartProd', this.product)
                }, 10)

            }
        },

        decrement() {
            // console.log(this.product.quantity)
            if (this.product.quantity != 1) {
                this.product.quantity = this.product.quantity - 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                setTimeout(() => {
                    this.$store.commit('updateCartProd', this.product)
                }, 10)
            }
        },

        logoColorsincrement(product) {
            if (product.number_of_logos < 4) {
                product.number_of_logos = Number(product.number_of_logos) + 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                this.$store.commit('updateLogoColors', product.number_of_logos)
            }
        },

        logoColorsdecrement(product) {
            if (product.number_of_logos != 1) {
                product.number_of_logos = Number(product.number_of_logos) - 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                this.$store.commit('updateLogoColors', product.number_of_logos)
            }
        }

        // getImages(removeImages = false) {
        //     if(removeImages) {
        //         this.images = []
        //     }
        //     //
        //     let variants = this.productSelected.variants
        //     let variant = variants.find(obj => {
        //         return obj.color?.value == this.productSelected.selected_color.value
        //     })
        //     let images = []
        //     variant.images.forEach((object, key) => {
        //         images.push(object.link)
        //     })
        //     console.log('Called')
        //     let imageLoaded = 0;
        //     for (let i = 0; i < images.length; i++) {

        //         const img = new Image();
        //         img.src = images[i];

        //         img.onload = () => {
        //             imageLoaded++;
        //             console.log(images[i])
        //             if (imageLoaded === images.length) {
        //                 console.log("Done !");
        //                 this.images = images
        //                 //         // this.isLoading = false;
        //             }
        //         };
        //     }
        // }
    },

    created() {
        // this.getImages();
        // let variants = this.productSelected.variants
        // let variant = variants.find(obj => {
        //     return obj.color?.value == this.productSelected.selected_color.value
        // })
        // let images = []
        // variant.images.forEach((object, key) => {
        //     images.push(object.link)
        // })

        // let imageLoaded = 0;
        // for (let i = 0; i < images.length; i++) {

        //     const img = new Image();
        //     img.src = images[i];

        //     img.onload = () => {
        //         imageLoaded++;
        //         console.log(images[i])
        //         if (imageLoaded === images.length) {
        //             console.log("Done !");
        //             this.images = images
        //             //         // this.isLoading = false;
        //         }

        //         console.log(imageLoaded);
        //     };
        // }
        // console.log('kana')
        // if (images.length > 0) {
        //     return images
        // } else {
        //     return []
        // }
    }
}
</script>

<style scoped>

</style>

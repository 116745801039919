<template>
  <v-app>
    <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
      <v-overlay :model-value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <v-card elevation="3" rounded="lg">
              <div class="py-6 text-center">
                <v-btn :to="{ name: 'Home' }" class="transparent elevation-0" variant="text">
                  <v-img :src="logoSrc" max-width="100%" height="auto" width="200"></v-img>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="py-4 px-4">
                <h3 class="text-body-2 font-weight-semibold text-primary">Forgot password?</h3>
                <p class="text-body-2 text-primary font-weight-medium">No sweat! We got you.</p>
                <v-form @submit.prevent="forgotPassword">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="py-1 text-left">
                        <v-label class="opacity-100 font-weight-medium font-14 mb-1">Email</v-label>
                        <v-text-field
                          v-model="user.email"
                          placeholder="john@example.com"
                          variant="outlined"
                          density="compact"
                          required
                          :error-messages="errors.email"
                        ></v-text-field>
                        <div class="text-left mt-2text-body-2 ">We'll email you a link to reset your password.</div>
                      </v-col>
                      <v-col cols="12" class="py-1">
                        <v-btn
                          class="font-weight-semibold text-none rounded-pill py-md-5"
                          type="submit"
                          color="primary"
                          block
                        >
                          Send reset link
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
              <v-divider></v-divider>
              <div class="py-2 text-body-1 text-center"> 
                <v-btn 
                  variant="text" 
                  height="auto"
                  compact="comfortable"
                  class="text-primary px-0 min-width-0 elevation-0  text-decoration-underline"
                  :to="{ name: 'Login' }"
                >Back to log in
                </v-btn> 
                
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ForgotPassword",
  setup() {
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const logoSrc = new URL('@/assets/images/swagbox-logo.png', import.meta.url).href;

    const user = reactive({
      email: "",
    });

    const errors = reactive({
      email: "",
    });

    const forgotPassword = async () => {
      errors.email = user.email ? "" : "Email is required";
      if (!user.email) return;

      loading.value = true;

      try {
        const response = await store.dispatch("FORGOT_PASSWORD", user);
        store.commit("showAlert", {
          text: response.message,
          successStatus: true,
        });
        router.push({ name: "Home" });
      } catch (error) {
        store.commit("showAlert", {
          text: error.data?.message || "Something went wrong",
          successStatus: false,
          alertColor: "red-darken-4",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      user,
      errors,
      loading,
      logoSrc,
      forgotPassword,
    };
  },
};
</script>

<style scoped>
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0;
}
</style>

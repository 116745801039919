import { createRouter, createWebHistory } from 'vue-router'
// import VueRouter from 'vue-router'
import Home from '@/views/Home'
import ProductOnboarding from '@/views/ProductOnboarding'
import PresetBoxes from '@/views/PresetBoxes'
import Cart from '@/views/Cart'
import PaymentOptions from '@/views/PaymentOptions'
import ProductCatalog from '@/views/ProductCatalog'
import LoginPage from '@/views/LoginPage'
import ForgotPassword from '@/views/ForgotPassword'
import SignUpPage from '@/views/SignUpPage'
// import VerifyAccount from '@/views/SignUpPage/VerifyAccount'
import SetPassword from '@/views/SetPassword'
import NewPasswordSet from '@/views/NewPasswordSet'
import ThankYou from '@/views/ThankYou'
import OrderQuote from '@/views/ThankYou/OrderQuote.vue'
import CartEmpty from '@/views/CartEmpty'
import EmailVerified from '@/views/EmailVerified'
import AccountCreated from '@/views/AccountCreated'
import ProofAction from '@/views/ProofAction'
import UploadArtwork from '@/views/UploadArtwork'
import Payment from '@/views/Payment'
import NotFound from '@/views/NotFound'
// import DashBoard from '@/views/CustomerDashboard/DashBoard'
import TermsOfUse from '@/views/TermsOfUse'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import CustomerDashboard from '@/views/CustomerDashboard'
import Dashboard from '@/views/CustomerDashboard/Dashboard'
import Quotes from '@/views/CustomerDashboard/Quotes'
import Orders from '@/views/CustomerDashboard/Orders'
import Shipment from '@/views/CustomerDashboard/Shipment'
import ShipmentDetails from '@/views/CustomerDashboard/Shipment/ShipmentDetails'
import ContactsList from '@/views/CustomerDashboard/ContactsList'
import AddNewContact from '@/views/CustomerDashboard/ContactsList/AddNewContact'
import ContactGroups from '@/views/CustomerDashboard/ContactGroups'
import AddContactGroup from '@/views/CustomerDashboard/ContactGroups/AddContactGroup'
import Inventory from '@/views/CustomerDashboard/Inventory'
import OrderDetails from '@/views/CustomerDashboard/Orders/OrderDetails'
import store from '@/store/index';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { public: true, guest: true },
  },
  {
    path: "/product-on-boarding",
    name: "ProductOnboarding",
    component: ProductOnboarding,
    meta: { public: true, guest: true },
  },
  {
    path: "/preset-boxes",
    name: "PresetBoxes",
    component: PresetBoxes,
    meta: { public: true, guest: true },
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: { public: true, guest: true },
  },
  {
    path: "/payment-options",
    name: "PaymentOptions",
    component: PaymentOptions,
    meta: { public: true, guest: true },
  },
  {
    path: "/product-catalog",
    name: "ProductCatalog",
    component: ProductCatalog,
    meta: { public: true, guest: true },
  },
  {
    path: "/login",
    name: "Login", // Change from "LoginPage" to "Login"
    component: LoginPage,
    meta: { public: true, guest: true, hideNavbar: true, hideFooter: true, is_auth_page: true },
  },
  
  {
    path: "/sign-up",
    name: "SignUpPage",
    component: SignUpPage,
    meta: { public: true, guest: true, hideNavbar: true, hideFooter: true, is_auth_page: true },
  },
  {
    path: "/verify-account/:token",
    name: "EmailVerified",
    component: EmailVerified,
    meta: { public: true, guest: true, is_auth_page: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { public: true, guest: true, hideNavbar: true, hideFooter: true, is_auth_page: true },
  },
  {
    path: "/reset-password/:token",
    name: "SetPassword",
    component: SetPassword,
    meta: { public: true, guest: true, hideNavbar: true, hideFooter: true, is_auth_page: true },
  },
  {
    path: "/password-set",
    name: "NewPasswordSet",
    component: NewPasswordSet,
    meta: { public: true, guest: true, hideNavbar: true, hideFooter: true, is_auth_page: true },
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYou,
    meta: { public: true, guest: true },
  },
  {
    path: "/order-quote",
    name: "OrderQuote",
    component: OrderQuote,
    meta: { public: true, guest: true },
  },
  {
    path: "/cart-empty",
    name: "CartEmptyView", // Updated name to match the renamed file
    component: CartEmpty,
    meta: { public: true, guest: true },
},
  {
    path: "/proof-action/:order_id",
    name: "ProofAction",
    component: ProofAction,
    meta: { public: true, guest: true },
  },
  {
    path: "/upload-artwork/:order_id",
    name: "UploadArtwork",
    component: UploadArtwork,
    meta: { public: true, guest: true },
  },
  {
    path: "/payment/:order_id",
    name: "Payment",
    component: Payment,
    meta: { public: true, guest: true },
  },
  {
    path: "/account-created",
    name: "AccountCreated",
    component: AccountCreated,
    meta: { public: true, guest: true },
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
    meta: { public: true, guest: true },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: { public: true, guest: true },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { public: true, guest: true },
  },
  {
    path: "/customer",
    component: CustomerDashboard,
    meta: { hideNavbar: true },
    children: [
      { path: "dashboard", component: Dashboard },
      { path: "quotes", component: Quotes },
      { path: "orders", component: Orders },
      { path: "shipment", component: Shipment },
      { path: "inventory", component: Inventory },
      { path: "order-detail/:order_id", component: OrderDetails },
      { path: "shipment-details", component: ShipmentDetails },
      { path: "contacts", component: ContactsList },
      { path: "new-contact", component: AddNewContact },
      { path: "contact-groups", component: ContactGroups },
      { path: "add-contact-group", component: AddContactGroup },
    ],
  },
];


// const router = new VueRouter({
//   mode: "history",
//   base: '/',
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     //https://router.vuejs.org/guide/advanced/scroll-behavior.html
//     if (to.hash) {
//       return { selector: to.hash }
//     } else if (savedPosition) {
//       return savedPosition;
//     } else {
//       return { x: 0, y: 0 }
//     }
//   }
// });

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// 🛑 Authentication Guard
router.beforeEach((to, from, next) => {
  const loggedIn = store.getters.isLoggedIn;
  
  if (to.name === null) {
    return next({ path: "/not-found" });
  }

  if (to.meta.is_auth_page && loggedIn) {
    return next({ path: "/customer/dashboard" });
  }

  if (!to.meta.public && !loggedIn) {
    return next({ path: "/", query: { redirect: to.fullPath } });
  }

  next();
});

export default router;

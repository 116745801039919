<template>
<v-container fluid class="bg-gray py-10 py-md-15">
    <v-row class="text-center pt-lg-15 justify-center">
        <v-col cols="12" md="6" lg="6">
            <h2 class="mb-5 mb-md-10">
                There are a lot more reasons to build your swagbox with us
            </h2>
        </v-col>
    </v-row>
    <v-row class="pb-lg-15">
        <v-col cols="12" md="6">
            <v-row>
                <v-col v-for="(feature, index) in features" :key="index" cols="12" sm="6" md="6">
                    <v-card class="py-md-8 px-md-8 px-4 py-4 text-left bg-white elevation-0 feature-card h-100">
                        <v-img class="mb-md-8 mb-5" :src="feature.icon" width="64" />
                        <h6 class="mb-3 text-dark3 font-20 ls-05 font-weight-semibold">{{ feature.title }}</h6>
                        <p class="text-black font-14 ls-75">{{ feature.description }}</p>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6">
            <div style="background: #cbcbe7" class="rounded-xl d-flex justify-center align-center h-100">
                <v-col cols="12" md="9">
                    <v-img :src="require('@/assets/images/build-your-boxes.png')" alt="Swag Management" class="fill-height"></v-img>
                </v-col>
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "BuildYourSwagbox",
    data() {
        return {
            features: [{
                    title: "Custom Swag Design",
                    description: "Not a designer? No worries, we design the swag for you for free. ",
                    icon: require("@/assets/images/svg/design.svg"),
                },
                {
                    title: "Assembly & Packaging",
                    description: "The days of packing swag yourself are over. We do this for you. ",
                    icon: require("@/assets/images/svg/assembly.svg"),
                },
                {
                    title: "Inventory Management",
                    description: "No need to store swag in your garage. Your swag is safe with us. ",
                    icon: require("@/assets/images/svg/inventory.svg"),
                },
                {
                    title: "Online Stores",
                    description: "Have left over swag laying around? Send it to us to store for you.",
                    icon: require("@/assets/images/svg/store.svg"),
                },
            ],
        };
    },
};
</script>

<style scoped></style>

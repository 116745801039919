<template> 
  <div class="bg-light1 mb-3 pb-3 pt-sm-8 pt-5 px-4 rounded-16" elevation="0"> 
    <div class="position-relative   pb-md-5">
      <div class="title-border d-none d-md-block"></div>
      <div class="position-absolute mobile-relative top-0 bottom-0 bg-light1 mt-md-n4 pr-md-3">
        <div class="text-primary font-weight-semibold ls-75 font-18 d-md-flex text-left align-center">
          Contact & Signup 
        </div>
      </div>
    </div>

    <form @submit.prevent="handleSignup" class="mb-3 mt-sm-8 mt-md-0">
      <!-- Checkout as Guest Toggle --> 
      <v-row>
        <v-col cols="12" class="d-flex align-center mb-2">
          <label 
            for="guest-switch" 
            class="font-weight-semibold text-primary font-13 d-flex align-center cursor-pointer"
          >
            Checkout as Guest
          </label>
          <v-switch 
          id="guest-switch"
          v-model="checkoutAsGuest" 
          inset 
          hide-details
          density="compact"
          class="ml-2"
          max-height="27"
          color="primary"
          :class="{ 'opacity-100': checkoutAsGuest, 'opacity-50': !checkoutAsGuest }"
        ></v-switch>
        </v-col>
      </v-row>

      
      <!-- Contact & Signup Form -->
      <v-form class="ship-form">
      <v-row class="mb-2">
        <v-col cols="12 py-0">
  
          <v-text-field  type="email"  v-model="email" variant="solo" density="comfortable"
              class="elevation-0" hide-details>
              <template v-slot:label>
                  <span class="text-light7">Email Address <span
                          class="text-error">*</span></span>
              </template>
          </v-text-field>
          
        </v-col>
        <v-col cols="12" sm="6"> 
          <v-text-field  type="password"  v-model="password" variant="solo" density="comfortable"
              class="elevation-0" hide-details>
              <template v-slot:label>
                  <span class="text-light7">Set a strong password <span
                          class="text-error">*</span></span>
              </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0 pt-sm-3"> 
          <v-text-field  type="confirmPassword"  v-model="confirmPassword" variant="solo" density="comfortable"
              class="elevation-0" hide-details>
              <template v-slot:label>
                  <span class="text-light7">Confirm password <span
                          class="text-error">*</span></span>
              </template>
          </v-text-field>
        </v-col> 
      </v-row>
    </v-form>

      <!-- Password requirements -->
      <div class="text-light7 text-left font-12 font-weight-medium">
        Minimum 8 characters, At least one uppercase character, 
        At least one lowercase character, One number & one special character
      </div>
    </form>
  </div> 
</template>

<script>
export default {
  name: 'ContactSignup',
  data() {
    return {
      checkoutAsGuest: false,
      email: "",
      password: "",
      confirmPassword: "",
      CartItem: [],
    };
  },
  methods: {
    handleSignup() {
      console.log("Signup form submitted");
    },
  },
};
</script>

<style scoped> 
</style>

import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light', // Default to light theme
    themes: {
      light: {
        colors: {
          primary: '#14142B',
          secondary: '#1A2453',
          green: '#29909A',
          blue: '#1A0C2C',
          gray: '#EDEDF7', 
          background: '#FFFFFF', 
          onBackground: '#000000', // Set text color to black
          error: '#CA024F', // Override Vuetify's default error color
        },
      },
      dark: {
        colors: {
          primary: '#14142B',
          secondary: '#1A2453',
          green: '#29909A',
          background: '#000000', // Dark background
          onBackground: '#FFFFFF', // White text on dark background
          error: '#CA024F', // Ensure error color is consistent in dark mode
        },
      },
    },
  },
});

export default vuetify;

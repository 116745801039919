<template>
    <div class="loader-full">
      <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
        <v-img :src="swagboxIcon" contain alt="Swagbox Logo" height="36"></v-img>
      </v-progress-circular>
    </div>
  </template>
  
  <script>
  import swagboxIcon from '@/assets/images/swagbox-icon.png';
  
  export default {
    name: 'PageLoader',
    data() {
      return {};
    }
  };
  </script>
  
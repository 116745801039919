<template>
  <v-container fluid class="mb-8 mb-md-15 pb-md-10 wavebg">
    <v-row class="align-center px-4 justify-center">
      <v-col cols="12" lg="7" class="text-center">
        <div class="pt-md-10 pt-5 pb-0 pb-sm-6 z-10 position-relative">
          <h1 class="text-black font-40 ls-75">
            Pick an option
          </h1>
          <p class="my-4 font-16 ls-75 text-black">
            We've made it easy for you to get started in building your swag box, by providing different ways to begin. Pick the customization option that best suits your needs.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-4 justify-center">
      <!-- Dynamically Generated Cards -->
      <v-col
        v-for="(option, index) in options"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card
          :to="{ name: option.routeName }"
          class="pa-5 option-card rounded-16 text-left z-10"
          height="100%"
        >
        <div class="mb-md-8 mb-5 rounded-16 bg-pink-light">
            <v-img
              :src="require(`@/assets/images/${option.image}`)"
              :alt="option.title"
              class="fill-height"
            ></v-img>
          </div>
          <h6 class="font-20 text-left text-dark2 ls-75 mb-2 font-weight-semibold">
            {{ option.title }}
          </h6>
          <p class="ma-0 font-14 text-left text-black ls-75 mb-5">
            {{ option.description }}
          </p> 
          <v-btn variant="outlined" color="primary" class="px-6 font-16 font-weight-bold" rounded min-height="40">
              Start Building my Box
              <i class="ml-2 ri-arrow-right-line font-16 font-weight-light"></i>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PickOption',
  data() {
    return {
      options: [
        {
          title: 'Preset Boxes',
          description:
            'With our preset boxes, you can now skip the selection process of products.',
          image: 'preset-boxes.png',
          routeName: 'PresetBoxes',
        },
        {
          title: 'Do it yourself',
          description:
            'Want to get creative and create your own customized box? Let\'s do it!',
          image: 'do-it-yourself.png',
          routeName: 'ProductCatalog',
        },
        {
          title: 'Bulk swag. No box',
          description:
            'Not looking to bundle your items in a box at all? That\'s okay! Order single or multiple items in bulk.',
          image: 'bulk-swag.png',
          routeName: 'ProductCatalog',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>

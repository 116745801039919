<template>
<v-footer class="py-8 footer-bg">
    <v-row class="justify-space-between align-center">
        <!-- Left Section: Logo & Copyright -->
        <v-col cols="12" md="4" class="text-center text-md-left">
            <v-img :src="require('@/assets/images/swagbox-white-logo.png')" alt="Swagboxes Logo" width="159px" class="mb-4 mx-auto mx-md-0" />
            <p class="font-weight-light font-14 text-light5 ls-5 mb-md-3">SWAGFLO<br>
                4659 Wright Rd, Stafford, Texas 77477<br>
                United States of America</p>
            <p class="font-weight-light font-13 mt-2 mb-2 text-white">
                © {{ new Date().getFullYear() }} Swagboxes. All rights reserved.
            </p>
        </v-col>

        <!-- Right Section: Social Media Icons -->
        <v-col cols="12" md="8" class="text-md-end text-center">
            <v-row class="mb-md-3">
                <v-col col="12" class="py-0">
                    <v-btn :to="{ name: 'PresetBoxes' }" variant="text" class="text-white font-weight-light ls-75 me-md-1">
                        Preset boxes
                    </v-btn>
                    <v-btn :to="{ name: 'ProductCatalog' }" variant="text" class="text-white font-weight-light ls-75 me-md-1">
                        Do it yourself
                    </v-btn>
                    <v-btn :to="{ name: 'ProductCatalog' }" variant="text" class="text-white font-weight-light ls-75 me-md-1">
                        Bulk Swag
                    </v-btn>

                    <v-btn href="https://www.swagifypod.com/contact-us" target="_blank" rel="noopener noreferrer" variant="text" class="text-white font-weight-light ls-75">
                        Contact us
                    </v-btn>

                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" md="12" class="text-md-end text-center py-0 mb-10">
                    <v-btn href="https://print-on-demand.swagify.com/terms-of-use.html" target="_blank" variant="text" class="text-light6 font-weight-light ls-75 me-md-1">
                        Terms of Use
                    </v-btn>
                    <v-btn href="https://print-on-demand.swagify.com/privacy-policy.html" target="_blank" variant="text" class="text-light6 font-weight-light ls-75 me-md-1">
                        Privacy Policy
                    </v-btn>
                    <v-btn href="https://www.swagifypod.com/cookie-policy" target="_blank" variant="text" class="text-light6 font-weight-light ls-75 ">
                        Cookie Policy
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" class="social-icons">
                <v-col col="12" md="12" class="text-md-end text-center py-0">
                    <v-btn v-for="icon in icons" :key="icon" class="mx-2 bg-dark1 text-white text-white social-icon" width="32" variant="flat" icon>
                        <i :class="icon" style="font-size: 16px;"></i>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-footer>
</template>

<script>
export default {
    name: "FooterBar",
    data() {
        return {
            icons: [
                "ri-twitter-fill", // Twitter icon
                "ri-facebook-fill", // Facebook icon
                "ri-instagram-fill", // Instagram icon
            ],
        };
    },
};
</script>

<style scoped>

</style>

<template>
  <v-app>
    <!-- Pick an option-->
    <PickOption />

    <!-- Pick an option-->
    <powered-platforms />

  </v-app>
</template>

<script>
  import PickOption from "./components/PickOption"
  import PoweredPlatforms from './components/PoweredPlatforms.vue'

  export default {
    name: 'ProductOnboarding',
    components: {
      PickOption,
      PoweredPlatforms,
    },
    mounted() {},

    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>

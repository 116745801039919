<template>
<v-container fluid class="py-10 py-md-12 bg-gradinet3">
    <v-row class="align-center px-md-4 justify-center">
        <v-col cols="12" lg="10">
            <v-row class="text-start px-4 justify-space-between align-center">
                <v-col cols="12" sm="6" lg="5" class="text-left">
                        <h2 class="mb-3 font-40 text-dark3 ls-75">
                            All Powered By<br class="d-none d-sm-block" />
                            Our Platform
                        </h2>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Real time
                            inventory tracking & management
                        </p>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Create
                            custom Swag Shops in seconds
                        </p>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Use Redeem
                            Pages to automate shipments
                        </p>
                        <p class="text-black font-16 pt-3 d-flex ls-75">
                            <i class="ri-arrow-right-line mr-2 text-bluish"></i> Send swag &
                            rewards around the globe
                        </p>
                        <v-btn
                        variant="outlined"
                        color="primary"
                        class="mt-8 px-7 font-weight-bold bg-transparent"
                        min-height="40"
                        rounded
                        href="https://www.swagifypod.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Book a demo
                        <i class="ri-arrow-right-line ml-2 font-16 font-weight-light"></i>
                        </v-btn>


                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="ps-md-0">
                        <v-img :src="require('@/assets/images/pc.png')" alt="Swag Management" class="fill-height overflow-visible wave2 position-relative"></v-img>
                    </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "SwagGift",
    components: {},
    mounted() {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped></style>

<template>
<v-container fluid class="py-10 py-md-12 bg-gradinet2">
    <v-row class="align-center px-md-4 justify-center">
        <v-col cols="12" lg="10">
            <v-row class="text-start px-4 justify-space-between align-center">
                <v-col cols="12" sm="12" md="6" lg="5">
                    <div>
                        <h2 class="mb-4 text-light4 ls-75">
                            Start sending incredible swag <br class="d-none d-md-block" />
                            and gifts
                        </h2>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <p class="mb-3 text-light5 ls-75">
                                    A platform for managing swag effortlessly, streamlining the
                                    process of finding, storing, and delivering promotional
                                    merchandise.
                                </p>
                            </v-col>
                        </v-row> 
                        <v-btn
                        variant="outlined"
                        color="white"
                        class="mt-4 px-6 text-unset font-16 font-weight-bold bg-transparent"
                        min-height="40"
                        rounded
                        href="https://www.swagifypod.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Book a demo
                        <i class="ml-2 ri-arrow-right-line font-16 font-weight-light"></i>
                        </v-btn>  
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="ps-md-0">
                    <v-img :src="require('@/assets/images/swag-gifts.png')" alt="Swag Management" class="fill-height overflow-visible wave2 position-relative"></v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "SwagGift",
    components: {},
    mounted() {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped></style>

<template>
  <v-app>
    <v-container fluid class="bg-gradient-4">
      <!-- Sign In Component -->
       <div class="mb-3 mb-md-5 pt-2">
          <SignIn />
        </div>
      
      <v-row>
        <v-col cols="12" sm="12">
          <!-- Cart items -->
          <CartItems />
        </v-col>
      </v-row>
      
      <hr class="my-3 mb-md-5 border-grey">
       
      <v-row>
        <v-col cols="12" sm="12" md="7">
          <div class="text-black ls-75 font-15 font-weight-semibold text-left mb-md-1">Estimated Delivery Date</div>
          
          <v-radio-group v-model="selectedOption" class="text-left">
            <v-radio value="one">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 14th [+ $6.23 each] (1 DAY RUSH)
                </span>
                <span class="d-flex align-baseline">
                  <v-icon class="ri-gift-line text-gradient-2 font-20 mx-2" ></v-icon> 
                  <span class="text-gradient-2 font-12 ls-75 font-weight-bold text-uppercase">Upgrade to save up to $172</span>
                </span>
              </template>
            </v-radio>

            <v-radio value="two">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 15th [+ $4.48 each] (2 DAY RUSH)
                </span>
                <span class="d-flex align-baseline">
                  <v-icon class="ri-gift-line text-gradient-2 font-20 mx-2" ></v-icon> 
                  <span class="text-gradient-2 font-12 ls-75 font-weight-bold text-uppercase">Upgrade to save upto $156</span>
                </span>
              </template>
            </v-radio>
            <v-radio value="three">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 18th [+ $2.23 each] (3 DAY RUSH)
                </span>
                <span class="d-flex align-baseline">
                  <v-icon class="ri-checkbox-circle-fill text-gradient-3 font-20 mx-2" ></v-icon> 
                  <span class="text-gradient-3 font-12 ls-75 font-weight-bold text-uppercase">YOU’RE SAVING $120 ON SHIPPING</span>
                </span> 
                
              </template>
            </v-radio>
            <v-radio value="four">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 19th [+ $2.02 each] (4 DAY RUSH)
                </span>
              </template>
            </v-radio>
            <v-radio value="five">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 20th [+ $1.96 each] (5 DAY RUSH)
                </span>
              </template>
            </v-radio>
            <v-radio value="six">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 21st [+ $1.96 each] (STANDARD)
                </span>
              </template>
            </v-radio>
            <v-radio value="seven">
              <template v-slot:label>
                <span class="font-13 font-weight-medium ls-25 text-black">
                  March 22nd [FREE] (STANDARD)
                </span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="12" md="5">
          <!-- Order summary -->
           <OrderSummary />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import SignIn from "@/views/CartEmpty/components/SignIn.vue";
import OrderSummary from "@/views/Cart/components/OrderSummary.vue";
import CartItems from "@/components/CartItems";


export default {
  name: 'Cart-page',
  components: {
    CartItems,
    OrderSummary,
    SignIn,
  },
  data() {
    return {
      selectedOption: null, // Stores the selected radio value
    };
  },
  created() {
    console.log("Cart products:", this.$store.state.cart?.box_products);
    if (!this.$store.state.cart?.box_products || this.$store.state.cart?.box_products?.length === 0) {
      // this.$router.push({ name: 'CartEmpty' }); // 🔴 Comment this out to test
    }
  },
};
</script>

<style scoped>
</style>

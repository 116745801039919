<template>
  <v-container fluid class="gradient-bg py-10 py-md-15">
    <v-row class="position-absolute position-mob-rel z-1">
      <v-col cols="12" sm="8" md="7" lg="6">
        <div class="text-left mt-lg-10 pt-lg-15 ps-lg-10">
          <h1 class="ls-75 mb-3 mb-md-5">
            Design and send custom swag anywhere, anytime.
          </h1>

          <v-row>
            <v-col cols="12" sm="8">
              <p class="font-16 ls-75 font-weight-regular mb-3">
                Say goodbye to boring gifts and say hello, to a fully featured
                swag gifting platform for your employees, friends, and family.
              </p>
            </v-col>
          </v-row> 
          <v-btn min-height="42" variant="outlined" color="primary"
            class="mt-4 px-5 ls-75 font-weight-bold font-16" rounded :to="{ name: 'ProductOnboarding' }">
            Start Building my Box
            <v-icon class="ml-2 ri-arrow-right-line font-16 font-weight-light"></v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="z-0 py-md-5">
      <v-col cols="12" md="12">
        <v-img :src="require('@/assets/images/hero-bner.webp')" alt="Design and send custom swag anywhere anytime"
          class="fill-height"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


export default {
  name: 'HeroSection',
  data() {
    return {
      carouselItems: [
        {
          src: require('@/assets/images/home-slider1.png'),
          title: 'Distribute',
        },
        {
          src: require('@/assets/images/home-slider2.png'),
          title: 'Automate',
        },
        {
          src: require('@/assets/images/home-slider3.png'),
          title: 'Create',
        },
        {
          src: require('@/assets/images/home-slider2.png'),
          title: 'Automate',
        },
        {
          src: require('@/assets/images/home-slider3.png'),
          title: 'Create',
        },
        {
          src: require('@/assets/images/home-slider1.png'),
          title: 'Distribute',
        },
        {
          src: require('@/assets/images/home-slider2.png'),
          title: 'Automate',
        },
      ],
    };
  },
  mounted() {

  },
};
</script>

<style scoped>

</style>

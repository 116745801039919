import { createApp } from 'vue';
import App from './App.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import vuetify from '@/plugins/vuetify';
import router from './router/index.js';
import store from './store/index';
import helper from "./helper";
import '@/plugins/vee-validate';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// Import Remix Icon CSS
import 'remixicon/fonts/remixicon.css';

// Import Material Design Icons (MDI)
import '@mdi/font/css/materialdesignicons.css';

// Import your main.scss file
import '@/assets/styles/main.scss';

const app = createApp(App);
require('@/services/api')

// Setup Vuetify to use both MDI and Remix Icons
app.use(vuetify);
app.use(router);
app.use(store);
app.config.globalProperties.$helper = helper;
app.component('vue-phone-number-input', VuePhoneNumberInput);

app.mount('#app');

<template>
  <v-app>
    <!-- Pick an option -->
    <PresetBoxesList v-if="!loading && allBoxes.length" :allBoxes="allBoxes" />

    <!-- Optional: Show a message if no boxes are available -->
    <v-container v-if="!loading && !allBoxes.length" class="mt-1">
      <v-row>
        <v-col cols="12" class="text-center">
          <p>No preset boxes available.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import PresetBoxesList from "./components/PresetBoxesList";

export default {
  name: 'PresetBoxes',
  components: {
    PresetBoxesList,
  },

  mounted() {},

  computed: {
    allBoxes() {
      return this.$store.getters.presetBoxes;
    }
  },

  data() {
    return {
      loading: true
    };
  },

  methods: {},

  created() {
    this.$store.dispatch('GET_PRESET_LIST').then(
      response => {
        if (response.status == 'success') {
            this.loading = false;
        }
      },
      error => {
        this.loading = false;
        throw error;
      }
    );
  }
};
</script>

<style scoped>
</style>

<template>
  <v-app>
    <v-container fluid class="border rounded-16 bg-white">
      <!-- <div class="page-secton" id="packaging">
        <v-row>
          <v-col cols="12">
            <div class="text--primary text-h5 text-left">Let's Begin, <strong>Choose packaging!</strong></div>
          </v-col>
          <v-col cols="6" md="4" lg="3" class="px-2 px-lg-3" v-for="(pkg, packageIndex) in $store.getters.packages"
            :key="packageIndex">
            <v-card class="text-center pa-4 rounded-lg" height="100%">
              <div class="mb-3 pointer" @click.stop="productdetail = !productdetail">
                <v-img :src="pkg.thumbnail" max-width="100%" max-height="200px"></v-img>
              </div>
              <div class="text-left">
                <v-card-title class="text-body-2 mb-2 font-weight-medium pa-0">
                  {{ pkg.name }}
                </v-card-title>
                <div class="text-body-2 font-weight-extramedium text--primary">
                  from USD <span class="font-weight-bold">${{ pkg.price_per_item }}</span>
                </div>
              </div>
              <div class="cstm-check mt-4">
                <input type="checkbox" class="d-none" :id="pkg.id" name="products">
                <label :for="pkg.id">
                  <span class="inactive">Add to box</span>
                  <span class="active"><i class="mdi mdi-check"></i>Added</span>
                </label>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div> -->

      <div class="page-secton" id="mensclothing">
        <v-row v-for="(category, catIndex) in catalogs" :key="catIndex">
          <v-col cols="12" class="pb-1">
            <div class="font-weight-semibold text-light7 ls-75 font-15 text-left" :id="'cat' + category.id" :ref="'category-' + category.id">
              First up, pick your <span class="text-primary">{{category.title}}!</span>
            </div>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" v-for="(item, itemIndex) in category.products" :key="itemIndex"
            class="pa-1 pa-lg-2">
            <v-card variant="text" class="text-center border-grey pa-2 pa-md-4 rounded-lg product-card border" height="100%">
              <div class="mb-3 pointer" @click.stop="openProductDetail(item, cartItemIds.includes(item.id))">
                <!-- <v-img :src="$helper.functions.getThumbImage(item, 'thumbnails')" max-width="100%" contain
                  class="mx-auto">
                </v-img> -->
                <NwImg :src="$helper.functions.getThumbImage(item, 'thumbnails')" alt="Example Alt Text"
                  style="max-height: 200px;" />
              </div>
              <div class="text-left">
                <v-card-title class="text-dark2 mb-2 font-14 ls-75 font-weight-semibold pa-0">
                  {{ item.title }}
                </v-card-title>
                <div class="text-light7 font-13 ls-75 font-weight-normal">
                  Starts from USD <span class="font-weight-semibold text-bluish3">{{ getItemPrice(item).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })
                  }}</span>
                </div>
              </div>
              <!-- Checkbox -->
              <!-- <div class="cstm-check mt-4"> -->
              <div class="cstm-check mt-3">
                <!-- addToCart(item) -->
                <v-btn  outlined v-if="!cartItemIds.includes(item.id)"
                  @click="openProductDetail(item, cartItemIds.includes(item.id))"
                  class="font-14 text-dark2 font-weight-semibold rounded-pill w-100 elevation-0 border-0 bg-light1" min-height="38"> <i class="mdi mdi-plus me-2 font-16"></i> Add to box</v-btn>
                <v-btn  outlined v-if="cartItemIds.includes(item.id)" @click="removeIndex(item)"
                  class="font-14 font-weight-semibold rounded-pill w-100 text-dark2 elevation-0 border-bluish bg-light1" min-height="38"><i class="mdi mdi-check font-16 me-2"></i> Added
                </v-btn>
              </div>
              <!-- </div> -->
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>


  </v-app>
</template>

<script>
import { cloneDeep } from 'lodash'
import NwImg from "@/components/NwImg"
import { eventBus } from '@/eventBus';

export default {
  name: 'ProductsList',
  components: {
    NwImg
  },

  props: {
    catalogs: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      productdetail: null,
      qtyperbox: 0,
      selected: 'Red',
      selectedProduct: {},
      selected_color: null,
      product_quantity: 1,
      NewColors: { hexcode: '#000000', title: 'Black', },
      // colorfilter: ["#000000", "#FFFFFF", "#9C071A", "#263147", "#274C97", "#9A978E", "#FFB095", "#CDE5FA", "#343235", "#66838D", "#BF6B92", "#B26C9B",],
      colorfilter: [
        { hexcode: '#000000', title: 'Black', },
        { hexcode: '#9C071A', title: 'Red', },
        { hexcode: '#263147', title: 'Dark Green', },
        { hexcode: '#FFB095', title: 'Light Salmon', },
        { hexcode: '#CDE5FA', title: 'Sky Blue', },
        { hexcode: '#66838D', title: 'Steel Teal', },
      ],
      tags: [{
        text: "Black",
        color: "#000000",
      },
      {
        text: "White",
        color: "#ffffff",
      },
      {
        text: "Red",
        color: "#F44336",
      }
      ],
      packaging: [{
        src: require('@/assets/images/custom-mailer-box-outside-print.png'),
        title: 'Custom Mailer Box Inside + Outside Print',
        price: 30.01,
        id: 11,
      },
      {
        src: require('@/assets/images/plain-mailer-box.png'),
        title: 'Plain mailer box',
        price: 30.01,
        id: 12,
      },
      {
        src: require('@/assets/images/custom-mailer-box-1.png'),
        title: 'Custom mailer box',
        price: 30.01,
        id: 13,
      },
      {
        src: require('@/assets/images/custom-vintage-box.png'),
        title: 'Custom vintage box',
        price: 30.01,
        id: 14,
      },
      {
        src: require('@/assets/images/custom-mailer-bags.png'),
        title: 'Custom Mailer bags',
        price: 30.01,
        id: 15,
      },
      {
        src: require('@/assets/images/plain-mailer-bags.png'),
        title: 'Plain mailer bags',
        price: 30.01,
        id: 16,
      },
      {
        src: require('@/assets/images/full-color-mailer-box.png'),
        title: 'Full color mailer box',
        price: 30.01,
        id: 17,
      },
      {
        src: require('@/assets/images/custom-product-box.png'),
        title: 'Custom product box',
        price: 30.01,
        id: 18,
      }
      ],
      // Product Slider
      onboarding: 0,
      slider: [{
        src: require('@/assets/images/speedup-socks-and-stickers.png')
      },
      {
        src: require('@/assets/images/classics-pack.png')
      },
      {
        src: require('@/assets/images/cozy-and-calm.png')
      },
      {
        src: require('@/assets/images/happy-camper.png')
      },
      {
        src: require('@/assets/images/the-chief-swag-officer.png')
      },
      {
        src: require('@/assets/images/the-outdoor-enthusiast.png')
      },
      {
        src: require('@/assets/images/the-rebrand-plus.png')
      },
      {
        src: require('@/assets/images/the-road-warrior.png')
      },
      ]
    };
  },

  watch: {
    // productdetail(newValue, oldValue) {
    //   if (!newValue && !this.cartItemIds.includes(this.selectedProduct.id)) {
    //     this.$helper.functions.resetSpecificProduct(this.selectedProduct)
    //   }
    //   if(newValue) {
    //     this.selectedProduct.number_of_logos = this.$store.getters.numberOfLogos
    //   }
    //   // console.log('newValue', newValue)
    // }
  },

  computed: {
    cartItemIds() {
      if (this.$store.state.cart?.box_products) {
        return this.$store.state.cart?.box_products.map(prod => prod.id)
      } else {
        return []
      }
    },

    productSelected() {
      return this.selectedProduct
    },

    quantityLimits() {
      return this.$store.getters.getQuantityLimits
    },
  },

  methods: {

    getItemPrice(item) {
      // let pricee = this.$helper.functions.getItemBasePrice(item, this.$store.getters.getCart?.quantity)
      let pricee = this.$helper.functions.getProdLowestPrice(item, this.$store.getters.getCart?.quantity)
      return pricee.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    loaded(e) {
      // console.log(e)
    },

    updateCart(product, color) {
      product.selected_color = color
      this.$store.commit('updateCartProd', product)
    },

    removeIndex(item) {
      let itemIndex = this.$store.state.cart?.box_products.findIndex(prod => prod.id == item.id)
      this.$store.commit('removeItemFromCart', itemIndex)
    },

    alreadyAdded(product) {
      let prodId = product
      let existInCart = this.$store.state.cart?.box_products.find(product => product.id == prodId)
      return existInCart
    },

    colorChanged() {
    },

    addToCart(product) {
      if (!product.selected_color) {
        product.selected_color = product.colors[0] ? product.colors[0] : null
      }
      let variants = product.variants
      let variant = variants.find(obj => {
        return obj.color?.value == product.selected_color.value
      })
      let images = []
      variant.images.forEach((object, key) => {
        let img_name = this.$helper.functions.getImage(object)
        images.push(img_name)
      })
      if (images.length > 0) {
        product.images = images;
      } else {
        product.images = [];
      }
      this.$store.commit('pushToCart', product)
    },

    openProductDetail(product, isIncludedInCart) {
      // this.$router.replace({ ...this.$router.currentRoute, query: { 'details': product.id} });
      if (isIncludedInCart) {
        let prod = cloneDeep(this.$store.state.cart?.box_products.find(prod => prod.id == product.id))
        eventBus.emit('openProdDetail', prod)
        // this.productdetail = true
      } else {
        // this.selectedProduct = product
        // this.selectedProduct.selected_color = this.selectedProduct.colors[0] ? this.selectedProduct.colors[0] : null
        // this.productdetail = true
        let prod = cloneDeep(product)
        eventBus.emit('openProdDetail', prod)
      }
      // this.product_quantity = this.selectedProduct.quantity
      // this.selected_color = this.selectedProduct.selected_color
    },


  },

  created() {

    // let isScrolling;
    // window.addEventListener('scroll', function (event) {
    //   window.clearTimeout(isScrolling);
    //   let current = this
    //   isScrolling = setTimeout(() => {
    //     // console.log('this.colorfilter', this.colorfilter)
    //     // let scrollPosition = window.scrollY
    //     // this.$store.getters.products.forEach((category) => {
    //     //   console.log('category.id', category.id);
    //     // });
    //     // console.log('scrollPosition', scrollPosition)
    //     isScrolling = 'finished'
    //     // return '123'
    //   }, 66);
    //   // console.log('isScrolling', isScrolling)

    // }, false);
    // console.log('isScrolling', isScrolling)
    eventBus.on('cartProdDetail', (product) => {
      eventBus.emit('openProdDetail', product)
      // this.selectedProduct = product
      // this.product_quantity = this.selectedProduct.quantity
      // this.selected_color = this.selectedProduct.selected_color
      // this.productdetail = true
    });
  }
}
</script>

<style scoped>
.theme--light.v-card {
  position: relative;
  min-height: 375px;
}

.v-image.v-responsive {
  height: 200px;
}

.cstm-lable {
  position: absolute;
  left: 0;
  right: 0;
  margin: 16px 12px;
  bottom: 0;
}

.color-dropdown {
  height: 24px;
  width: 24px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.v-card.product-card:hover {
  /* transform: scale(1.03);/ */
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: #fdfdfd;
}

.v-text-field--outlined fieldset,
.v-text-field fieldset {
  border-color: rgba(7, 26, 82, 1) !important;
  border-radius: 2px !important;
}

.cst-slider button {
  opacity: 0.4;
  border: 1px solid #dedede;
}

.cst-slider .active {
  opacity: 1;
}

.max-ftr-h {
  max-height: 140px;
  overflow-y: auto;
}

@media (min-width: 960px) {
  .floating-btn {
    top: 35%;
  }
}

@media (max-width: 420px) {
  .theme--light.v-card {
    position: relative;
    min-height: 330px;
  }

  .v-image.v-responsive {
    height: 115px;
  }
}
</style>

<template>
<v-container fluid class="bg-white">
    <v-row class="align-center px-md-4 justify-center py-10 py-lg-15">
        <v-col cols="12" lg="10">
            <v-row class="text-start px-md-4 justify-space-between align-center">
                <v-col cols="12" sm="6" lg="6" class="pe-md-12">
                    <v-img :src="require('@/assets/images/swag-management.png')" alt="Swag Management" class="fill-height grill-bg"></v-img>
                </v-col>
                <v-col cols="12" sm="6" lg="6" class="">
                    <div>
                        <h2 class="mb-4">
                            A swag management 
                            <span >platform </span> that <br class="d-none d-lg-block">
                            makes
                            <span class="text-cyan-darken-2 ">Sourcing</span>, <br class="d-none d-lg-block">
                            <span class="text-cyan-darken-2">Storing</span>, 
                            and
                            <br class="d-none d-lg-block">
                            <span class="text-cyan-darken-2">Shipping</span>
                            swag easy.
                        </h2>
                        <v-btn min-height="42" variant="outlined" color="primary"
                            class="mt-4 px-5 ls-75 font-weight-bold font-16" rounded :to="{ name: 'ProductOnboarding' }">
                            Start Building my Box
                            <v-icon class="ml-2 ri-arrow-right-line font-16 font-weight-light"></v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "SwagManagement",
    components: {},
    mounted() {},
    computed: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped></style>

<template>
  <v-card class="rounded-lg">
    <v-card-title class="py-4 font-weight-semibold text-primary font-18 ls-75 d-flex justify-space-between align-center">
      <span>Flat Rate Storage and Fulfillment</span>
      <v-btn @click="$emit('close')" variant="text"
        class="rounded-circle text-light7 pa-0 font-24 font-weight-light" width="24" height="24" min-width="24">
        <i class="ri-close-circle-line"></i>
      </v-btn>
    </v-card-title>
    <v-divider class="border-grey opacity-100"></v-divider>

    <v-card-text class="px-0 py-0">
      <v-container>
        <div class="ls-25 font-13 font-weight-medium text-black mb-2">Storage pricing</div>
        <v-table class="elevation-0 transparent border-grey rounded-lg" density="compact">
          <thead>
            <tr class="font-weight-medium font-12 ls-25 text-light10">
              <th class="text-left">Item</th>
              <th class="text-center">Swagify warehouse</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in storagePricing" :key="index" class="font-13 ls-25 font-weight-medium text-primary">
              <td class="text-left py-md-5 py-4" style="max-width: 150px; min-width: 250px;">{{ item.name }}</td>
              <td class="text-center py-md-5 py-4">{{ item.warehouse }}</td>
              <td class="text-center py-md-5 py-4">{{ item.total }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-card-text>

    <v-divider class="border-grey opacity-100"></v-divider>
    <v-card-actions class="pa-3 text-end d-flex justify-end align-center">
      <v-btn class="rounded-lg px-4 bg-green2 font-13 ls-25 font-weight-semibold text-white" min-height="40" @click="$emit('close')">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'FlatStorage',
  setup() {
    // Data stored in a reactive reference
    const storagePricing = ref([
      { name: "Pack Storage", warehouse: "$5.00", total: "N/A" },
      { name: "Small Item Fee (Stickers, Pins, Buttons, Insert cards)", warehouse: "$0.25", total: "$2.00" },
      { name: "Medium Item Fee (Bottles, Journals, Shirts)", warehouse: "$1.00", total: "$4.00" },
      { name: "Large Item Fee (Backpacks, Hoodies, Jackets)", warehouse: "$3.00", total: "$6.00" }
    ]);

    return { storagePricing };
  }
};
</script>
